import { DotLegalChip, DotLegalEmptyState, DotLegalInformationTooltip, DotLegalTable, DotLegalTooltip, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import { AnnualWheelActivityTableModel } from "../AnnualWheel.types";
import { useTranslation } from "../../localization/useTranslation";
import { Badge, Box, TableCell } from "@mui/material";
import AnnualWheelTaskDeleted from "../annualWheelTask/AnnualWheelTaskDeleted.svg?react";
import React from "react";
import { useAnnualWheelListOverviewStyles } from "./AnnualWheelListOverview.styles";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import EmptySvg from "./EmptyList.svg?react";
import NoDataSvg from "../../processingActivity/processingActivityOverview/processingActivitySharingsTab/noSharings.svg?react";
import { getTaskColor } from "../../taskManagement/task/Task.styles";
import AnnualWheelOverflowMenu from "../AnnualWheelOverflowMenu.tsx";
import TrialExpiredRowWrapper from "../../common/components/TrialExpiredRowWrapper.tsx";

export interface IAnnualWheelListOverview {
    activties: Array<AnnualWheelActivityTableModel> | undefined;
    isLoading: boolean;
    setSelectedActivity: (activity: AnnualWheelActivityTableModel | undefined) => void;
    setShowDeleteActivityDialog: (activity: { showDialog: boolean; activityName: string }) => void;
    setShowEditActivityDialog: () => void;
    hasActivities: boolean;
}

function AnnualWheelListOverview(props: IAnnualWheelListOverview) {
    const { translateString } = useTranslation();
    const styles = useAnnualWheelListOverviewStyles();
    const { permissions } = useUserContext();
    const { platformFeatures } = usePlanContext();

    function onEditClick(row: AnnualWheelActivityTableModel) {
        props.setSelectedActivity(row);
        props.setShowEditActivityDialog();
    }

    function getHeaders() {
        let headers = Array<ITableHeader<AnnualWheelActivityTableModel>>();
        headers.push({ property: "name", text: translateString("activity"), align: "left", showOnMobile: true });
        headers.push({ property: "customId", text: "ID", align: "left", showOnMobile: true });
        headers.push({ property: "month", text: translateString("month"), align: "left", showOnMobile: true });
        headers.push({ property: "recurringIntervalInMonths", text: translateString("recurrence"), align: "left", showOnMobile: true });
        headers.push({ property: "priority", text: translateString("priority"), align: "left", showOnMobile: true });
        headers.push({ property: "taskStatus", text: translateString("status"), align: "left", showOnMobile: true });

        if (platformFeatures.relatedActivitiesAndTasks) {
            headers.push({ property: "relatedActivities", text: translateString("relatedActivities"), align: "left", showOnMobile: false });
        }

        if (platformFeatures.annualWheelComplianceArea) {
            headers.push({ property: "areas", text: translateString("area"), align: "left", showOnMobile: true });
        }

        headers.push({ property: "businessAreas", text: translateString("businessAreas"), align: "left", showOnMobile: true });

        if (platformFeatures.tags && permissions.canAccessTags) {
            headers.push({ property: "tagsString", text: translateString("tags"), align: "left", showOnMobile: true });
        }

        headers.push({ property: "responsibles", text: translateString("responsible"), align: "left", showOnMobile: true });
        headers.push({ property: "groupCompaniesString", text: translateString("groupCompanies") });
        headers.push({ property: "documentationRequired", text: translateString("documentationRequired"), align: "left", showOnMobile: true });

        return headers;
    }
    const hasActivities = props.hasActivities && props.activties && props.activties.length > 0;
    return (
        <React.Fragment>
            {props.isLoading || hasActivities ? (
                <DotLegalTable
                    headers={getHeaders()}
                    rowsPerPage={30}
                    defaultOrderBy={"month"}
                    clickableRows={false}
                    defaultOrder={"asc"}
                    isLoading={props.isLoading}
                    data={props.activties ?? []}
                    renderRow={(row, i) => {
                        const canEditActivity = permissions.taskPermissions.read && !row.deleted;
                        return (
                            <TrialExpiredRowWrapper
                                onNonExpiredClick={() => (canEditActivity ? onEditClick(row) : {})}
                                key={row.id + row.month.toString()}
                                tooltip={translateString("annualWheelActivityLockedPlan")}
                                expired={row.notPartOfCurrentPlan}
                                planUpgradeText={translateString("annualWheelActivityLockedPlan")}
                                pointerOnHover={canEditActivity}
                            >
                                <TableCell>
                                    {
                                        <Box sx={styles.nameContainer}>
                                            <Box component={"span"}>{row.name}</Box>
                                            <DotLegalInformationTooltip text={row.description} />
                                            {row.deleted && (
                                                <DotLegalTooltip text={translateString("annualActivityIsDeleted")}>
                                                    <Box sx={styles.img}>
                                                        <AnnualWheelTaskDeleted />
                                                    </Box>
                                                </DotLegalTooltip>
                                            )}
                                        </Box>
                                    }
                                </TableCell>
                                <TableCell>{row.customId}</TableCell>
                                <TableCell>{row.monthString}</TableCell>
                                <TableCell>{row.recurringIntervalInMonthsString}</TableCell>
                                <TableCell>{row.priorityString}</TableCell>

                                <TableCell>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                        <Badge
                                            variant="dot"
                                            sx={(theme) => ({
                                                "& .MuiBadge-badge": {
                                                    backgroundColor: getTaskColor(row.taskStatus, theme),
                                                },
                                            })}
                                        />
                                        <Box>{row.statusString}</Box>
                                    </Box>
                                </TableCell>
                                {platformFeatures.relatedActivitiesAndTasks && <TableCell>{row.relatedActivities.join(", ")}</TableCell>}
                                {platformFeatures.annualWheelComplianceArea && <TableCell>{row.complianceAreaString}</TableCell>}
                                <TableCell>
                                    {row.businessAreas.map((x, index) => {
                                        return (
                                            <Box sx={styles.chip} key={x.name + index}>
                                                <DotLegalChip
                                                    value={x.name}
                                                    color={x.color}
                                                    size="small"
                                                    isSelected
                                                    collapse={row.businessAreas.length > 2 && index > 0}
                                                />
                                            </Box>
                                        );
                                    })}
                                </TableCell>
                                {platformFeatures.tags && permissions.canAccessTags && (
                                    <TableCell>
                                        {row.tags.map((x, index) => {
                                            return (
                                                <Box sx={styles.chip} key={x.name + index}>
                                                    <DotLegalChip
                                                        value={x.name}
                                                        color={x.color}
                                                        size="small"
                                                        isSelected
                                                        collapse={row.tags.length > 2 && index > 0}
                                                    />
                                                </Box>
                                            );
                                        })}
                                    </TableCell>
                                )}
                                <TableCell>{row.responsibles && row.responsibles.map((x) => x.name).join(", ")}</TableCell>
                                <TableCell>
                                    {row.groupCompanies.length > 2 ? (
                                        <DotLegalTooltip text={row.groupCompaniesString}>
                                            <div>
                                                {row.groupCompanies
                                                    .slice(0, 2)
                                                    .map((gc) => gc.name)
                                                    .join(", ")}
                                                <Box component={"span"} sx={{ mr: 0.5, ml: 0.5 }}>
                                                    +
                                                </Box>
                                                <b>{(row.groupCompanies.length - 2).toString()}</b>
                                            </div>
                                        </DotLegalTooltip>
                                    ) : (
                                        row.groupCompaniesString
                                    )}
                                </TableCell>
                                <TableCell>{row.documentationRequired ? translateString("yes") : translateString("no")}</TableCell>
                                <TableCell align={"right"}>
                                    <AnnualWheelOverflowMenu
                                        onEditClick={() => {
                                            onEditClick(row);
                                        }}
                                        onDeleteClick={() => {
                                            props.setShowDeleteActivityDialog({ showDialog: true, activityName: row.name });
                                            props.setSelectedActivity(row);
                                        }}
                                        month={row.month}
                                        deleted={row.deleted}
                                        id={row.id}
                                        partOfCurrentPlan={!row.notPartOfCurrentPlan}
                                    />
                                </TableCell>
                            </TrialExpiredRowWrapper>
                        );
                    }}
                    paginationLabelOf={translateString("labelOf")}
                />
            ) : (
                <DotLegalEmptyState
                    icon={props.hasActivities ? <NoDataSvg /> : <EmptySvg />}
                    text={
                        <React.Fragment>
                            <span>{props.hasActivities ? translateString("noData") : translateString("noAnnualWheelActivities")}</span>
                        </React.Fragment>
                    }
                />
            )}
        </React.Fragment>
    );
}

export default AnnualWheelListOverview;
