import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { get, put } from "../common/api/apiShared";
import { DeclarationControlViewModel, DeclarationViewModel } from "./Declaration.types";
import { useState } from "react";
import { SelectableItem } from "../processingActivity/ProcessingActivity.types";
import { useStateUrlParamsArray } from "../common/hooks/useStateUrlParams";
import { DotLegalSelectOption } from "../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../localization/useTranslation";
import { PageHeaderEditEntityButtonMenuItem } from "../common/components/pageHeaderEditEntityButton/PageHeaderEditEntityButton.tsx";
import { EntityType } from "../common/Common.types.ts";
import { useNavigate } from "react-router";
import { useUrlProvider } from "../useUrlProvider.ts";
import { useUserContext } from "../auth/userContextProvider/UserContextProvider.tsx";
import { useAddTaskMenuItem } from "../common/components/addTaskMenuItem/AddTaskMenuItemContext.hooks.ts";

export function useDeclaration() {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const { id } = useParams<{ id: string }>();

    const [showDeleteControlDialog, setShowDeleteControlDialog] = useState(false);
    const [selectedControl, setSelectedControl] = useState<DeclarationControlViewModel | undefined>(undefined);
    const [showNoteDialog, setShowNoteDialog] = useState(false);
    const [showDeclarationControlDialog, setShowDeclarationControlDialog] = useState(false);
    const [showCompleteDialog, setShowCompleteDialog] = useState(false);
    const [showApproveControlDialog, setShowApproveControlDialog] = useState(false);
    const [showReadyForRevisionDialog, setShowReadyForRevisionDialog] = useState(false);
    const [showAuditorNoteDialog, setShowAuditorNoteDialog] = useState(false);
    const [showAuditorDeviationNoteDialog, setShowAuditorDeviationNoteDialog] = useState(false);
    const [showGroupEntitiesDialog, setShowGroupEntitiesDialog] = useState({ groupEntities: [] as Array<SelectableItem>, entitiesWithoutAccess: 0 });
    const [searchedCategory, setSearchedCategory] = useStateUrlParamsArray("categories", []);
    const [searchedResponsible, setSearchedResponsible] = useStateUrlParamsArray("responsible", []);
    const [showCompleteControlDialog, setShowCompleteControlDialog] = useState(false);

    const url = getDeclarationQueryKey(id!);
    let { isLoading, data, refetch } = useQuery(url, () => get<DeclarationViewModel>(url));

    let categoryFilterOptions: Array<DotLegalSelectOption> = [];
    let responsibleFilterOptions: Array<DotLegalSelectOption> = [];

    let controlsToDisplay: Array<DeclarationControlViewModel> = [];
    if (data) {
        createCategoryFilterOptions(data.controls.filter((x) => x.category !== null).flatMap((x) => x.category));
        createResponsibleFilterOptions(data.controls.flatMap((x) => x.responsible));
        sortSelectOptions();

        controlsToDisplay = getFilteredRows(data.controls);
    }

    const saveNoteMutation = useMutation(saveNoteUrl);
    const saveNote = (note: string) => {
        saveNoteMutation.mutateAsync(note, {
            onSuccess: () => {
                snackbar.show(translateString("noteUpdated"));
                setShowNoteDialog(false);
                refetch();
            },
        });
    };

    const saveAuditorNoteMutation = useMutation(saveAuditorNoteApi);
    const saveAuditorNote = async (controlId: string, note: string) => {
        await saveAuditorNoteMutation.mutateAsync(
            { controlId: controlId, auditorNote: note },
            {
                onSuccess: () => {
                    snackbar.show(translateString("noteUpdated"));
                    refetch();
                    setShowAuditorNoteDialog(false);
                },
            }
        );
    };

    const saveAuditorDeviationNoteMutation = useMutation(saveAuditorDeviationNoteApi);
    const saveAuditorDeviationNote = async (controlId: string, note: string) => {
        await saveAuditorDeviationNoteMutation.mutateAsync(
            { controlId: controlId, auditorDeviationNote: note },
            {
                onSuccess: () => {
                    snackbar.show(translateString("noteUpdated"));
                    refetch();
                    setShowAuditorDeviationNoteDialog(false);
                },
            }
        );
    };

    const setSamplingMutation = useMutation(setSamplingApi);
    const onSetSampling = async (controlId: string, sampling: boolean) => {
        setSamplingMutation.mutateAsync(
            { controlId: controlId, sampling: sampling },
            {
                onSuccess: () => {
                    refetch();
                },
            }
        );
    };

    return {
        declarationId: id!,
        isLoading,
        data,
        controlsToDisplay,
        showGroupEntitiesDialog,
        setShowGroupEntitiesDialog,
        searchedCategory,
        setSearchedCategory,
        searchedResponsible,
        setSearchedResponsible,
        categoryFilterOptions,
        responsibleFilterOptions,
        showDeclarationControlDialog,
        setShowDeclarationControlDialog,
        refetch,
        showNoteDialog,
        setShowNoteDialog,
        selectedControl,
        setSelectedControl,
        saveNote,
        isSavingNote: saveNoteMutation.isLoading,
        showCompleteDialog,
        setShowCompleteDialog,
        showCompleteControlDialog,
        setShowCompleteControlDialog,
        showDeleteControlDialog,
        setShowDeleteControlDialog,
        showApproveControlDialog,
        setShowApproveControlDialog,
        showReadyForRevisionDialog,
        setShowReadyForRevisionDialog,
        showAuditorNoteDialog,
        setShowAuditorNoteDialog,
        saveAuditorNote,
        isSavingAuditorNote: saveAuditorNoteMutation.isLoading,
        showAuditorDeviationNoteDialog,
        setShowAuditorDeviationNoteDialog,
        isSavingAuditorDeviationNote: saveAuditorDeviationNoteMutation.isLoading,
        saveAuditorDeviationNote,
        onSetSampling,
    };

    function createCategoryFilterOptions(categories: Array<SelectableItem>) {
        for (let category of categories) {
            if (!categoryFilterOptions.some((x) => x.id === category.id)) {
                categoryFilterOptions.push({ id: category.id, name: category.name });
            }
        }
    }

    function createResponsibleFilterOptions(responsibles: Array<SelectableItem>) {
        for (let responsible of responsibles) {
            if (!responsibleFilterOptions.some((x) => x.id === responsible.id)) {
                responsibleFilterOptions.push({
                    id: responsible.id,
                    name: responsible.name,
                });
            }
        }
    }

    function sortSelectOptions() {
        categoryFilterOptions.sort((a, b) => (a.name > b.name ? 1 : -1));
        responsibleFilterOptions.sort((a, b) => (a.name > b.name ? 1 : -1));
    }

    function getFilteredRows(rows: Array<DeclarationControlViewModel>) {
        let tempData = [...rows];

        if (searchedCategory.length > 0 && searchedCategory[0] !== "") {
            tempData = tempData.filter((row) => searchedCategory.indexOf(row.category.id.toString()) >= 0);
        }

        if (searchedResponsible.length > 0 && searchedResponsible[0] !== "") {
            tempData = tempData.filter((row) => searchedResponsible.indexOf(row.responsible.id.toString()) >= 0);
        }

        return tempData;
    }

    function saveNoteUrl(note: string) {
        return put(`/declarationControl/${selectedControl!.id}/note`, note);
    }

    async function saveAuditorNoteApi(model: { controlId: string; auditorNote: string }) {
        return put(`/Declarationcontrol/${model.controlId}/auditorNote`, model.auditorNote);
    }

    async function saveAuditorDeviationNoteApi(model: { controlId: string; auditorDeviationNote: string }) {
        return put(`/Declarationcontrol/${model.controlId}/auditorDeviationNote`, model.auditorDeviationNote);
    }

    async function setSamplingApi(model: { controlId: string; sampling: boolean }) {
        return put(`/Declarationcontrol/${model.controlId}/sampling`, model.sampling);
    }
}

export function getDeclarationQueryKey(id: string) {
    return `/declaration/${id}`;
}

export function useDeclarationMenuItems() {
    const navigate = useNavigate();
    const urlProvider = useUrlProvider();
    const addTaskMenuItem = useAddTaskMenuItem();
    const { translateString } = useTranslation();
    const { permissions } = useUserContext();

    const [declarationToDelete, setDeclarationToDelete] = useState<string | undefined>(undefined);
    const [declarationToEdit, setDeclarationToEdit] = useState<string | undefined>(undefined);

    const getMenuItems = (isLocked: boolean, declarationId: string) => {
        let menuItems: Array<PageHeaderEditEntityButtonMenuItem> = [];
        if (isLocked) {
            return menuItems;
        }

        menuItems.push({
            menuItem: translateString("manageCategories"),
            onClick: () => {
                navigate(urlProvider.getDeclarationCategoriesUrl(declarationId));
            },
        });

        if (permissions.declarationPermissions.edit) {
            menuItems.push({
                menuItem: translateString("edit"),
                onClick: () => {
                    setDeclarationToEdit(declarationId);
                },
            });
        }

        if (permissions.declarationPermissions.delete) {
            menuItems.push({
                menuItem: translateString("delete"),
                onClick: () => {
                    setDeclarationToDelete(declarationId);
                },
            });
        }

        addTaskMenuItem.addCreateTaskMenuItem(menuItems, {
            fromEntityId: declarationId,
            fromEntityType: EntityType.Declaration,
            toEntityType: EntityType.Task,
        });

        return menuItems;
    };

    return {
        declarationToDelete,
        setDeclarationToDelete,
        declarationToEdit,
        setDeclarationToEdit,
        getMenuItems,
    };
}
