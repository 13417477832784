import { DotLegalHeader, DotLegalTextField, DotLegalLink, DotLegalTooltip, DotLegalOverflowMenu } from "@dotlegal/dotlegal-ui-components";
import { Box, Typography, IconButton } from "@mui/material";
import React from "react";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { annualWheelActivityIcon, closeDialogXIcon } from "../../../common/icons";
import { useTranslation } from "../../../localization/useTranslation";
import { useUrlProvider } from "../../../useUrlProvider";
import { useTaskDialogStyles } from "./TaskDialogHeader.style";
import { useNavigate } from "react-router-dom";

export interface ITaskDialogHeader {
    activityName: string;
    taskName?: string;
    processingActivtiyId: string | null;
    annualWheelActivityId?: string;
    customTaskId: string | undefined | null;
    isAdHocTask?: boolean;
    onNameChange?: (value: string) => void;
    borderPadding: number;
    setShowDeleteDialog: (show: boolean) => void;
    onDialogClose: () => void;
}

function TaskDialogHeader(props: ITaskDialogHeader) {
    const styles = useTaskDialogStyles();
    const { getProcessingActivityOverviewUrl } = useUrlProvider();
    const { translateString } = useTranslation();
    const { permissions } = useUserContext();
    const { getAnnualWheelUrlWithActivityId } = useUrlProvider();

    return (
        <Box
            sx={{
                fontSize: 14,
                fontWeight: "bold",
                textTransform: "uppercase",
                width: "100%",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop: props.borderPadding,
                    paddingLeft: props.borderPadding,
                    paddingRight: props.borderPadding,
                    fontSize: "18px !important",
                }}
            >
                <Box
                    sx={{
                        fontSize: 14,
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        width: "100%",
                    }}
                >
                    {props.isAdHocTask && permissions.taskPermissions.edit ? (
                        <Box sx={{ display: "flex", gap: 1 }}>
                            {props.customTaskId && (
                                <DotLegalHeader marginBottom={0} headerStyle={"small"}>
                                    {props.customTaskId}
                                </DotLegalHeader>
                            )}
                            <Box
                                sx={{
                                    "& .MuiInputBase-input": {
                                        fontWeight: 700,
                                        textTransform: "uppercase",
                                    },
                                    flex: 1,
                                    marginTop: "-0.7px",
                                }}
                            >
                                <DotLegalTextField
                                    disabled={!permissions.taskPermissions.edit}
                                    noMargin
                                    onChange={props.onNameChange}
                                    variant="standard"
                                    label={""}
                                    value={props.activityName ?? ""}
                                    debounce
                                />
                            </Box>
                        </Box>
                    ) : (
                        <Box sx={styles.headerContainer}>
                            {props.customTaskId && (
                                <DotLegalHeader marginBottom={0} headerStyle={"small"}>
                                    {props.customTaskId}
                                </DotLegalHeader>
                            )}
                            <DotLegalHeader marginBottom={0} headerStyle={"small"}>
                                {props.activityName}
                            </DotLegalHeader>
                            {props.taskName && (
                                <React.Fragment>
                                    <Typography sx={styles.seperator}>/</Typography>
                                    <DotLegalHeader marginBottom={0} color="secondary" headerStyle={"small"}>
                                        {props.processingActivtiyId !== null ? (
                                            <DotLegalLink to={getProcessingActivityOverviewUrl(props.processingActivtiyId!)}>{props.taskName}</DotLegalLink>
                                        ) : (
                                            <span>{props.taskName}</span>
                                        )}
                                    </DotLegalHeader>
                                </React.Fragment>
                            )}
                            {!props.isAdHocTask && (
                                <DotLegalLink to={getAnnualWheelUrlWithActivityId(props.annualWheelActivityId!)}>
                                    <DotLegalTooltip text={translateString("connectedToAnnualWheelActivity")}>{annualWheelActivityIcon}</DotLegalTooltip>
                                </DotLegalLink>
                            )}
                        </Box>
                    )}
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ marginRight: 1 }}>
                        {permissions.taskPermissions.delete && (
                            <DotLegalOverflowMenu
                                setHorizontally
                                color={"secondary"}
                                items={[
                                    {
                                        menuItem: translateString("delete"),
                                        onClick: () => props.setShowDeleteDialog(true),
                                    },
                                ]}
                            />
                        )}
                    </Box>

                    <Box>
                        <IconButton onClick={props.onDialogClose}>{closeDialogXIcon}</IconButton>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default TaskDialogHeader;
