import React, { createContext, useState } from "react";
import AddTaskDialog, { AddTaskRelationData } from "../../../taskManagement/addTask/addTaskDialog/AddTaskDialog.tsx";
import EditTaskDialog from "../../../taskManagement/editTaskDialog/EditTaskDialog.tsx";
import { DotLegalOverflowMenuItem } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation.ts";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider.tsx";
import { useLocation } from "react-router-dom";

export interface AddTaskMenItemModel {
    addCreateTaskMenuItem: (menuItems: Array<DotLegalOverflowMenuItem>, relationInformation: AddTaskRelationData, namePrefix?: string) => void;
    registerRefetchFunc: (refetchFunc: () => void) => void;
    unregisterRefetchFunc: () => void;
}

export const AddTaskMenuItemContext = createContext<AddTaskMenItemModel>({
    addCreateTaskMenuItem: (menuItems, relationInformation, namePrefix) => {},
    registerRefetchFunc: () => {},
    unregisterRefetchFunc: () => {},
});

export interface IAddTaskMenItemProviderProps {
    children: any;
}

interface AddTaskMenuItemProviderRelatedData {
    relatedData: AddTaskRelationData;
    namePrefix?: string;
}
export function AddTaskMenuItemProvider(props: IAddTaskMenItemProviderProps) {
    const location = useLocation();
    const [taskInformation, setTaskInformation] = useState<AddTaskMenuItemProviderRelatedData | undefined>(undefined);
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [createdTaskId, setCreatedTaskId] = useState<string | undefined>(undefined);
    const [refetchFunction, setRefetchFunction] = useState<() => void>();

    const { translateString } = useTranslation();
    const { permissions } = useUserContext();

    React.useEffect(() => {
        if (createdTaskId !== undefined) {
            resetDialogs();
        }
        // eslint-disable-next-line
    }, [location.pathname]);

    const data: AddTaskMenItemModel = {
        addCreateTaskMenuItem: (menuItems, relationInformation, namePrefix) => {
            if (permissions.taskPermissions.create) {
                menuItems.push({
                    menuItem: translateString("createTask"),
                    onClick: () => {
                        setShowAddDialog(true);
                        setTaskInformation({ relatedData: relationInformation, namePrefix });
                    },
                });
            }
        },
        registerRefetchFunc: (refetchFunc) => {
            setRefetchFunction(() => refetchFunc);
        },
        unregisterRefetchFunc: () => {
            setRefetchFunction(undefined);
        },
    };

    const resetDialogs = () => {
        setCreatedTaskId(undefined);
        setShowAddDialog(false);
        setTaskInformation(undefined);
    };

    return (
        <AddTaskMenuItemContext.Provider value={data}>
            {props.children}

            {showAddDialog && !createdTaskId && (
                <AddTaskDialog
                    relationInformation={taskInformation?.relatedData}
                    onDialogClose={() => {
                        resetDialogs();
                    }}
                    onTaskCreated={(taskId) => {
                        setCreatedTaskId(taskId);
                    }}
                    prefixName={taskInformation?.namePrefix}
                />
            )}

            {createdTaskId && (
                <EditTaskDialog
                    setShowDeleteDialog={() => {}}
                    refetchTasks={() => {
                        if (refetchFunction) {
                            refetchFunction();
                        }
                    }}
                    taskId={createdTaskId!}
                    setSelectedTask={() => resetDialogs()}
                    setYearByTaskYear={() => {}}
                />
            )}
        </AddTaskMenuItemContext.Provider>
    );
}

export default AddTaskMenuItemProvider;
