export enum EntityType {
    ProcessingActivity = 1,
    System = 2,
    LegalEntity = 3,
    Policy = 4,
    IncidentLog = 5,
    AnnualWheelActivity = 6,
    Task = 7,
    Risk = 8,
    LegalEntityAudit = 9,
    LegalEntityClassification = 10,
    LegalEntityAuditGroup = 11,
    InternalAssessment = 12,
    LegalEntityDocument = 13,
    Contract = 14,
    Declaration = 15,
    DeclarationControl = 16,
}
