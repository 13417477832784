import { DotLegalDatePicker, DotLegalDialog, DotLegalMultiSelect, DotLegalSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import useAddTaskDialogHooks from "./AddTaskDialog.hooks";
import { EntityType } from "../../../common/Common.types.ts";
import { Box } from "@mui/material";
import { useEffect, useRef } from "react";

export interface AddTaskRelationData {
    fromEntityId: string;
    fromEntityType: EntityType;
    toEntityType: EntityType;
}

export interface IAddTaskDialog {
    onDialogClose: () => void;
    onTaskCreated: (taskId: string) => void;
    relationInformation?: AddTaskRelationData;
    prefixName?: string;
}

function AddTaskDialog(props: IAddTaskDialog) {
    const { translateString } = useTranslation();

    const nameRef = useRef<HTMLTextAreaElement>();
    useEffect(() => {
        setTimeout(() => {
            nameRef.current?.focus();
        }, 0);
    }, []);

    const {
        saveModel,
        validation,
        onModelChange,
        onSave,
        businessAreaData,
        businessAreaIsLoading,
        responsibleData,
        responsibleIsLoading,
        convertDate,
        isSaving,
    } = useAddTaskDialogHooks(props);

    return (
        <DotLegalDialog
            size="sm"
            buttonOkText={translateString("create")}
            header={translateString("createTask")}
            open
            onDialogClose={props.onDialogClose}
            onOkClick={() => onSave()}
            inProgress={isSaving}
        >
            <Box mr={3}>
                <DotLegalTextField
                    inputRef={nameRef}
                    label={translateString("name")}
                    value={saveModel?.name ?? ""}
                    debounce={false}
                    errorText={validation?.getErrorsForDisplay("name")}
                    onChange={(name) => onModelChange((model) => (model.name = name))}
                    toolTipText={translateString("taskNameTooltip")}
                />
                <DotLegalSelect
                    options={responsibleData}
                    isLoading={responsibleIsLoading}
                    selectedItem={saveModel.responsible}
                    placeholder={translateString("noResponsible")}
                    label={translateString("responsible")}
                    onChange={(responsible) => onModelChange((model) => (model.responsible = responsible))}
                    noOptionsLabel={translateString("noOptions")}
                    errorText={validation?.getErrorsForDisplay("responsible")}
                    toolTipText={translateString("taskResponsibleTooltip")}
                />
                <DotLegalMultiSelect
                    label={translateString("businessAreas")}
                    options={businessAreaData}
                    isLoading={businessAreaIsLoading}
                    placeholder={translateString("noBusinessAreas")}
                    onChange={(businessAreas) => onModelChange((model) => (model.businessAreas = businessAreas))}
                    noOptionsLabel={translateString("noOptions")}
                    chosenOptions={saveModel.businessAreas}
                    errorText={validation?.getErrorsForDisplay("businessAreas")}
                    toolTipText={translateString("taskBusinessAreaTooltip")}
                />

                <DotLegalDatePicker
                    label={translateString("deadline")}
                    value={saveModel.deadline}
                    onChange={(date: Date | null) => {
                        onModelChange((model) => (model.deadline = convertDate(date!)));
                    }}
                    view="month"
                    language={"da"}
                    error={validation.getErrorsForDisplay("deadline") !== undefined}
                    helperMessage={validation?.getErrorsForDisplay("deadline")}
                    minDate={new Date(new Date().getFullYear(), 0, 1)}
                    maxDate={new Date(new Date().getFullYear() + 1, 11, 31)}
                    disableKeyboardInput
                    toolTipText={translateString("taskDeadlineTooltip")}
                ></DotLegalDatePicker>
            </Box>
        </DotLegalDialog>
    );
}

export default AddTaskDialog;
