import { createElement, useState } from "react";
import { TaskRelationRow } from "../../editTaskDialog/taskRelations/TaskRelations.types";
import { IRelateToExistingsTasksDialog } from "./RelateToExistingTasksDialog";
import { useMutation, useQuery } from "react-query";
import { get, post } from "../../../common/api/apiShared";
import { RelationSaveModel } from "../../addTask/addTaskDialog/AddTaskDialog.types";
import { EntityType } from "../../../common/Common.types";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { Trans } from "react-i18next";

export default function useRelateToExistingTasksDialogHooks(props: IRelateToExistingsTasksDialog) {
    const snackbar = useDotLegalSnackbar();

    const url = `/taskRelation/selectableTasks/${props.entityId}/entity`;
    let { data } = useQuery(url, () => get<Array<TaskRelationRow>>(url));

    const [selectedTasks, setSelectedTasks] = useState<Array<TaskRelationRow>>([]);

    const addRelationsMutation = useMutation(addRelationsApiUrl);

    const addRelations = () => {
        addRelationsMutation.mutateAsync(mapToSaveModel(), {
            onSuccess: (response: any) => {
                snackbar.show(createElement(Trans, { i18nKey: "taskRelatedCompleted", values: { number: selectedTasks.length } }));
                props.onSaved();
            },
        });
    };

    function addRelationsApiUrl(saveModel: Array<RelationSaveModel>) {
        return post<string>(`/relations/`, saveModel);
    }

    function onSaveClick() {
        addRelations();
    }

    function onAddTask(taskId: string) {
        let temp = [...selectedTasks];

        let task = data?.find((x) => x.taskId === taskId);
        if (task) {
            temp.push(task);
        }

        setSelectedTasks(temp);
    }

    function onRemoveTask(taskId: string) {
        let temp = [...selectedTasks];

        temp = temp.filter((x) => x.taskId !== taskId);

        setSelectedTasks(temp);
    }

    return { selectedTasks, data, onAddTask, onRemoveTask, onSaveClick, isSaving: addRelationsMutation.isLoading };

    function mapToSaveModel(): Array<RelationSaveModel> {
        return selectedTasks.map((x) => {
            return {
                fromEntityId: props.entityId,
                fromEntityType: props.entityType,
                toEntityType: EntityType.Task,
                toEntityId: x.taskId,
            };
        });
    }
}
