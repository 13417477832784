import { DotLegalDialog, DotLegalMultiSelect } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import RelatedTasksSelector from "../../editTaskDialog/taskRelations/relatedTasksSelector/RelatedTasksSelector";
import { Box } from "@mui/material";
import useRelateToExistingTasksDialogHooks from "./RelateToExistingTasksDialog.hooks";
import { EntityType } from "../../../common/Common.types";

export interface IRelateToExistingsTasksDialog {
    onDialogClose: () => void;
    entityId: string;
    entityType: EntityType;
    onSaved: () => void;
}

function RelateToExistingsTasksDialog(props: IRelateToExistingsTasksDialog) {
    const { translateString } = useTranslation();
    const { data, selectedTasks, onAddTask, onRemoveTask, onSaveClick, isSaving } = useRelateToExistingTasksDialogHooks(props);

    return (
        <DotLegalDialog
            buttonOkText={translateString("save")}
            open
            onDialogClose={props.onDialogClose}
            onOkClick={onSaveClick}
            header={translateString("relateToExistingTask")}
            size="md"
            inProgress={isSaving}
        >
            <Box sx={{ marginLeft: 1, marginRight: 1, marginTop: 1 }}>
                <RelatedTasksSelector
                    firstColumnHeader={translateString("task")}
                    showRelatedTasks
                    selectableTasks={data?.filter((x) => !selectedTasks.some((s) => s.taskId === x.taskId)) ?? []}
                    shouldAddRelatedTask={false}
                    selectedTasks={selectedTasks}
                    updateRelatedTaskScenario={function (): void {
                        throw new Error("Function not implemented.");
                    }}
                    onAddTask={onAddTask}
                    onDeleteTaskClick={onRemoveTask}
                    hidePlanningColumn
                    customTooltipMsg={translateString("relateTask")}
                />
            </Box>
        </DotLegalDialog>
    );
}

export default RelateToExistingsTasksDialog;
