import React from "react";
import AuditGroupHeader from "./auditGroupHeader/AuditGroupHeader";
import { useTranslation } from "../../localization/useTranslation";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { useUrlProvider } from "../../useUrlProvider";
import { useLegalEntityAuditGroupDialog } from "./LegalEntityAuditGroupOverview.hooks";
import { DotLegalCompletedPercentage, DotLegalFixedHeightText, DotLegalHeading, DotLegalPaper } from "@dotlegal/dotlegal-ui-components";
import { Box, Divider, Grid, GridSize } from "@mui/material";
import QuestionnaireRisk from "../../common/components/questionnaireComponents/questionnaireRisk/QuestionnaireRisk";
import { getQuestionnaireResponseScore } from "../../common/questionnaireResponseScore";
import { LegalEntityAuditStatus } from "../../legalEntity/legalEntityAuditTab/LegalEntityAudit.types";
import DotLegalLink from "../../common/components/dotLegalLink/DotLegalLink";
import QuestionnaireTemplateLink from "../../legalEntity/legalEntityAuditTab/QuestionnaireTemplateLink.tsx";
import { usePlanContext } from "../../auth/planProvider/PlanProvider.tsx";
import DotLegalTabs from "../../common/components/dotLegalTabs/DotLegalTabs.tsx";
import { TabContent } from "../../common/components/dotLegalTabs/DotLegalTabs.types.ts";
import RelatedTasksTab from "../../taskManagement/relatedTasksTab/RelatedTasksTab.tsx";
import { EntityType } from "../../common/Common.types.ts";
import LegalEntityAuditGroupAuditsTab from "./legalEntityAuditGroupAuditsTab/LegalEntityAuditGroupAuditsTab.tsx";
import { AuditGroupTabs } from "./LegalEntityAuditGroupOverview.types.ts";
import { useGetRelatedTaskData } from "../../taskManagement/relatedTasksTab/RelatedTasksTab.hooks.ts";

function LegalEntityAuditGroupOverview() {
    const { translateString, translateDate, translateLegalEntityAuditStatus } = useTranslation();
    const { customerName, permissions } = useUserContext();
    const { getAuditGroupsUrl, getGroupEntityUrl, getAuditGroupTaskApiUrl } = useUrlProvider();
    const { vendorManagementFeatures } = usePlanContext();
    const { auditGroupId, isLoading, data, onAuditGroupUpdated, refetch, selectedTab, setSelectedTab } = useLegalEntityAuditGroupDialog();
    const { getTaskTabHeader } = useGetRelatedTaskData(auditGroupId, getAuditGroupTaskApiUrl(auditGroupId));

    const gridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize; lg: GridSize; xl: boolean } = {
        item: true,
        xs: 12,
        sm: 6,
        md: 4,
        lg: 2,
        xl: true,
    };

    const getTabContent = () => {
        const content: Array<TabContent> = [];

        content.push({
            label: translateString("audits"),
            content: <LegalEntityAuditGroupAuditsTab isLoading={isLoading} auditsData={data} refetchData={refetch} />,
            index: AuditGroupTabs.Audits,
        });

        if (permissions.taskPermissions.read) {
            content.push({
                label: getTaskTabHeader(),
                content: (
                    <RelatedTasksTab
                        forEntityId={auditGroupId}
                        forEntityType={EntityType.LegalEntityAuditGroup}
                        forEntityName={data?.name ?? ""}
                        customApiUrl={getAuditGroupTaskApiUrl(auditGroupId)}
                        additionalHeaderTypeTableHeader={translateString("createdFrom")}
                    />
                ),
                index: AuditGroupTabs.Tasks,
            });
        }

        return content;
    };

    return (
        <React.Fragment>
            <AuditGroupHeader
                breadCrumbs={[
                    { name: translateString("externalAudits"), link: getAuditGroupsUrl() },
                    { name: data?.name, tooltip: data?.description },
                ]}
                customerName={customerName}
                onAuditGroupUpdated={onAuditGroupUpdated}
                auditGroupModel={
                    data
                        ? {
                              id: data.id,
                              auditTemplateId: data!.auditTemplateId,
                              auditTemplateName: data.auditTemplate,
                              auditTemplateVersion: data.auditTemplateVersion,
                              deadline: data.deadline,
                              name: data.name,
                              responsibleId: data.responsibleId,
                              description: data.description,
                              senderId: data.senderId,
                              sender: data.sender,
                              sent: data.sent,
                          }
                        : undefined
                }
                showAddLegalEntityBtn
                showDeleteMenuItem
                canDeleteAuditgroup={data !== undefined && data.audits.filter((x) => x.status === LegalEntityAuditStatus.Submitted).length === 0}
            />

            <DotLegalPaper sx={{ marginBottom: 2, minHeight: 132 }} background="smallPurpleWave" backgroundCover>
                <Grid container>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("status")}>
                            <DotLegalFixedHeightText
                                isLoading={isLoading}
                                fontSize="xs"
                                content={
                                    data
                                        ? data.progress === 100
                                            ? translateString("completed")
                                            : data?.sent
                                              ? translateLegalEntityAuditStatus(LegalEntityAuditStatus.InProgress.toString())
                                              : ""
                                        : ""
                                }
                            />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("sent")}>
                            <DotLegalFixedHeightText isLoading={isLoading} fontSize="xs" content={data?.sent ? translateDate(data.sent) : ""} />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("auditTemplate")}>
                            <DotLegalFixedHeightText
                                isLoading={isLoading}
                                fontSize="xs"
                                content={
                                    data ? (
                                        vendorManagementFeatures.auditGroups ? (
                                            <QuestionnaireTemplateLink id={data.auditTemplateId} name={data.auditTemplate} type={"audit"} />
                                        ) : (
                                            data.auditTemplate
                                        )
                                    ) : (
                                        ""
                                    )
                                }
                            />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("deadline")}>
                            <DotLegalFixedHeightText isLoading={isLoading} fontSize="xs" content={data ? translateDate(data.deadline) : ""} />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("responsible")}>
                            <DotLegalFixedHeightText isLoading={isLoading} fontSize="xs" content={data ? data.responsible : ""} />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("sentFrom")}>
                            <DotLegalFixedHeightText
                                isLoading={isLoading}
                                fontSize="xs"
                                content={
                                    data ? (
                                        data.sender ? (
                                            data.senderId ? (
                                                <DotLegalLink bold linkColor={"primary"} to={getGroupEntityUrl(data.senderId)}>
                                                    {data.sender}
                                                </DotLegalLink>
                                            ) : (
                                                data.sender
                                            )
                                        ) : (
                                            customerName
                                        )
                                    ) : (
                                        ""
                                    )
                                }
                            />
                        </DotLegalHeading>
                    </Grid>
                </Grid>
                <Divider
                    sx={{
                        backgroundColor: "#F0F7FC",
                        marginBottom: 2,
                        marginTop: 2,
                        marginLeft: 6,
                        marginRight: 6,
                    }}
                />
                <Grid container>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("legalEntities")}>
                            <DotLegalFixedHeightText isLoading={isLoading} content={data?.numberOfLegalEntities ?? ""} fontSize="xl" />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("opened")}>
                            <DotLegalFixedHeightText isLoading={isLoading} content={data?.openedAuditsCount ?? ""} fontSize="xl" />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("submitted")}>
                            <DotLegalFixedHeightText isLoading={isLoading} content={data?.submittedAuditsCount ?? ""} fontSize="xl" />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("excluded")}>
                            <DotLegalFixedHeightText isLoading={isLoading} content={data?.excludedAuditsCount ?? ""} fontSize="xl" />
                        </DotLegalHeading>
                    </Grid>
                    {data && data.scoreEnabled && (
                        <Grid {...gridProps}>
                            <DotLegalHeading name={translateString("overallRiskLevelAndScore")}>
                                <DotLegalFixedHeightText
                                    isLoading={isLoading}
                                    fontSize="xs"
                                    content={
                                        data && data.overallRiskLevelName && data.overallRiskLevelName && data.overallRiskScore ? (
                                            <Box sx={{ display: "flex", justifyContent: "center", gap: 1, fontWeight: "bold", marginTop: "6px" }}>
                                                <QuestionnaireRisk
                                                    riskLevel={data?.overallRiskLevel}
                                                    riskName={data?.overallRiskLevelName}
                                                    scoreEnabled={data.scoreEnabled}
                                                    status={LegalEntityAuditStatus.Submitted}
                                                />
                                                (
                                                {getQuestionnaireResponseScore(
                                                    true,
                                                    data.scoreEnabled,
                                                    data.overallRiskScore,
                                                    translateString("scoreNotEnabled")
                                                )}
                                                )
                                            </Box>
                                        ) : undefined
                                    }
                                />
                            </DotLegalHeading>
                        </Grid>
                    )}
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("progress")}>
                            <DotLegalFixedHeightText
                                isLoading={isLoading}
                                fontSize="sm"
                                content={
                                    data ? (
                                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                                            <Box width={238}>
                                                <DotLegalCompletedPercentage completed={data.progress} />
                                            </Box>
                                        </Box>
                                    ) : undefined
                                }
                            />
                        </DotLegalHeading>
                    </Grid>
                    {data && !data.scoreEnabled && <Grid {...gridProps}></Grid>}
                </Grid>
            </DotLegalPaper>

            <DotLegalTabs
                fullWidth={false}
                tabStyle="secondary"
                selectedTab={selectedTab!}
                setSelectedTab={(val) => setSelectedTab(val)}
                content={getTabContent()}
                noPadding
            />
        </React.Fragment>
    );
}

export default LegalEntityAuditGroupOverview;
