import { DotLegalHeader, DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";
import React, { useState } from "react";
import DotLegalPaper from "../../common/components/dotLegalPaper/DotLegalPaper";
import { useTranslation } from "../../localization/useTranslation";
import { AnnualWheelMonthEnum, TaskStateEnum } from "../AnnualWheel.types";
import AnnualWheelTaskDeleted from "./AnnualWheelTaskDeleted.svg?react";
import { Box } from "@mui/material";
import { RepetitionIcon } from "../../common/icons";
import { SelectableItem } from "../../processingActivity/ProcessingActivity.types";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import { getTaskColor } from "../../taskManagement/task/Task.styles";
import ComplianceAreaWrapper from "../../common/components/complianceAreaWrapper/ComplianceAreaWrapper";
import AnnualWheelOverflowMenu from "../AnnualWheelOverflowMenu.tsx";
import PlanUpgradeDialogWrapper from "../../plan/planUpgradeDialogWrapper/PlanUpgradeDialogWrapper.tsx";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider.tsx";

export const annualWheelTaskCardHeight = 105;
export const annualWheelTaskCardMargin = 8;

export interface IAnnualWheelTaskProps {
    id: string;
    month: AnnualWheelMonthEnum;
    name: string;
    status: TaskStateEnum;
    deleted: boolean;
    partOfCurrentPlan: boolean;
    areas: Array<SelectableItem>;
    onEditClick: () => void;
    onDeleteClick: () => void;
    isPdf?: boolean;
    recurrenceInterval?: number;
    customId: string | null;
}

function AnnualWheelTask(props: IAnnualWheelTaskProps) {
    const { translateString } = useTranslation();
    const { platformFeatures } = usePlanContext();
    const { permissions } = useUserContext();
    const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);

    const canEditActivity = permissions.taskPermissions.read && !props.deleted;

    const paper = (
        <DotLegalPaper backgroundColor={props.partOfCurrentPlan ? undefined : "lighterGrey"}>
            <Box
                sx={(_) => ({
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    minHeight: "55px",
                })}
            >
                <Box
                    sx={(theme) => ({
                        backgroundColor: getTaskColor(props.status, theme),
                        marginRight: theme.spacing(2),
                        borderRadius: "12px",
                        paddingRight: "6px",
                    })}
                />

                <Box className="tester" sx={{ display: "flex", justifyContent: "space-between", flex: 1 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                        <Box>
                            <DotLegalHeader fontWeight={500} headerStyle={"small"} marginBottom={0} maxNoLines={props.isPdf ? undefined : 1}>
                                {props.customId && <span style={{ marginRight: 10, fontWeight: "bold" }}>{props.customId}</span>}
                                {props.name}
                            </DotLegalHeader>
                        </Box>

                        {platformFeatures.annualWheelComplianceArea && (
                            <Box sx={{ display: "flex", gap: 1 }}>
                                {props.areas.map((x) => (
                                    <ComplianceAreaWrapper key={x.id} complianceArea={x.name} />
                                ))}
                            </Box>
                        )}

                        {props.recurrenceInterval && (
                            <Box sx={{ display: "flex" }}>
                                <Box sx={{ display: "flex", alignItems: "center", marginRight: "4px" }}>{RepetitionIcon}</Box>
                                <DotLegalHeader fontWeight={500} headerStyle={"small"} marginBottom={0} center>
                                    {props.recurrenceInterval === 1
                                        ? translateString("everyMonth")
                                        : translateString("recurrenceInterval", { interval: props.recurrenceInterval })}
                                </DotLegalHeader>
                            </Box>
                        )}
                    </Box>
                    <Box
                        sx={{
                            justifySelf: "flex-end",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Box sx={{ height: "100%", display: "flex", flexDirection: "column-reverse", justifyContent: "space-between" }}>
                            <Box
                                sx={(_) => ({
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    width: "100%",
                                    height: "36px",
                                    "& .MuiButtonBase-root": {
                                        marginBottom: -1.2,
                                    },
                                })}
                            >
                                <AnnualWheelOverflowMenu
                                    onEditClick={props.onEditClick}
                                    onDeleteClick={props.onDeleteClick}
                                    id={props.id}
                                    deleted={props.deleted}
                                    month={props.month}
                                    partOfCurrentPlan={props.partOfCurrentPlan}
                                />
                            </Box>
                            {props.deleted && (
                                <DotLegalTooltip text={translateString("annualActivityIsDeleted")}>
                                    <Box
                                        sx={(_) => ({
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            width: "100%",
                                        })}
                                    >
                                        <Box
                                            sx={(_) => ({
                                                display: "flex",
                                                height: "100%",
                                                width: "36px",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            })}
                                        >
                                            <AnnualWheelTaskDeleted />
                                        </Box>
                                    </Box>
                                </DotLegalTooltip>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </DotLegalPaper>
    );
    return (
        <Box
            className={`avoid-page-break`}
            sx={(theme) => ({
                marginTop: `${annualWheelTaskCardMargin}px`,
                marginBottom: `${annualWheelTaskCardMargin}px`,
                width: "100%",
                height: props.isPdf ? "100%" : undefined,
                "& .MuiPaper-root": {
                    height: props.isPdf ? "auto" : annualWheelTaskCardHeight,
                },
                "&:hover": {
                    cursor: canEditActivity ? "pointer" : "initial",
                },
            })}
            onClick={() => (canEditActivity ? props.onEditClick() : {})}
        >
            {props.partOfCurrentPlan ? (
                paper
            ) : (
                <DotLegalTooltip position={"top"} text={translateString("annualWheelActivityLockedPlan")}>
                    <div onClick={() => setShowUpgradeDialog(true)}>{paper}</div>
                </DotLegalTooltip>
            )}

            {showUpgradeDialog && (
                <PlanUpgradeDialogWrapper onDialogClose={() => setShowUpgradeDialog(false)} text={translateString("annualWheelActivityLockedPlan")} />
            )}
        </Box>
    );
}

export default AnnualWheelTask;
