import { Box, Grid, Skeleton, useTheme } from "@mui/material";
import React from "react";
import {
    DotLegalButton,
    DotLegalChip,
    DotLegalFilterControls,
    DotLegalHeader,
    DotLegalMenuButton,
    DotLegalMultiSelect,
    DotLegalPageHeader,
    DotLegalSwitch,
    useIsBetweenSize,
} from "@dotlegal/dotlegal-ui-components";
import DotLegalPaper from "../common/components/dotLegalPaper/DotLegalPaper";
import { useTranslation } from "../localization/useTranslation";
import { useAnnualWheelDataMapping } from "./AnnualWheel.hooks";
import { completedTaskCountColor, overDueTaskCountColor, plannedTaskCountColor, totalTaskCounterColor, useAnnualWheelStyles } from "./AnnualWheel.styles";
import exportReportWave from "./exportReportWave.svg?url";
import overdueTasksWave from "./overdueTasksWave.svg?url";
import plannedTasksWave from "./plannedTasksWave.svg?url";
import completedTasksWave from "./completedTasksWave.svg?url";
import totalTasksWave from "./totalTasksWave.svg?url";
import AnnualWheelYearPicker from "./annualWheelYearPicker/AnnualWheelYearPicker";
import DeleteAnnualWheelActivityDialog from "./deleteAnnualWheelActivtyDialog/DeleteAnnualWheelActivityDialog";
import { useNavMenuDataMapping } from "../main/NavMenu/NavMenu.hooks";
import { usePlanContext } from "../auth/planProvider/PlanProvider";
import PlanDialogButtonWrapper from "../plan/planDialogButtonWrapper/planDialogButtonWrapper";
import { useUserContext } from "../auth/userContextProvider/UserContextProvider";
import AnnualWheelCalendarOverview from "./annualWheelCalendarOverview/AnnualWheelCalendarOverview";
import AnnualWheelListOverview from "./annualWheelListOverview/AnnualWheelListOverview";
import { GridSizes } from "../common/components/dotLegalTable/DotLegalTable.types";
import { TaskStateEnum } from "./AnnualWheel.types";
import { isNullOrWhitespace } from "../common/stringOperations";
import TemplateAnnualWheelActivityDialog from "./templateAnnualWheelActivityDialog/TemplateAnnualWheelActivityDialog.tsx";
import AnnualWheelAddEditDialog from "./annualWheelAddEditDialog/AnnualWheelAddEditDialog.tsx";

function AnnualWheel() {
    const theme = useTheme();
    const styles = useAnnualWheelStyles();
    const { translateString } = useTranslation();
    const {
        isLoading,
        data,
        year,
        setYear,
        showTemplateAnnualWheelActivityDialog,
        setShowTemplateAnnualWheelActivityDialog,
        refetchData,
        setMonth,
        activity,
        setActivity,
        showDeleteActivityDialog,
        setShowDeleteActivityDialog,
        onDownloadPdfClick,
        isDownloadingPdf,
        permissions,
        onClickDownloadExcelReport,
        isDownloadingReport,
        showListView,
        setShowListView,
        areasFilterOptions,
        businessAreasFilterOptions,
        monthsFilterOptions,
        prioritiesFilterOptions,
        responsiblesFilterOptions,
        statusFilterOptions,
        annualWheelActivityOptions,
        searchedAreas,
        searchedBusinessAreas,
        searchedMonths,
        searchedPriorities,
        searchedResponisbles,
        searchedStatus,
        searchedActivities,
        setSearchedAreas,
        setSearchedBusinessAreas,
        setSearchedMonths,
        setSearchedPriorities,
        setSearchedResponsibles,
        setSearchedStatus,
        setSearchedActivities,
        getFilteredData,
        searchedString,
        setSearchedString,
        onBoxCounterClick,
        getTaskManagementUrlForMonth,
        groupCompanyOptions,
        searchedGroupCompanies,
        setSearchedGroupCompanies,
        hasActivities,
        onCustomTemplateCreated,
        selectedAnnualWheelActivityTemplate,
        showAddEditDialog,
        setShowAddEditDialog,
        onAddEditDialogClosed,
        onTemplateSelected,
        getStartDate,
        onAddEditPreviousClick,
        onMonthAddClicked,
        searchableAnnualWheelActivities,
    } = useAnnualWheelDataMapping();

    const { refetchMenuNotifications } = useNavMenuDataMapping();
    const { platformFeatures } = usePlanContext();
    const { customerName } = useUserContext();

    const tableGridProps: GridSizes = {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 4,
        xl: true,
    };

    function getLeftPageHeaderComponents() {
        return (
            <DotLegalSwitch
                checked={showListView}
                onChange={(showList) => setShowListView(showList)}
                leftLabel={translateString("calendar")}
                rightLabel={translateString("list")}
            />
        );
    }

    function getSearchFields() {
        let searchFields: Array<JSX.Element> = [];

        searchFields.push(
            <DotLegalMultiSelect
                options={monthsFilterOptions}
                chosenOptions={searchedMonths}
                onChange={setSearchedMonths}
                placeholder={translateString("month")}
                label={translateString("month")}
                noOptionsLabel={translateString("noOptions")}
                noMargin
            />
        );

        searchFields.push(
            <DotLegalMultiSelect
                options={annualWheelActivityOptions}
                chosenOptions={searchedActivities}
                onChange={setSearchedActivities}
                placeholder={translateString("activity")}
                label={translateString("activity")}
                noOptionsLabel={translateString("noOptions")}
                noMargin
            />
        );

        searchFields.push(
            <DotLegalMultiSelect
                options={statusFilterOptions}
                chosenOptions={searchedStatus}
                onChange={setSearchedStatus}
                placeholder={translateString("status")}
                label={translateString("status")}
                noOptionsLabel={translateString("noOptions")}
                noMargin
            />
        );

        if (platformFeatures.annualWheelComplianceArea) {
            searchFields.push(
                <Grid {...tableGridProps} item>
                    <DotLegalMultiSelect
                        options={areasFilterOptions}
                        chosenOptions={searchedAreas}
                        onChange={setSearchedAreas}
                        placeholder={translateString("area")}
                        label={translateString("area")}
                        noOptionsLabel={translateString("noOptions")}
                        noMargin
                    />
                </Grid>
            );
        }

        searchFields.push(
            <DotLegalMultiSelect
                options={responsiblesFilterOptions}
                chosenOptions={searchedResponisbles}
                onChange={setSearchedResponsibles}
                placeholder={translateString("responsible")}
                label={translateString("responsible")}
                noOptionsLabel={translateString("noOptions")}
                noMargin
            />
        );

        searchFields.push(
            <DotLegalMultiSelect
                options={prioritiesFilterOptions}
                chosenOptions={searchedPriorities}
                onChange={setSearchedPriorities}
                placeholder={translateString("priority")}
                label={translateString("priority")}
                noOptionsLabel={translateString("noOptions")}
                noMargin
            />
        );

        searchFields.push(
            <DotLegalMultiSelect
                options={businessAreasFilterOptions}
                chosenOptions={searchedBusinessAreas}
                onChange={setSearchedBusinessAreas}
                placeholder={translateString("businessArea")}
                label={translateString("businessArea")}
                noOptionsLabel={translateString("noOptions")}
                noMargin
            />
        );

        searchFields.push(
            <DotLegalMultiSelect
                options={groupCompanyOptions}
                chosenOptions={groupCompanyOptions.length === 2 ? [groupCompanyOptions[0].id, groupCompanyOptions[1].id] : searchedGroupCompanies}
                onChange={setSearchedGroupCompanies}
                placeholder={translateString("groupCompanies")}
                label={translateString("groupCompanies")}
                noOptionsLabel={translateString("noOptions")}
                disabled={groupCompanyOptions.length === 2}
                itemsIsNonRemovable={groupCompanyOptions.length === 2}
                noMargin
            />
        );

        return searchFields;
    }

    return (
        <React.Fragment>
            <DotLegalPageHeader
                userContext={{ customerName }}
                breadCrumbs={[{ name: translateString("annualWheel") }]}
                topLeftComponent={
                    <AnnualWheelYearPicker
                        showPreviousYear={data?.previousYearHasActivities}
                        sx={styles.yearPicker}
                        year={year}
                        onChange={setYear}
                        isLoading={isLoading}
                    />
                }
                leftComponent={getLeftPageHeaderComponents()}
                rightComponent={
                    <PlanDialogButtonWrapper text={translateString("upgradePrivacyPlanToday")} enabled={!platformFeatures.annualWheelReport}>
                        <DotLegalMenuButton
                            buttonType={"secondary"}
                            isLoading={isDownloadingPdf || isDownloadingReport}
                            disabled={isDownloadingPdf || isDownloadingReport || !platformFeatures.annualWheelReport}
                            isLocked={!platformFeatures.annualWheelReport}
                            upgradePlatformText={translateString("upgradePrivacyPlanToday")}
                            menuItems={[
                                {
                                    onClick: () => {
                                        onDownloadPdfClick().then();
                                    },
                                    disabled: isDownloadingPdf,
                                    children: translateString("downLoadAnnualWheel"),
                                },
                                {
                                    onClick: () => {
                                        onClickDownloadExcelReport().then();
                                    },
                                    children: translateString("downloadReport"),
                                },
                            ]}
                        >
                            {translateString("download")}
                        </DotLegalMenuButton>
                    </PlanDialogButtonWrapper>
                }
            />
            <Grid
                container
                spacing={2}
                sx={(theme1) => ({
                    "& .MuiChip-label": {
                        fontWeight: "bold",
                    },
                })}
            >
                <Grid item xs={12} sm={12} md={8} lg={5} xl={4}>
                    <DotLegalPaper
                        sx={() => ({
                            backgroundImage: `url("${exportReportWave}")`,
                            ...styles.taskCounterPaper,
                            alignItems: "flex-start",
                            justifyContent: "center",
                        })}
                    >
                        {permissions.annualWheelPermission.create && (
                            <Box sx={{ display: "flex", gap: 2 }}>
                                <DotLegalButton
                                    id={"create-annual-wheel-activity"}
                                    buttonType={"primary"}
                                    onClick={() => {
                                        setMonth(1 + new Date().getMonth());
                                        setShowAddEditDialog(true);
                                    }}
                                    toolTip={translateString("createAnnualWheelActivityInfoText")}
                                    showTooltipAsInfoIcon
                                >
                                    {translateString("createActivity")}
                                </DotLegalButton>

                                <DotLegalButton
                                    id={"create-annual-wheel-activity-from-template"}
                                    buttonType={"secondary"}
                                    onClick={() => {
                                        setMonth(1 + new Date().getMonth());
                                        setShowTemplateAnnualWheelActivityDialog(true);
                                    }}
                                    toolTip={translateString("createAnnualWheelActivityFromTemplateInfoText")}
                                    showTooltipAsInfoIcon
                                >
                                    {translateString("createFromTemplate")}
                                </DotLegalButton>
                            </Box>
                        )}
                    </DotLegalPaper>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg>
                    <Box sx={(theme1) => ({})}>
                        <DotLegalPaper
                            sx={{
                                backgroundImage: `url("${overdueTasksWave}")`,
                                outline: searchedStatus.includes(TaskStateEnum.Overdue.toString()) ? theme.palette.primary.dark + " solid" : "",
                                ...styles.taskCounterPaper,
                            }}
                            clickableRows
                            onClick={() => onBoxCounterClick(TaskStateEnum.Overdue)}
                        >
                            <DotLegalHeader headerStyle={"medium"} center>
                                {translateString("overdueActivities")}
                            </DotLegalHeader>
                            {!isLoading ? (
                                <DotLegalChip alternativeColor={overDueTaskCountColor()} value={data!.overdueTaskCount!.toString()}></DotLegalChip>
                            ) : (
                                <Skeleton>
                                    <DotLegalChip alternativeColor={totalTaskCounterColor()} value={"0"}></DotLegalChip>
                                </Skeleton>
                            )}
                        </DotLegalPaper>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg>
                    <DotLegalPaper
                        sx={{
                            backgroundImage: `url("${plannedTasksWave}")`,
                            outline: searchedStatus.includes(TaskStateEnum.Ready.toString()) ? theme.palette.primary.dark + " solid" : "",
                            ...styles.taskCounterPaper,
                        }}
                        clickableRows
                        onClick={() => onBoxCounterClick(TaskStateEnum.Ready)}
                    >
                        <Box
                            sx={(_) => ({
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            })}
                        >
                            <DotLegalHeader headerStyle={"medium"} center>
                                {translateString("plannedActivities")}
                            </DotLegalHeader>
                            {!isLoading ? (
                                <DotLegalChip alternativeColor={plannedTaskCountColor()} value={data!.plannedTaskCount!.toString()}></DotLegalChip>
                            ) : (
                                <Skeleton>
                                    <DotLegalChip alternativeColor={totalTaskCounterColor()} value={"0"}></DotLegalChip>
                                </Skeleton>
                            )}
                        </Box>
                    </DotLegalPaper>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg>
                    <DotLegalPaper
                        sx={{
                            backgroundImage: `url("${completedTasksWave}")`,
                            outline: searchedStatus.includes(TaskStateEnum.Completed.toString()) ? theme.palette.primary.dark + " solid" : "",
                            ...styles.taskCounterPaper,
                        }}
                        clickableRows
                        onClick={() => onBoxCounterClick(TaskStateEnum.Completed)}
                    >
                        <DotLegalHeader headerStyle={"medium"} center>
                            {translateString("completedActivities")}
                        </DotLegalHeader>
                        {!isLoading ? (
                            <DotLegalChip alternativeColor={completedTaskCountColor()} value={data!.completedTaskCount!.toString()}></DotLegalChip>
                        ) : (
                            <Skeleton>
                                <DotLegalChip alternativeColor={totalTaskCounterColor()} value={"0"}></DotLegalChip>
                            </Skeleton>
                        )}
                    </DotLegalPaper>
                </Grid>
                {!useIsBetweenSize(1200, 1946) && (
                    <Grid item xs={12} sm md={4} lg>
                        <DotLegalPaper sx={{ backgroundImage: `url("${totalTasksWave}")`, ...styles.taskCounterPaper }}>
                            <DotLegalHeader headerStyle={"medium"} center>
                                {translateString("totalTasksForYear")}
                            </DotLegalHeader>
                            {!isLoading ? (
                                <DotLegalChip alternativeColor={totalTaskCounterColor()} value={data!.totalTaskCount!.toString()}></DotLegalChip>
                            ) : (
                                <Skeleton>
                                    <DotLegalChip alternativeColor={totalTaskCounterColor()} value={"0"}></DotLegalChip>
                                </Skeleton>
                            )}
                        </DotLegalPaper>
                    </Grid>
                )}
            </Grid>

            <Box sx={{ marginTop: theme.spacing(2) }}>
                <DotLegalPaper removePadding>
                    <Box sx={{ padding: 2 }}>
                        <DotLegalFilterControls
                            extraControls={getSearchFields()}
                            searchFieldPlaceholder={translateString("search")}
                            onSearchStringChange={setSearchedString}
                            searchString={searchedString}
                        />
                        {showListView ? (
                            <AnnualWheelListOverview
                                setShowDeleteActivityDialog={setShowDeleteActivityDialog}
                                activties={getFilteredData(searchableAnnualWheelActivities ?? [])}
                                isLoading={isLoading}
                                setSelectedActivity={setActivity}
                                hasActivities={hasActivities}
                                setShowEditActivityDialog={() => setShowAddEditDialog(true)}
                            />
                        ) : (
                            <AnnualWheelCalendarOverview
                                activities={getFilteredData(searchableAnnualWheelActivities ?? [])}
                                isLoading={isLoading}
                                onMonthAddClicked={onMonthAddClicked}
                                setSelectedActivity={setActivity}
                                year={year}
                                setShowDeleteActivityDialog={setShowDeleteActivityDialog}
                                getTaskManagementUrlForMonthAndYear={getTaskManagementUrlForMonth}
                                extendColumns={!isNullOrWhitespace(searchedString)}
                                setShowEditActivityDialog={() => setShowAddEditDialog(true)}
                            />
                        )}
                    </Box>
                </DotLegalPaper>
            </Box>

            {showTemplateAnnualWheelActivityDialog && (
                <TemplateAnnualWheelActivityDialog
                    onDialogClose={() => {
                        setShowTemplateAnnualWheelActivityDialog(false);
                        setActivity(undefined);
                        onTemplateSelected(undefined);
                    }}
                    onNextClick={onTemplateSelected}
                    onCustomTemplateAdded={(customTemplateModel) => onCustomTemplateCreated(customTemplateModel)}
                />
            )}

            {showDeleteActivityDialog.showDialog && (
                <DeleteAnnualWheelActivityDialog
                    activityId={activity!.id}
                    activityName={showDeleteActivityDialog.activityName}
                    onCloseDialog={() => {
                        setShowDeleteActivityDialog({ showDialog: false, activityName: "" });
                        setActivity(undefined);
                    }}
                    onDeleteClick={() => {
                        refetchData();
                        refetchMenuNotifications().then();
                        setShowDeleteActivityDialog({ showDialog: false, activityName: "" });
                        setActivity(undefined);
                    }}
                    hardDelete={activity?.deleted}
                />
            )}

            {showAddEditDialog && (
                <AnnualWheelAddEditDialog
                    annualWheelActivityId={activity?.id}
                    onDialogClose={onAddEditDialogClosed}
                    onPreviousClick={() => onAddEditPreviousClick(!isNullOrWhitespace(activity?.id))}
                    selectedAnnualWheelActivityTemplate={selectedAnnualWheelActivityTemplate!}
                    onSave={refetchData}
                    startDate={getStartDate()}
                />
            )}
        </React.Fragment>
    );
}

export default AnnualWheel;
