import React from "react";
import DotLegalDeleteDialogWrapper from "../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { useDeleteTaskHook } from "./DeleteTaskDialog.hooks";
import { useTranslation } from "../../localization/useTranslation";
import { Trans } from "react-i18next";

export interface IDeleteTaskDialog {
    taskId: string;
    taskName: string;
    onCloseDialog: () => void;
    onDeleteClick: () => void;
}

function DeleteTaskDialog(props: IDeleteTaskDialog) {
    const { translateString } = useTranslation();
    const { removeTask, inProgress, isLoading, isLastTaskOnAnnualWheelActivity } = useDeleteTaskHook(props);

    return (
        <DotLegalDeleteDialogWrapper
            open
            inProgress={inProgress}
            itemForDeletion={props.taskName}
            onDialogClose={props.onCloseDialog}
            onOkClick={() => removeTask()}
            isLoading={isLoading}
            additionalWarning={
                isLastTaskOnAnnualWheelActivity !== undefined && isLastTaskOnAnnualWheelActivity ? (
                    <Trans i18nKey="deleteTaskWarning" values={{ name: props.taskName }} />
                ) : undefined
            }
        />
    );
}

export default DeleteTaskDialog;
