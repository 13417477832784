import { Badge, Box, Grid, styled, useTheme } from "@mui/material";
import React from "react";
import {
    DotLegalChip,
    DotLegalDatePicker,
    DotLegalHeader,
    DotLegalMultiSelect,
    DotLegalSelect,
    DotLegalTextField,
    DotLegalTooltip,
} from "@dotlegal/dotlegal-ui-components";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { useMasterDataSection } from "./MasterDataSection.hooks";
import { useTranslation } from "../../../localization/useTranslation";
import { AnnualWheelMonthEnum, ApprovalOption, TaskRawStateEnum } from "../../../annualWheel/AnnualWheel.types";
import { TrackingEvent, useTrackAIEvent } from "../../../processingActivity/hooks/useTracking";
import { useDotLegalFileUploadDataMapping } from "../../../common/components/dotLegalFileUploadTable/DotLegalFileUploadTable.hooks";
import { AnnualWheelActivityType, AnnualWheelPriority } from "../../../annualWheel/annualWheelDialog/AnnualWheelDialog.types";
import { TaskMasterDataStaticDataViewModel } from "./MasterDataSection.types";
import DotLegalWarningTriangle from "../../../common/components/dotLegalWarningTriangle/DotLegalWarningTriangle";
import { getTaskColor } from "../../task/Task.styles";
import { useRiskIndicator } from "../../../riskAssessment/riskIndicator/RiskIndicator.hooks";
import { isResponsibleRequired } from "../../../annualWheel/ApprovalOptions";
import { isNullOrWhitespace, setFirstLetterToLowerCase } from "../../../common/stringOperations";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";
import { EntityType } from "../../../common/Common.types.ts";
import PlanInputFieldWrapper from "../../../plan/planInputFieldWrapper/PlanInputFieldWrapper";
import { PurpleLockIcon } from "../../../common/icons";
import DotLegalCollapse from "../../../common/components/dotLegalCollapse/DotLegalCollapse";
import TagsMultiSelectStandAlone from "../../../common/components/tagsMultiSelect/tagsMultiSelectAutoUpdater/TagsMultiSelectAutoUpdater.tsx";

export interface MasterDataSectionProps {
    taskId?: string;
    masterData?: TaskMasterDataStaticDataViewModel;
    isLoading: boolean;
    passIsDoneLoading?: (newValue: boolean) => void;
    refetchTask: () => void;
}

export const SelectWrapper = styled("div")({
    "& .MuiBox-root:first-child": {
        marginTop: 0,
        marginBottom: 0,
    },
});

function MasterDataSection(props: MasterDataSectionProps) {
    const bottomMargin = 2;
    const leftGridSize = 5;
    const rightGridSize = 7;
    const trackEvent = useTrackAIEvent();
    const theme = useTheme();
    const { permissions } = useUserContext();
    const { platformFeatures } = usePlanContext();
    const { translateString } = useTranslation();
    const { addTaskToCalendar } = useDotLegalFileUploadDataMapping();
    const {
        options,
        completedAllowed,
        businessAreaData,
        assigneesData,
        responsiblesData,
        complianceAreas,
        isSectionLoading,
        showAddToCalenderLink,
        priorityOptions,
        data,
        masterDataUpdater,
        groupEntities,
        expandDetails,
        setExpandDetails,
    } = useMasterDataSection(props);
    const { getRiskIcon } = useRiskIndicator();

    const isAdHocTask = props.masterData?.isAdHocTask;

    const convertDate = () => {
        return new Date(props.masterData!.deadline);
    };

    const getValue = (value: string) => {
        return <div>{value}</div>;
    };

    const getLoadingBox = () => {
        return (
            <SelectWrapper>
                <DotLegalSelect
                    disabled={!permissions.taskPermissions.edit}
                    options={[]}
                    selectedItem={undefined}
                    disableClearable
                    onChange={() => {}}
                    placeholder={""}
                    variant={"standard"}
                    label={"Loading..."}
                    noOptionsLabel={"Loading..."}
                    isLoading={isSectionLoading}
                />
            </SelectWrapper>
        );
    };

    const getBagdeColorForTaskState = (id: string) => {
        switch (id) {
            case "1":
                return getTaskColor(TaskRawStateEnum.Ready, theme);
            case "2":
                return getTaskColor(TaskRawStateEnum.InProgress, theme);
            case "5":
                return getTaskColor(TaskRawStateEnum.ReadyForApproval, theme);
            default:
                return getTaskColor(TaskRawStateEnum.Completed, theme);
        }
    };

    const getAddToOutlook = () => {
        const content = (
            <Box
                sx={{
                    "& .MuiTypography-root:hover": {
                        cursor: platformFeatures.addTasksToOutlook ? "pointer" : "default",
                        textDecoration: platformFeatures.addTasksToOutlook ? "underline" : "none",
                    },
                }}
            >
                <DotLegalHeader
                    marginBottom={0}
                    headerStyle={"extraSmall"}
                    color="secondary"
                    fontWeight={400}
                    onClick={() => {
                        if (platformFeatures.addTasksToOutlook) {
                            trackEvent(TrackingEvent.TaskAddedToOutlook, { task: data!.id });
                            addTaskToCalendar(data!.id, props.masterData!.name);
                        }
                    }}
                >
                    {translateString("addToOutLook")}
                </DotLegalHeader>
            </Box>
        );

        if (platformFeatures.addTasksToOutlook) {
            return content;
        }

        return (
            <DotLegalTooltip text={translateString("upgradePrivacyPlanToday")}>
                <span>{content}</span>
            </DotLegalTooltip>
        );
    };

    function canEditAdHocTaskMasterData() {
        if (props.masterData?.isAdHocTask) {
            return permissions.taskPermissions.edit;
        }

        return false;
    }

    function hasEditAccess() {
        if (props.masterData?.isAdHocTask) {
            return permissions.taskPermissions.edit;
        }

        return permissions.canManageAnnualWheelActivityTaskMasterData;
    }

    const getReadOnlyResponsible = () => {
        if (isSectionLoading) return getLoadingBox();

        const responsible = responsiblesData?.find((x) => x.id === data?.responsible);
        return (
            <DotLegalHeader color={responsible ? "primary" : "lightgrey"} fontWeight={400} headerStyle={"small"}>
                {responsible ? responsible.name : translateString("noResponsible")}
            </DotLegalHeader>
        );
    };

    const getReadOnlyPriority = () => {
        if (isSectionLoading) return getLoadingBox();

        const name = data?.priority ? translateString(setFirstLetterToLowerCase(AnnualWheelPriority[data!.priority].toString())) : "";
        return (
            <DotLegalHeader color={"primary"} fontWeight={400} headerStyle={"small"}>
                <Box sx={{ "& svg": { marginRight: "12px" } }}>
                    {getRiskIcon(Number(data?.priority))}
                    {name}
                </Box>
            </DotLegalHeader>
        );
    };

    const getResponsibleLockedTooltip = () => {
        if (data?.approval === ApprovalOption.ApprovalNotRequiredResponsibleGetNotified) {
            return translateString("responsibleLockedRequiredForCompletionNotification");
        } else if (data?.approval === ApprovalOption.ApprovalRequiredByResponsible) {
            return translateString("responsibleLockedRequiredForCompletion");
        } else return "";
    };

    function getApprovalLockedTooltip() {
        if (data?.responsible) {
            return "";
        }

        return translateString("responsibleRequiredForApprovalTooltip");
    }

    const responsibleNotChosen = data && (data.responsible === undefined || data.responsible === null || data.responsible === "");
    let assigneesPlaceholder = "";
    if (responsiblesData) {
        assigneesPlaceholder = data && data.responsible && data.assignees.length === 0 ? responsiblesData.find((x) => x.id === data.responsible)!.name : "";
    }

    return (
        <Box>
            <DotLegalSelect
                disabled={!permissions.taskPermissions.edit}
                width={"40%"}
                disableClearable
                noMargin
                label=""
                options={options}
                selectedItem={data?.taskState.toString()}
                onChange={async (state) => await masterDataUpdater.onProgressChange(state)}
                placeholder=""
                warningText={completedAllowed}
                noOptionsLabel={translateString("noOptions")}
                isLoading={isSectionLoading}
                renderOptions={(option) => (
                    <Box>
                        <Badge
                            sx={{
                                marginRight: 1.5,
                                "& .MuiBadge-badge": {
                                    backgroundColor: getBagdeColorForTaskState(option.id),
                                },
                            }}
                            variant="dot"
                        />
                        {option.name}
                    </Box>
                )}
                inputStartAdornment={
                    data ? (
                        <Badge
                            sx={{
                                "& .MuiBadge-badge": {
                                    backgroundColor: getTaskColor(data!.taskState, theme),
                                },
                            }}
                            variant="dot"
                        />
                    ) : undefined
                }
                disableSearch
            />
            <DotLegalCollapse
                isExpanded={expandDetails}
                onExpandClick={() => {
                    if (expandDetails) {
                        setExpandDetails(false);
                    } else {
                        setExpandDetails(true);
                    }
                }}
                collapseName={translateString("details")}
                persistCollapseName
                leftAlign
                sx={{
                    marginTop: 2,
                    borderWidth: "1px",
                    "&.MuiCollapse-root": {
                        backgroundColor: "white",
                    },
                }}
                isCollapseNameBold
            >
                <Box
                    sx={(theme) => ({
                        marginTop: 2,
                        "& .MuiInputBase-root": {
                            marginTop: 0,
                        },
                        "& .MuiInputLabel-root": {
                            display: "none",
                        },
                    })}
                >
                    <Grid container>
                        <Grid item xs={leftGridSize}>
                            <DotLegalHeader marginBottom={bottomMargin} headerStyle={"small"}>
                                {translateString("deadline")}
                            </DotLegalHeader>
                        </Grid>
                        <Grid item xs={rightGridSize}>
                            {isSectionLoading ? (
                                getLoadingBox()
                            ) : (
                                <>
                                    {permissions.taskPermissions.edit && isAdHocTask ? (
                                        <DotLegalDatePicker
                                            label={""}
                                            value={data?.deadline ?? null}
                                            onChange={masterDataUpdater.onDeadlineChange}
                                            language={"da"}
                                            variant="standard"
                                            noMargin
                                            view="month"
                                            minDate={new Date(new Date().getFullYear(), 0, 1)}
                                            maxDate={new Date(new Date().getFullYear() + 1, 11, 31)}
                                            disableKeyboardInput
                                        />
                                    ) : (
                                        <DotLegalHeader marginBottom={0} headerStyle={"small"} fontWeight={500}>
                                            {getValue(
                                                `${translateString(
                                                    AnnualWheelMonthEnum[convertDate().getMonth() + 1].toLocaleLowerCase()
                                                )} ${convertDate().getFullYear()}`
                                            )}
                                        </DotLegalHeader>
                                    )}

                                    {props.masterData?.isOverdue && <DotLegalWarningTriangle warningText={translateString("deadlineOverdue")} />}

                                    {showAddToCalenderLink() && getAddToOutlook()}
                                </>
                            )}
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={leftGridSize}>
                            <DotLegalHeader marginBottom={bottomMargin} headerStyle={"small"} toolTip={getResponsibleLockedTooltip()}>
                                {translateString("responsible")}
                            </DotLegalHeader>
                        </Grid>
                        <Grid item xs={rightGridSize}>
                            {props.masterData?.type === AnnualWheelActivityType.ProcessingActivityValidation || !hasEditAccess() ? (
                                getReadOnlyResponsible()
                            ) : (
                                <SelectWrapper>
                                    <DotLegalSelect
                                        disableClearable={isResponsibleRequired(data?.approval) || data?.businessAreas.length === 0}
                                        options={responsiblesData}
                                        isLoading={isSectionLoading}
                                        selectedItem={data?.responsible}
                                        placeholder={translateString("addResponsible")}
                                        variant={"standard"}
                                        label={translateString("responsible")}
                                        onChange={async (responsible) => await masterDataUpdater.onResponsibleChange(responsible)}
                                        noOptionsLabel={translateString("noOptions")}
                                    />
                                </SelectWrapper>
                            )}
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={leftGridSize}>
                            <DotLegalHeader marginBottom={bottomMargin} headerStyle={"small"}>
                                {translateString("priority")}
                            </DotLegalHeader>
                        </Grid>
                        <Grid item xs={rightGridSize}>
                            {!hasEditAccess() ? (
                                getReadOnlyPriority()
                            ) : (
                                <SelectWrapper>
                                    <DotLegalSelect
                                        options={priorityOptions()}
                                        selectedItem={data?.priority.toString()}
                                        disableClearable
                                        onChange={async (priority) => await masterDataUpdater.onPriorityChange(priority)}
                                        placeholder={""}
                                        variant={"standard"}
                                        label={translateString("priority")}
                                        noOptionsLabel={translateString("noOptions")}
                                        isLoading={isSectionLoading}
                                        renderOptions={(option) => (
                                            <Box sx={{ "& svg": { marginRight: "12px" } }}>
                                                {getRiskIcon(Number(option.id))}
                                                {option.name}
                                            </Box>
                                        )}
                                        inputStartAdornment={data ? <Box sx={{ marginLeft: "-12px" }}>{getRiskIcon(data.priority)}</Box> : undefined}
                                    />
                                </SelectWrapper>
                            )}
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={leftGridSize}>
                            <DotLegalHeader marginBottom={bottomMargin} headerStyle={"small"}>
                                {translateString("assign")}
                            </DotLegalHeader>
                        </Grid>
                        <Grid item xs={rightGridSize}>
                            {isSectionLoading ? (
                                getLoadingBox()
                            ) : hasEditAccess() ? (
                                <SelectWrapper>
                                    <DotLegalMultiSelect
                                        disabled={!hasEditAccess()}
                                        options={assigneesData}
                                        chosenOptions={data?.assignees ?? []}
                                        placeholder={responsibleNotChosen ? translateString("assignTo") : assigneesPlaceholder}
                                        variant={"standard"}
                                        label={""}
                                        onChange={async (assigness) => await masterDataUpdater.onAssigneesChange(assigness)}
                                        noOptionsLabel={translateString("noOptions")}
                                    />
                                </SelectWrapper>
                            ) : (
                                <Box sx={{ display: "flex", flexWrap: "wrap", marginBottom: 1 }}>
                                    {assigneesData
                                        ?.filter((x) => data!.assignees.indexOf(x.id) > -1)
                                        .map((x) => {
                                            return (
                                                <Box sx={{ marginRight: 1, marginBottom: 0.5 }} key={x.id}>
                                                    <DotLegalChip isSelected size="small" key={x.id} value={x.name}></DotLegalChip>
                                                </Box>
                                            );
                                        })}
                                </Box>
                            )}
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={leftGridSize}>
                            <DotLegalHeader marginBottom={bottomMargin} headerStyle={"small"}>
                                {translateString("businessAreas")}
                            </DotLegalHeader>
                        </Grid>
                        <Grid item xs={rightGridSize}>
                            {isSectionLoading ? (
                                getLoadingBox()
                            ) : props.masterData?.type === AnnualWheelActivityType.ProcessingActivityValidation || !hasEditAccess() ? (
                                <Box sx={{ display: "flex", flexWrap: "wrap", marginBottom: 1 }}>
                                    {businessAreaData
                                        ?.filter((x) => data!.businessAreas.indexOf(x.id) > -1)
                                        .map((x) => {
                                            return (
                                                <Box sx={{ marginRight: 1, marginBottom: 0.5 }} key={x.id}>
                                                    <DotLegalChip isSelected size="small" key={x.id} color={x.color} value={x.name}></DotLegalChip>
                                                </Box>
                                            );
                                        })}
                                </Box>
                            ) : (
                                <SelectWrapper>
                                    <DotLegalMultiSelect
                                        label={translateString("businessAreas")}
                                        options={businessAreaData}
                                        chosenOptions={data?.businessAreas ?? []}
                                        placeholder={translateString("addBusinessArea")}
                                        variant={"standard"}
                                        onChange={async (businessAreas) => await masterDataUpdater.onBusinessAreaChange(businessAreas)}
                                        noOptionsLabel={translateString("noOptions")}
                                        itemsIsNonRemovable={
                                            props.masterData?.isAdHocTask && isNullOrWhitespace(data?.responsible) && data?.businessAreas.length === 1
                                        }
                                        disableClearable
                                    />
                                </SelectWrapper>
                            )}
                        </Grid>
                    </Grid>

                    {props.masterData?.type !== AnnualWheelActivityType.ProcessingActivityValidation && (
                        <Grid container>
                            <Grid item xs={leftGridSize}>
                                <DotLegalHeader marginBottom={bottomMargin} headerStyle={"small"}>
                                    {translateString("complianceAreas")}
                                </DotLegalHeader>
                            </Grid>
                            <Grid item xs={rightGridSize}>
                                {isSectionLoading ? (
                                    getLoadingBox()
                                ) : canEditAdHocTaskMasterData() ? (
                                    <SelectWrapper>
                                        <DotLegalMultiSelect
                                            label={translateString("complianceAreas")}
                                            options={complianceAreas}
                                            isLoading={isSectionLoading}
                                            chosenOptions={data?.complianceAreas ?? []}
                                            placeholder={translateString("addComplianceArea")}
                                            variant={"standard"}
                                            onChange={async (complianceAreas) => await masterDataUpdater.onComplianceAreaChange(complianceAreas)}
                                            noOptionsLabel={translateString("noOptions")}
                                        />
                                    </SelectWrapper>
                                ) : (
                                    <Box sx={{ display: "flex", flexWrap: "wrap", marginBottom: 1 }}>
                                        {complianceAreas
                                            ?.filter((x) => data!.complianceAreas.indexOf(x.id) > -1)
                                            .map((x) => {
                                                return (
                                                    <Box sx={{ marginRight: 1, marginBottom: 0.5 }} key={x.id}>
                                                        <DotLegalChip isSelected size="small" key={x.id} value={x.name}></DotLegalChip>
                                                    </Box>
                                                );
                                            })}
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    )}

                    <Grid container>
                        <Grid item xs={leftGridSize}>
                            <DotLegalHeader marginBottom={bottomMargin} headerStyle={"small"}>
                                ID
                            </DotLegalHeader>
                        </Grid>
                        <Grid item xs={rightGridSize}>
                            {isSectionLoading ? (
                                getLoadingBox()
                            ) : canEditAdHocTaskMasterData() ? (
                                <DotLegalTextField
                                    noMargin
                                    placeholder={translateString("addId")}
                                    label={translateString("addId")}
                                    variant="standard"
                                    value={data?.customId ?? ""}
                                    debounce
                                    onChange={async (value) => await masterDataUpdater.onCustomIdChange(value)}
                                />
                            ) : (
                                <DotLegalHeader marginBottom={bottomMargin} headerStyle={"small"} fontWeight={500}>
                                    {props.masterData?.customId}
                                </DotLegalHeader>
                            )}
                        </Grid>
                    </Grid>

                    {permissions.canAccessTags && (
                        <Grid container>
                            <Grid item xs={leftGridSize}>
                                <DotLegalHeader marginBottom={bottomMargin} headerStyle={"small"}>
                                    {translateString("tags")}
                                </DotLegalHeader>
                            </Grid>

                            <Grid item xs={rightGridSize}>
                                <SelectWrapper>
                                    <TagsMultiSelectStandAlone
                                        entityId={props.taskId!}
                                        entityType={EntityType.Task}
                                        isLoading={isSectionLoading}
                                        variant="standard"
                                        disabled={!hasEditAccess()}
                                        displayOnlyAsChips={!hasEditAccess()}
                                        placeHolder={translateString("addTag")}
                                        hideLabel={false}
                                    />
                                </SelectWrapper>
                            </Grid>
                        </Grid>
                    )}

                    <Grid container>
                        <Grid item xs={leftGridSize}>
                            <DotLegalHeader headerStyle={"small"}>{translateString("notificationDays")}</DotLegalHeader>
                        </Grid>
                        <Grid item xs={rightGridSize}>
                            {isSectionLoading ? (
                                getLoadingBox()
                            ) : canEditAdHocTaskMasterData() ? (
                                <DotLegalTextField
                                    noMargin
                                    placeholder={translateString("addNotification")}
                                    label={translateString("addNotification")}
                                    variant="standard"
                                    value={data?.notificationDays?.toString() ?? ""}
                                    debounce
                                    onChange={async (value) => await masterDataUpdater.onNotificationDaysChange(value)}
                                    onlyNumber="integer"
                                    minNumberValue={1}
                                    maxNumberValue={90}
                                />
                            ) : (
                                <DotLegalHeader headerStyle={"small"} fontWeight={500}>
                                    {data?.notificationDays}
                                </DotLegalHeader>
                            )}
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={leftGridSize}>
                            <DotLegalHeader headerStyle={"small"}>{translateString("groupCompaniesParenthesis")}</DotLegalHeader>
                        </Grid>
                        <Grid item xs={rightGridSize}>
                            {isSectionLoading ? (
                                getLoadingBox()
                            ) : canEditAdHocTaskMasterData() ? (
                                <SelectWrapper>
                                    <DotLegalSelect
                                        options={groupEntities}
                                        onChange={async (value) => await masterDataUpdater.onGroupEntityChange(value!)}
                                        label={""}
                                        noOptionsLabel={""}
                                        noMargin
                                        variant="standard"
                                        disableClearable
                                        selectedItem={data?.groupEntityId ?? "all"}
                                    />
                                </SelectWrapper>
                            ) : (
                                <DotLegalHeader headerStyle={"small"} fontWeight={500}>
                                    {props.masterData?.legalEntityName ?? translateString("entireGroup")}
                                </DotLegalHeader>
                            )}
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={leftGridSize}>
                            <DotLegalHeader headerStyle={"small"} toolTip={getApprovalLockedTooltip()}>
                                {translateString("approval")}
                            </DotLegalHeader>
                        </Grid>
                        <Grid item xs={rightGridSize}>
                            {isSectionLoading ? (
                                getLoadingBox()
                            ) : canEditAdHocTaskMasterData() ? (
                                <PlanInputFieldWrapper hasAccess={platformFeatures.signOff} hoverText={translateString("upgradePrivacyPlanToday")}>
                                    <DotLegalSelect
                                        options={[
                                            {
                                                id: ApprovalOption.ApprovalNotRequired.toString(),
                                                name: translateString("approvalNotRequired"),
                                            },
                                            {
                                                id: ApprovalOption.ApprovalNotRequiredResponsibleGetNotified.toString(),
                                                name: translateString("approvalNotRequiredResponsibleGetNotified"),
                                            },
                                            {
                                                id: ApprovalOption.ApprovalRequiredByResponsible.toString(),
                                                name: translateString("approvalRequiredByResponsible"),
                                            },
                                        ]}
                                        isLoading={isSectionLoading}
                                        selectedItem={data?.approval.toString()}
                                        placeholder={""}
                                        label={""}
                                        onChange={async (option) => masterDataUpdater.onApprovalChange(option!)}
                                        noMargin
                                        noOptionsLabel={translateString("noOptions")}
                                        disableClearable
                                        disableSearch
                                        disabled={!platformFeatures.signOff || isNullOrWhitespace(data?.responsible)}
                                        icon={!platformFeatures.signOff ? PurpleLockIcon : undefined}
                                        variant="standard"
                                    />
                                </PlanInputFieldWrapper>
                            ) : (
                                <DotLegalHeader headerStyle={"small"} fontWeight={500}>
                                    {translateString(setFirstLetterToLowerCase(ApprovalOption[data?.approval!].toString()))}
                                </DotLegalHeader>
                            )}
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={leftGridSize}>
                            <DotLegalHeader marginBottom={0} headerStyle={"small"}>
                                {translateString("documentation")}
                            </DotLegalHeader>
                        </Grid>
                        <Grid item xs={rightGridSize}>
                            {isSectionLoading ? (
                                getLoadingBox()
                            ) : canEditAdHocTaskMasterData() ? (
                                <SelectWrapper>
                                    <DotLegalSelect
                                        options={[
                                            { id: "1", name: translateString("documentationIsRequired") },
                                            { id: "0", name: translateString("documentationIsNotRequired") },
                                        ]}
                                        onChange={async (value) => masterDataUpdater.onDocumentationRequiredChange(value)}
                                        label={translateString("documentation")}
                                        placeholder={translateString("documentation")}
                                        noOptionsLabel={""}
                                        noMargin
                                        disableClearable
                                        variant="standard"
                                        disableSearch
                                        selectedItem={data?.documentationRequired ? "1" : "0"}
                                    />
                                </SelectWrapper>
                            ) : (
                                <DotLegalHeader marginBottom={0} headerStyle={"small"} fontWeight={500}>
                                    {props.masterData?.documentationRequired
                                        ? translateString("documentationIsRequired")
                                        : translateString("documentationIsNotRequired")}
                                </DotLegalHeader>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </DotLegalCollapse>
        </Box>
    );
}

export default MasterDataSection;
