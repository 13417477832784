import React from "react";
import DotLegalFileUploadTable from "../../../common/components/dotLegalFileUploadTable/DotLegalFileUploadTable";
import { useTaskDocumentsHooks } from "./TaskDocuments.hooks";
import { DotLegalButton, DotLegalHeader, DotLegalRadioButton, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import DotLegalFileUploadWrapper from "../../../common/components/dotLegalFileUpload/DotLegalFileUploadWrapper";
import { Box, Collapse, RadioGroup, Typography } from "@mui/material";
import DotLegalWarningTriangle from "../../../common/components/dotLegalWarningTriangle/DotLegalWarningTriangle";

export interface ITaskDocuments {
    taskId: string;
    updateTaskState: () => void;
    updateDocumentState: (hasDocuments: boolean) => void;
    disabled: boolean;
    allDataIsDoneLoading: boolean;
    shouldAddDocument?: boolean;
    show?: boolean;
    isDocumentationRequired?: boolean;
    onFileDialogClose?: () => void;
    onClose: () => void;
    passIsDoneLoading?: (newValue: boolean) => void;
}

function TaskDocuments(props: ITaskDocuments) {
    const { translateString } = useTranslation();
    const {
        documentsData,
        createDocuments,
        removeDocument,
        documentsUploading,
        documentationOption,
        setDocumentationOption,
        taskLink,
        setTaskLink,
        uploadLink,
        updateDocument,
        selectedDocument,
        setSelectedDocument,
    } = useTaskDocumentsHooks(props, (hasDocuments) => props.updateDocumentState(hasDocuments));

    const shouldShowDocuments = props.show && props.allDataIsDoneLoading;

    return (
        <>
            {shouldShowDocuments && (
                <React.Fragment>
                    <DotLegalHeader marginTop={0} marginBottom={0.5} headerStyle="small">
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            {translateString("addDocumentation")}
                            {props.isDocumentationRequired && <DotLegalWarningTriangle warningText={translateString("documentationIsRequired")} />}
                        </Box>
                    </DotLegalHeader>

                    <Box>
                        <RadioGroup
                            row
                            value={documentationOption}
                            onChange={(e) => {
                                setDocumentationOption(Number((e.target as HTMLInputElement).value));
                                setTaskLink(undefined);
                            }}
                        >
                            <DotLegalRadioButton disabled={props.disabled} value={1} labelPlacement="end" label={translateString("uploadDocument")} />
                            <DotLegalRadioButton disabled={props.disabled} value={0} labelPlacement="end" label={translateString("insertLink")} />
                        </RadioGroup>
                        <Collapse in={documentationOption === 1}>
                            {
                                <DotLegalFileUploadWrapper
                                    disabled={props.disabled}
                                    files={[]}
                                    onFilesChanged={async (files) => {
                                        await createDocuments(files);
                                        await props.updateTaskState();
                                        await props.updateDocumentState(true);
                                    }}
                                    shouldOpenFileDialog={props.shouldAddDocument}
                                    isLoading={documentsUploading}
                                    onFileDialogCancel={props.onFileDialogClose}
                                    pasteEnabled={documentationOption === 1}
                                />
                            }
                        </Collapse>

                        {documentsData && documentsData.length === 0 && !props.isDocumentationRequired && (
                            <Box
                                sx={{
                                    display: "Flex",
                                    justifyContent: "end",
                                    "& .MuiButton-root": {
                                        textDecoration: "none !important",
                                        "&:hover": {
                                            backgroundColor: "inherit",
                                        },
                                    },
                                }}
                            >
                                <DotLegalButton onClick={() => props.onClose()} buttonType="linkButton">
                                    {translateString("close")}
                                </DotLegalButton>
                            </Box>
                        )}

                        <Collapse in={documentationOption === 0}>
                            <Box sx={{ height: 70, display: "flex", flexDirection: "column", "& > *": { marginTop: 0 } }}>
                                <DotLegalTextField
                                    label={translateString("insertLink")}
                                    value={taskLink ?? null}
                                    debounce={false}
                                    errorText={""}
                                    onChange={(value) => {
                                        setTaskLink(value);
                                    }}
                                    disabled={props.disabled}
                                    noMargin
                                ></DotLegalTextField>
                                <Typography
                                    onClick={uploadLink}
                                    sx={(theme) => ({
                                        alignSelf: "end",
                                        fontSize: theme.typography.pxToRem(12),
                                        color: theme.palette.primary.main,
                                        "&:hover": {
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                        },
                                    })}
                                >
                                    {translateString("insertLink")}
                                </Typography>
                            </Box>
                        </Collapse>
                    </Box>
                    {documentsData && documentsData.length > 0 && (
                        <Box sx={{ marginBottom: 4 }}>
                            <DotLegalFileUploadTable
                                documents={documentsData}
                                onRemoveDocument={async (documentId) => {
                                    await removeDocument(documentId);
                                }}
                                disabled={props.disabled}
                                onEditDocument={async (document) => {
                                    await updateDocument(document);
                                }}
                                selectedDocument={selectedDocument}
                                setSelectedDocument={setSelectedDocument}
                            />
                        </Box>
                    )}
                </React.Fragment>
            )}
        </>
    );
}

export default TaskDocuments;
