import { createElement } from "react";
import { Trans } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { deleteHttp, get } from "../../common/api/apiShared";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useNavMenuDataMapping } from "../../main/NavMenu/NavMenu.hooks";
import { IDeleteTaskDialog } from "./DeleteTaskDialog";

export function useDeleteTaskHook(props: IDeleteTaskDialog) {
    const snackbar = useDotLegalSnackbar();
    const { refetchMenuNotifications } = useNavMenuDataMapping();

    const url = `/task/${props.taskId}/isLastTaskOnAnnualWheelActivity`;
    const { isLoading, data: isLastTaskOnAnnualWheelActivity } = useQuery(url, () => get<boolean>(url));

    const removeMutation = useMutation(remove);
    const removeTask = () => {
        removeMutation.mutateAsync(undefined, {
            onSuccess: () => {
                props.onDeleteClick();
                snackbar.show(createElement(Trans, { i18nKey: "dataDeleted", values: { name: props.taskName } }));
                refetchMenuNotifications();
            },
        });
    };

    async function remove() {
        return await deleteHttp<string>(`/task/${props.taskId}`);
    }

    return {
        isLoading,
        isLastTaskOnAnnualWheelActivity,
        removeTask,
        inProgress: removeMutation.isLoading,
    };
}
