import React from "react";
import Widget from "../Widget";
import { useTasksWidget } from "./TaskWidget.hooks";
import { Box, Skeleton, styled } from "@mui/material";
import { DotLegalHeader, DotLegalSwitch } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import Task from "../../taskManagement/task/Task";
import NavigateTo from "../navigateTo/NavigateTo";
import WhiteDashboardBox from "../WhiteDashboardBox.tsx";
import DashboardPrettyScrollbar from "../DashboardPrettyScrollbar.tsx";
import { isNullOrWhitespace } from "../../common/stringOperations.ts";
import EditTaskDialog from "../../taskManagement/editTaskDialog/EditTaskDialog.tsx";
import TasksWidgetStatus from "./tasksWidgetStatus/TasksWidgetStatus.tsx";
import TaskWidgetEmptyState from "./taskWidgetEmptyState/TaskWidgetEmptyState.tsx";

export interface TasksWidgetProps {
    onlyUserSpecific: boolean;
    selectedGroupCompany: string | undefined;
    showCurrentMonth?: boolean;
}

export const SwitchNumbersCount = styled("span")({
    marginLeft: 8,
    borderRadius: "50%",
    padding: "2px 6px",
    backgroundColor: "white",
    fontWeight: "bold",
});

function TasksWidget(props: TasksWidgetProps) {
    const { translateString } = useTranslation();
    const {
        isLoading,
        tasks,
        noOverdueTaskStreakInMonths,
        progress,
        isEnabled,
        showCurrentMonth,
        numberOfOverdueTasks,
        numberOfTasksThisMonth,
        setShowCurrentMonth,
        getTaskManagementUrl,
        selectedTask,
        setSelectedTask,
        refetchTasks,
    } = useTasksWidget(props);

    const dotLegalSwitch = (
        <DotLegalSwitch
            onChange={() => setShowCurrentMonth(!showCurrentMonth)}
            checked={showCurrentMonth}
            leftLabel={
                <Box component={"span"}>
                    {translateString("overdue")}
                    <SwitchNumbersCount>{numberOfOverdueTasks}</SwitchNumbersCount>
                </Box>
            }
            rightLabel={
                <Box component={"span"}>
                    {translateString("thisMonth")}
                    <SwitchNumbersCount>{numberOfTasksThisMonth}</SwitchNumbersCount>
                </Box>
            }
        />
    );

    return (
        <Widget disabled={!isEnabled}>
            <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <DotLegalHeader headerStyle={"medium"} marginBottom={0}>
                    {translateString("tasks").toUpperCase()}
                </DotLegalHeader>

                {isLoading ? <Skeleton>{dotLegalSwitch}</Skeleton> : dotLegalSwitch}
                <TasksWidgetStatus numberOfTasks={tasks.length} showCurrentMonth={showCurrentMonth} progress={progress} />
                {isLoading ? (
                    <WhiteDashboardBox marginTop={2} fullHeight isLoading={isLoading}>
                        -
                    </WhiteDashboardBox>
                ) : tasks.length > 0 ? (
                    <Box
                        mt={2}
                        sx={{
                            marginRight: `-15px`,
                            height: "100%",
                            "& .MuiPaper-root": {
                                boxShadow: "none",
                            },
                        }}
                    >
                        <DashboardPrettyScrollbar showGutter>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pr: 0.5 }}>
                                {tasks.map((task) => {
                                    return (
                                        <Task
                                            activityName={task.name}
                                            taskName={task.processingActivityName ?? ""}
                                            description={task.description}
                                            businessAreas={task.businessAreas}
                                            responsibles={task.responsibles}
                                            month={new Date(task.deadline).getMonth() + 1}
                                            year={new Date(task.deadline).getFullYear()}
                                            subTaskCompleted={task.completedSubTasks}
                                            totalSubTasks={task.totalSubTasks}
                                            taskStatus={task.laneState}
                                            isOverdue={task.isOverdue}
                                            onClick={() => setSelectedTask(task.id)}
                                            priority={task.priority}
                                            processingActivtiyId={task.processingActivityId}
                                            areas={task.areas}
                                            customId={task.customId}
                                            isReadyForApproval={task.isReadyForApproval}
                                            key={task.id}
                                            isAdhocTask={task.isAdHocTask}
                                        />
                                    );
                                })}
                            </Box>
                        </DashboardPrettyScrollbar>
                    </Box>
                ) : (
                    <TaskWidgetEmptyState showCurrentMonth={showCurrentMonth} noOverdueTaskStreakInMonths={noOverdueTaskStreakInMonths} />
                )}
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <NavigateTo
                        color="purple"
                        text={showCurrentMonth ? translateString("goToCurrentTasks") : translateString("goToOverdueTasks")}
                        to={getTaskManagementUrl(showCurrentMonth ? `?months=${new Date().getMonth() + 1}` : "?months=&overdueTasks=true")}
                    />
                </Box>
            </Box>

            {!isNullOrWhitespace(selectedTask ?? undefined) && (
                <EditTaskDialog
                    setShowDeleteDialog={() => {}}
                    refetchTasks={refetchTasks}
                    taskId={selectedTask!}
                    setSelectedTask={setSelectedTask}
                    setYearByTaskYear={() => {}}
                />
            )}
        </Widget>
    );
}

export default TasksWidget;
