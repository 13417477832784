import React, { FunctionComponent, useEffect } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "../../../../localization/useTranslation";
import { DotLegalLink, DotLegalSwitch, useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { Trans } from "react-i18next";

export interface DotLegalAllOrMySwitchBoxProps {
    hidden?: boolean;
    onChange: (enabled: boolean) => void;
    enabled: boolean;
}

const DotLegalAllOrMySwitchBox: FunctionComponent<DotLegalAllOrMySwitchBoxProps> = ({ onChange, enabled, hidden }) => {
    const { translateString } = useTranslation();
    const snackBar = useDotLegalSnackbar();

    const info = (
        <span>
            <Trans
                i18nKey={"allMineInfo"}
                components={{
                    box: (
                        <DotLegalLink underline onClick={() => onChange(false)}>
                            {undefined}
                        </DotLegalLink>
                    ),
                }}
            />
        </span>
    );
    useEffect(() => {
        if (enabled && !hidden) {
            snackBar.show(info, "warning");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box sx={(theme) => ({ visibility: hidden ? "hidden" : "visible" })}>
            <DotLegalSwitch onChange={onChange} checked={enabled} leftLabel={translateString("all")} rightLabel={translateString("my")} />
        </Box>
    );
};

export default DotLegalAllOrMySwitchBox;
