import { SelectableItem } from "../../processingActivity/ProcessingActivity.types";

export interface DeclarationsViewModel {
    id: string;
    name: string;
    complianceAreas: Array<SelectableItem>;
    auditFrequency: AuditFrequency;
    auditDate: Date;
    approval: DeclarationApprovalOption;
    groupEntities: Array<SelectableItem>;
    auditProgress: number;
    noOfGroupEntitiesWithoutAccess: number;
    isLocked: boolean;
}

export enum AuditFrequency {
    Quarterly = 1,
    SemiAnnually = 2,
    Annual = 3,
    EveryTwoYears = 4,
    EveryThreeYears = 5,
}

export enum DeclarationApprovalOption {
    InternalApprovalSufficient = 1,
    ExternalApprovalRequired = 2,
}

export interface DeclarationsTableModel extends DeclarationsViewModel {
    complianceAreasString: string;
    auditFrequencyString: string;
    approvalString: string;
    groupEntitiesString: string;
    auditDateString: string;
}
