import { RelatedTasksTabProps } from "./RelatedTasksTab.tsx";
import { useMutation, useQuery } from "react-query";
import { deleteHttp, get } from "../../common/api/apiShared.ts";
import { RelatedTasksTableModel, RelatedTaskViewModel } from "./RelatedTasksTab.types.ts";
import { useEffect, useState } from "react";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation.ts";
import { AnnualWheelMonthEnum } from "../../annualWheel/AnnualWheel.types.ts";
import { getRawStateString } from "../editTaskDialog/masterDataSection/MasterDataSection.hooks.ts";
import { useAddTaskMenuItem } from "../../common/components/addTaskMenuItem/AddTaskMenuItemContext.hooks.ts";
import { isNullOrWhitespace } from "../../common/stringOperations.ts";
import { EntityType } from "../../common/Common.types.ts";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider.tsx";

export function useRelatedTasksTab(props: RelatedTasksTabProps) {
    const snackbar = useDotLegalSnackbar();
    const { translateString, translateEntityType } = useTranslation();
    const context = useAddTaskMenuItem();

    const [selectedRelationToRemove, setSelectedRelationToRemove] = useState<RelatedTasksTableModel | undefined>(undefined);
    const [selectedTaskToDelete, setSelectedTaskToDelete] = useState<RelatedTasksTableModel | undefined>(undefined);
    const [selectedTask, setSelectedTask] = useState<string | undefined>(undefined);
    const [showAddExistingTasksDialog, setShowAddExistingTasksDialog] = useState(false);

    const { isLoading, data, refetch } = useGetRelatedTaskData(props.forEntityId, props.customApiUrl);

    // This useEffect fixes -> Warning: Cannot update a component (`AddTaskMenuItemProvider`) while rendering a different component (`RelatedTasksTab`).
    // To locate the bad setState() call inside `RelatedTasksTab`
    useEffect(() => {
        // Register the refetch function after rendering
        context.registerRefetchFunc(refetch);

        return () => {
            context.unregisterRefetchFunc();
        };
        // eslint-disable-next-line
    }, [context]);

    const removeMutation = useMutation(deleteApi);
    const removeRelation = async (relationId: string) => {
        await removeMutation.mutateAsync(relationId, {
            onSuccess: () => {
                refetch();
                snackbar.show(translateString("relationRemoved"), "success");
                setSelectedRelationToRemove(undefined);
            },
        });
    };

    return {
        isLoading,
        refetchTasks: refetch,
        data: convertRowsToTableModel(data ?? []),
        selectedRelationToRemove,
        setSelectedRelationToRemove,
        removeRelation,
        selectedTask,
        setSelectedTask,
        selectedTaskToDelete,
        setSelectedTaskToDelete,
        showAddExistingTasksDialog,
        setShowAddExistingTasksDialog,
    };

    async function deleteApi(relationId: string) {
        return await deleteHttp(`/relations/${relationId}`);
    }

    function convertRowsToTableModel(rows: Array<RelatedTaskViewModel>) {
        return rows.map((x) => {
            return {
                ...x,
                deadlineString: `${translateString(AnnualWheelMonthEnum[new Date(x.deadline).getMonth() + 1].toLocaleLowerCase())} ${new Date(x.deadline).getFullYear()}`,
                stateString: getRawStateString(x.state, translateString),
                entityTypeString: x.additionalEntityInfo ? translateEntityType(EntityType[x.additionalEntityInfo.entityType].toString()) : undefined,
                entityName: x.additionalEntityInfo ? x.additionalEntityInfo.additionalEntityName : undefined,
            };
        });
    }
}

export function useGetRelatedTaskData(forEntityId: string, customApiUrl?: string) {
    const { permissions } = useUserContext();
    const { translateString } = useTranslation();
    const context = useAddTaskMenuItem();

    const url = isNullOrWhitespace(customApiUrl ?? null) ? `/relations/${forEntityId}/tasks` : customApiUrl!;
    const { isLoading, data, refetch } = useQuery(url, () => get<Array<RelatedTaskViewModel>>(url), { enabled: permissions.taskPermissions.read });

    // This useEffect fixes -> Warning: Cannot update a component (`AddTaskMenuItemProvider`) while rendering a different component (`RelatedTasksTab`).
    // To locate the bad setState() call inside `RelatedTasksTab`
    useEffect(() => {
        // Register the refetch function after rendering
        context.registerRefetchFunc(refetch);

        return () => {
            context.unregisterRefetchFunc();
        };
        // eslint-disable-next-line
    }, [context]);

    let numberOfTasks = isLoading ? undefined : data?.length;
    const getTaskTabHeader = () => {
        return `${translateString("tasks")} ${numberOfTasks !== undefined && numberOfTasks > 0 ? `(${numberOfTasks})` : ""}`;
    };

    return {
        isLoading,
        data,
        refetch,
        count: numberOfTasks,
        getTaskTabHeader,
    };
}
