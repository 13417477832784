import {
    DotLegalButton,
    DotLegalCheckbox,
    DotLegalEmptyState,
    DotLegalMultiSelect,
    DotLegalPaper,
    DotLegalSelect,
    DotLegalTableWithControls,
    DotLegalTooltip,
    ITableHeader,
    useDotLegalSnackbar,
} from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import {
    AuditGroupLegalEntityContact,
    AuditGroupLegalEntityRowApiModel,
    AuditGroupLegalEntityRowViewModel,
    LegalEntityAuditInformationViewModel,
} from "./AuditGroupLegalEntitySelector.types";
import { Box, Grid, GridSize, Skeleton, TableCell, TableRow, Typography } from "@mui/material";
import useAuditGroupLegalEntitySelectorHooks from "./AuditGroupLegalEntitySelector.hooks";
import DotLegalSelectWithButton from "../../../common/components/dotLegalSelectWithButton/DotLegalSelectWithButton";
import { DotLegalSelectOption } from "../../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { isNullOrWhitespace, setFirstLetterToLowerCase } from "../../../common/stringOperations";
import { LegalEntityContactType } from "../../../legalEntity/legalEntityContactTab/LegalEntityContactTab.types";
import CheckBox from "@mui/material/Checkbox";
import React from "react";
import LegalEntityContactDialog from "../../../legalEntity/legalEntityContactTab/legalEntityContactDialog/LegalEntityContactDialog";
import { LegalEntityAuditGroupDraft } from "../LegalEntityAuditGroupOverview.types";
import DotLegalLink from "../../../common/components/dotLegalLink/DotLegalLink";
import { useUrlProvider } from "../../../useUrlProvider";
import { LegalEntityAuditGroupSendModel } from "../sendAuditsDialog/SendAuditsDialog.types";
import ClassificationRisk from "../../../common/components/questionnaireComponents/classiificationRisk/ClassificationRisk";
import { LegalEntityMasterType, LegalEntityPageEnum } from "../../../legalEntity/legalEntities/LegalEntities.types";
import SendAuditsDialog from "../sendAuditsDialog/SendAuditsDialog";
import NoData from "../../../legalEntity/legalEntityTable/noData.svg?react";
import { LegalEntityAuditFrequency, LegalEntityAuditType } from "../../../legalEntity/legalEntityManagement/LegalEntityManagement.types";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";
import TrialUsageWarning from "../../../common/components/TrialUsageWarning";
import TrialExpiredRowWrapper from "../../../common/components/TrialExpiredRowWrapper";
import PlanDialogButtonWrapper from "../../../plan/planDialogButtonWrapper/planDialogButtonWrapper";
import { WhiteLockIcon } from "../../../common/icons";

export interface IAuditGroupLegalEntitySelector {
    selectedEntities: Array<LegalEntityAuditGroupDraft>;
    onSelectedEntitiesUpdated: (selectedEntities: Array<LegalEntityAuditGroupDraft>) => void;
    selectedEntititesIsLoading: boolean;
    rows: Array<AuditGroupLegalEntityRowApiModel>;
    onContactCreated: () => void;
    auditGroupId?: string;
    auditGroupName: string;
    isDeadlineOverdue: boolean;
}

function AuditGroupLegalEntitySelector(props: IAuditGroupLegalEntitySelector) {
    const { translateString, translateCountry, translateDate } = useTranslation();
    const { getLegalEntityUrl } = useUrlProvider();
    const { vendorManagementFeatures } = usePlanContext();

    const {
        showAddContactDialog,
        setShowAddContactDialog,
        systemsIsLoading,
        onCheckBoxClick,
        setHeaderCheckBoxState,
        onSelectAllEntities,
        onContactChange,
        onSystemChange,
        setShowSendAuditsDialog,
        showSendAuditsDialog,
        sendClicked,
        setSendClicked,
        auditIsLoading,
        filters,
        searchedCountry,
        setSearchedCountry,
        searchedType,
        setSearchedType,
        searchedAudit,
        setSearchedAudit,
        searchedLatestAuditSubmission,
        setSearchedLatestAuditSubmission,
        searchedSupervisionType,
        setSearchedSupervisionType,
        searchedSupervisionFrequency,
        setSearchedSupervisionFrequency,
        searchedRiskLevel,
        setSearchedRisklevel,
        searchedClassification,
        onUpdateClassificationFilter,
        onUpdateLatestAuditFilter,
        rows,
    } = useAuditGroupLegalEntitySelectorHooks(props);

    const snackbar = useDotLegalSnackbar();

    function getHeaders() {
        let headers = Array<ITableHeader<AuditGroupLegalEntityRowViewModel>>();
        headers.push({ property: "name", text: translateString("legalEntity"), align: "left", showOnMobile: true });
        headers.push({ property: "legalEntityTypesString", text: translateString("type"), align: "left", showOnMobile: true });
        headers.push({ property: "country", text: translateString("country"), align: "left", showOnMobile: true });
        headers.push({ property: "contactName", text: translateString("contact"), align: "left", showOnMobile: true });
        headers.push({ property: "systemNames", text: translateString("assets"), align: "left", showOnMobile: true });
        headers.push({ property: "classificationName", text: translateString("classifications"), align: "left", showOnMobile: true });
        headers.push({ property: "classificationRiskName", text: "", hideHeader: true });
        headers.push({ property: "auditName", text: translateString("latestAuditHeader"), align: "left", showOnMobile: true });
        headers.push({
            property: "auditSubmission",
            text: translateString("latestAuditSubmission"),
            align: "left",
            showOnMobile: true,
            sortOverride: (a: AuditGroupLegalEntityRowViewModel, b: AuditGroupLegalEntityRowViewModel, order) => sortLatesAuditSubmission(a, b, order),
        });
        headers.push({ property: "auditSubmissionName", text: "", hideHeader: true });
        headers.push({ property: "auditType", text: translateString("legalEntityAuditType"), align: "left", showOnMobile: true });
        headers.push({ property: "auditFrequency", text: translateString("legalEntityFrequency"), align: "left", showOnMobile: true });
        return headers;
    }

    function sortLatesAuditSubmission(a: AuditGroupLegalEntityRowViewModel, b: AuditGroupLegalEntityRowViewModel, order: "asc" | "desc") {
        if (order === "asc") {
            if (a.auditSubmission === null && b.auditSubmission === null) {
                if (a.auditSubmissionName > b.auditSubmissionName) return 1;
                else if (a.auditSubmissionName < b.auditSubmissionName) return -1;
                else return 0;
            } else if (a.auditSubmission !== null && b.auditSubmission !== null) {
                if (a.auditSubmission > b.auditSubmission) return 1;
                else if (a.auditSubmission < b.auditSubmission) return -1;
                return 0;
            } else if (a.auditSubmission !== null && b.auditSubmission === null) {
                return -1;
            }

            return 1;
        } else {
            if (a.auditSubmission === null && b.auditSubmission === null) {
                if (a.auditSubmissionName > b.auditSubmissionName) return -1;
                else if (a.auditSubmissionName < b.auditSubmissionName) return 1;
                else return 0;
            } else if (a.auditSubmission !== null && b.auditSubmission !== null) {
                if (a.auditSubmission > b.auditSubmission) return -1;
                else if (a.auditSubmission < b.auditSubmission) return 1;
                return 0;
            } else if (a.auditSubmission !== null && b.auditSubmission === null) {
                return -1;
            }

            return 1;
        }
    }

    const tableGridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize; lg: GridSize; xl: boolean } = {
        item: true,
        xs: 12,
        sm: 6,
        md: 4,
        lg: 2,
        xl: true,
    };

    const getRiskLevelFilter = () => {
        let isDisabled = isNullOrWhitespace(searchedClassification ?? undefined);

        const riskFilter = (
            <DotLegalMultiSelect
                onChange={setSearchedRisklevel}
                chosenOptions={searchedRiskLevel}
                label={translateString("classificationRiskLevel")}
                placeholder={translateString("classificationRiskLevel")}
                isLoading={props.selectedEntititesIsLoading}
                options={filters.riskLevelOptions}
                noMargin
                noOptionsLabel={translateString("noOptions")}
                disabled={isDisabled}
            />
        );

        if (isDisabled) {
            return (
                <DotLegalTooltip text={translateString("riskLevelFilterDisabled")}>
                    <span>{riskFilter}</span>
                </DotLegalTooltip>
            );
        }

        return riskFilter;
    };

    const getLastestAuditSubmissionFilter = () => {
        let isDisabled = isNullOrWhitespace(searchedAudit ?? undefined);

        const lastestAuditSubmissionFilter = (
            <DotLegalSelect
                onChange={setSearchedLatestAuditSubmission}
                selectedItem={searchedLatestAuditSubmission}
                label={translateString("latestAuditSubmission")}
                placeholder={translateString("latestAuditSubmission")}
                isLoading={props.selectedEntititesIsLoading}
                options={filters.latestAuditSubmissionFilterOptions}
                noMargin
                noOptionsLabel={translateString("noOptions")}
                disabled={isDisabled}
            />
        );

        if (isDisabled) {
            return (
                <DotLegalTooltip text={translateString("latestSubmittedAuditFilterDisabled")}>
                    <span>{lastestAuditSubmissionFilter}</span>
                </DotLegalTooltip>
            );
        }

        return lastestAuditSubmissionFilter;
    };

    const getSearchFields = () => {
        return (
            <React.Fragment>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedType}
                        chosenOptions={searchedType}
                        label={translateString("type")}
                        placeholder={translateString("type")}
                        isLoading={props.selectedEntititesIsLoading}
                        options={filters.typeSelectOptions}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedCountry}
                        chosenOptions={searchedCountry}
                        label={translateString("countriesPlural")}
                        placeholder={translateString("countriesPlural")}
                        isLoading={props.selectedEntititesIsLoading}
                        options={filters.countrySelectOptions}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>

                <Grid {...tableGridProps}>
                    <DotLegalSelect
                        onChange={(value) => onUpdateClassificationFilter(value)}
                        selectedItem={!isNullOrWhitespace(searchedClassification ?? undefined) ? searchedClassification : " "}
                        label={translateString("auditGroupClassification")}
                        placeholder={translateString("auditGroupClassification")}
                        isLoading={props.selectedEntititesIsLoading}
                        options={filters.classificationOptions}
                        noMargin
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>

                <Grid {...tableGridProps}>{getRiskLevelFilter()}</Grid>

                <Grid {...tableGridProps}>
                    <DotLegalSelect
                        onChange={(value) => onUpdateLatestAuditFilter(value)}
                        selectedItem={!isNullOrWhitespace(searchedAudit ?? undefined) ? searchedAudit : " "}
                        label={translateString("legalEntitiesLatestAuditHeader")}
                        placeholder={translateString("legalEntitiesLatestAuditHeader")}
                        isLoading={props.selectedEntititesIsLoading}
                        options={filters.auditTemplateOptions}
                        noMargin
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>

                <Grid {...tableGridProps}>{getLastestAuditSubmissionFilter()}</Grid>

                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedSupervisionType}
                        chosenOptions={searchedSupervisionType}
                        label={translateString("legalEntityAuditType")}
                        placeholder={translateString("legalEntityAuditType")}
                        isLoading={props.selectedEntititesIsLoading}
                        options={filters.superVisionTypeOptions}
                        noMargin
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>

                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedSupervisionFrequency}
                        chosenOptions={searchedSupervisionFrequency}
                        label={translateString("legalEntityFrequency")}
                        placeholder={translateString("legalEntityFrequency")}
                        isLoading={props.selectedEntititesIsLoading}
                        options={filters.superVisionFrequencyOptions}
                        noMargin
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
            </React.Fragment>
        );
    };

    const convertToSelectableItem = (contact: AuditGroupLegalEntityContact): DotLegalSelectOption => {
        return {
            id: contact.id,
            name: `${contact.name} ${contact.contactTypes.length > 0 ? `(${contact.contactTypes.map((x) => translateString(setFirstLetterToLowerCase(LegalEntityContactType[x].toString()))).join(", ")})` : ""}`,
            hoverText: contact.email,
        };
    };

    const formatLegalEntityTypes = (legalEntityTypes: Array<LegalEntityMasterType>) => {
        return legalEntityTypes
            .map((x) => {
                return translateString(setFirstLetterToLowerCase(LegalEntityMasterType[x].toString()));
            })
            .join(", ");
    };

    function renderOptions(option: DotLegalSelectOption) {
        return (
            <DotLegalTooltip text={option.hoverText}>
                <Box>{option.name}</Box>
            </DotLegalTooltip>
        );
    }

    const getSelectedEntity = (legalEntityId: string) => {
        return props.selectedEntities.find((x) => x.legalEntityId === legalEntityId);
    };

    const getSelectedContactId = (row: AuditGroupLegalEntityRowApiModel) => {
        let selectedEntity = getSelectedEntity(row.id);

        if (selectedEntity) {
            return selectedEntity.contactId;
        }

        return row.contacts.find((x) => x.isPrimaryContact)?.id;
    };

    const getName = (row: AuditGroupLegalEntityRowApiModel) => {
        return (
            <Box key={row.id} component={"span"} sx={{ fontWeight: "bold" }}>
                <DotLegalLink linkColor={"primary"} to={getLegalEntityUrl(row.id)}>
                    {row.name}
                </DotLegalLink>
            </Box>
        );
    };

    function getSubmitted(audit?: LegalEntityAuditInformationViewModel) {
        if (audit) {
            if (audit.isClosed) {
                return translateString("excluded");
            }

            if (audit.submissionDate) {
                return translateDate(audit.submissionDate);
            }

            return translateString("notSubmitted");
        }

        return "";
    }

    function getLoadingState() {
        return <Skeleton width={"100%"} height={18} />;
    }

    const mapSelectedLegalEntities = (): Array<LegalEntityAuditGroupSendModel> => {
        let data: Array<LegalEntityAuditGroupSendModel> = [];

        props.selectedEntities.forEach((s) => {
            const entity = props.rows.find((x) => x.id === s.legalEntityId)!;

            const contact = entity.contacts.find((x) => x.id === s.contactId)!;
            const systems = entity.assets.filter((x) => s.systemIds.includes(x.id));
            data.push({
                legalEntityId: entity.id,
                legalEntityName: entity.name,
                legalEntityTypes: entity.legalEntityTypes,
                countryCode: entity.countryCode,
                contactName: `${contact.name} ${contact.contactTypes.length > 0 ? ` (${contact.contactTypes.map((x) => translateString(setFirstLetterToLowerCase(LegalEntityContactType[x].toString()))).join(", ")})` : ""} `,
                contactEmail: contact.email,
                systemNames: systems.map((x) => x.name),
                systemIds: systems.map((x) => x.id),
                contactId: contact.id,
            });
        });

        return data;
    };

    return (
        <React.Fragment>
            <DotLegalPaper>
                <Box>
                    {props.selectedEntititesIsLoading || props.rows.length > 0 ? (
                        <>
                            {!vendorManagementFeatures.auditGroups && (
                                <TrialUsageWarning
                                    headerKey={translateString("auditGroupsLockedWarningHeader")}
                                    textKey={translateString("auditGroupsLockedWarning")}
                                    marginBottom={2}
                                />
                            )}

                            <DotLegalTableWithControls
                                extraControls={getSearchFields()}
                                headers={getHeaders()}
                                getUserSpecificPageLength={() => 30}
                                hideRowsPerPage
                                defaultOrderBy={"name"}
                                defaultOrder={"asc"}
                                isLoading={props.selectedEntititesIsLoading}
                                onSelectAllChanged={(selected) => onSelectAllEntities(selected)}
                                labelRowsPerPage={translateString("showEntites")}
                                labelSearch={translateString("search")}
                                noOptionsLabel={translateString("noOptions")}
                                paginationLabelOf={translateString("labelOf")}
                                data={mapToAuditGroupLegalEntityRowViewModel(rows ?? [])}
                                clickableRows={false}
                                headerCheckBoxState={setHeaderCheckBoxState()}
                                emptyText={translateString("noData")}
                                disableCheckAll={!vendorManagementFeatures.auditGroups}
                                renderRow={(row) => {
                                    const selectedEntity = props.selectedEntities.find((x) => x.legalEntityId === row.id);
                                    const isSelected = selectedEntity !== undefined;
                                    const selectedContactId = getSelectedContactId(row);
                                    return (
                                        <TrialExpiredRowWrapper
                                            key={row.id}
                                            tooltip={translateString("auditGroupLocked")}
                                            onNonExpiredClick={() => {}}
                                            expired={!vendorManagementFeatures.auditGroups}
                                            planUpgradeText={translateString("auditGroupLocked")}
                                        >
                                            <TableCell>
                                                <DotLegalCheckbox
                                                    disabled={!vendorManagementFeatures.auditGroups}
                                                    onChange={() => onCheckBoxClick(row)}
                                                    checked={isSelected}
                                                />
                                            </TableCell>
                                            <TableCell>{getName(row)}</TableCell>
                                            <TableCell>{formatLegalEntityTypes(row.legalEntityTypes)}</TableCell>
                                            <TableCell>{translateCountry(row.countryCode)}</TableCell>
                                            <TableCell sx={{ minWidth: 250 }}>
                                                <Box
                                                    sx={(theme) => ({
                                                        visibility: isSelected ? "visible" : "hidden",
                                                        "& .MuiAutocomplete-root .MuiInputBase-input": {
                                                            marginBottom: -1,
                                                            fontSize: theme.typography.pxToRem(12),
                                                        },
                                                    })}
                                                >
                                                    <DotLegalSelectWithButton
                                                        variant="standard"
                                                        onButtonClick={() => setShowAddContactDialog(row.id)}
                                                        buttonLabel={translateString("createNew")}
                                                        noOptionsLabel={translateString("noOptions")}
                                                        options={row.contacts.map((x) => convertToSelectableItem(x))}
                                                        onChange={(item) => onContactChange(row.id, item!)}
                                                        label={""}
                                                        placeholder={translateString("addContact")}
                                                        hasPermission
                                                        selectedItem={selectedContactId}
                                                        noMargin
                                                        renderOptions={renderOptions}
                                                        width="250"
                                                        errorText={
                                                            sendClicked && selectedEntity?.IsModelInvalid() ? (
                                                                <Typography sx={{ fontSize: 10 }}>{translateString("contactIsMandatory")}</Typography>
                                                            ) : (
                                                                ""
                                                            )
                                                        }
                                                        disableSearch
                                                        selectedValueText={row.contacts.find((x) => x.id === selectedContactId)?.email}
                                                        disabled={!vendorManagementFeatures.auditGroups}
                                                    />
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ minWidth: 280 }}>
                                                <Box
                                                    sx={(theme) => ({
                                                        visibility: isSelected ? "visible" : "hidden",
                                                        "& .MuiAutocomplete-root .MuiInputBase-input": {
                                                            marginBottom: -1,
                                                            fontSize: theme.typography.pxToRem(12),
                                                        },
                                                    })}
                                                >
                                                    <DotLegalMultiSelect
                                                        variant="standard"
                                                        options={row.assets}
                                                        chosenOptions={getSelectedEntity(row.id)?.systemIds ?? []}
                                                        onChange={(values) => onSystemChange(row.id, values)}
                                                        label={""}
                                                        placeholder={translateString("chooseOptionalAsset")}
                                                        noOptionsLabel={translateString("noOptions")}
                                                        isLoading={systemsIsLoading}
                                                        width="280"
                                                        noMargin
                                                        disabled={!vendorManagementFeatures.auditGroups}
                                                    />
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ minWidth: 350 }}>
                                                {auditIsLoading
                                                    ? getLoadingState()
                                                    : row.classifications &&
                                                      row.classifications.length > 0 && (
                                                          <ClassificationRisk
                                                              legalEntityId={row.id}
                                                              legalEntityPage={LegalEntityPageEnum.Customer}
                                                              classifications={row.classifications}
                                                          />
                                                      )}
                                            </TableCell>
                                            <TableCell width={200}>{auditIsLoading ? getLoadingState() : row.auditName}</TableCell>
                                            <TableCell width={220}>{auditIsLoading ? getLoadingState() : row.auditSubmissionName}</TableCell>
                                            <TableCell>
                                                {row.auditType
                                                    ? translateString(setFirstLetterToLowerCase(LegalEntityAuditType[row.auditType].toString()))
                                                    : undefined}
                                            </TableCell>
                                            <TableCell>
                                                {row.auditFrequency
                                                    ? translateString(setFirstLetterToLowerCase(LegalEntityAuditFrequency[row.auditFrequency].toString()))
                                                    : undefined}
                                            </TableCell>
                                        </TrialExpiredRowWrapper>
                                    );
                                }}
                            />
                        </>
                    ) : (
                        <DotLegalEmptyState icon={<NoData />} text={translateString("noLegalEntitiesToAddToAuditGroup")} />
                    )}
                </Box>
                {props.rows.length > 0 && (
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <PlanDialogButtonWrapper text={translateString("auditGroupLocked")} enabled={!vendorManagementFeatures.auditGroups}>
                            <DotLegalButton
                                disabled={props.selectedEntities.length === 0 || props.isDeadlineOverdue || !vendorManagementFeatures.auditGroups}
                                onClick={() => {
                                    setSendClicked(true);

                                    if (props.selectedEntities.some((x) => x.IsModelInvalid())) {
                                        snackbar.show(translateString("contactRequiredForLegalEntityOnAuditGroup"), "error");
                                        return;
                                    }

                                    setShowSendAuditsDialog(true);
                                }}
                                buttonType={"primary"}
                                toolTip={
                                    !vendorManagementFeatures.auditGroups
                                        ? translateString("auditGroupLocked")
                                        : props.isDeadlineOverdue
                                          ? translateString("auditGroupDeadlineIsOverdue")
                                          : undefined
                                }
                                endIcon={!vendorManagementFeatures.auditGroups ? WhiteLockIcon : undefined}
                            >
                                {translateString("sendAudits")} {props.selectedEntities.length > 0 && "(" + props.selectedEntities.length + ")"}
                            </DotLegalButton>
                        </PlanDialogButtonWrapper>
                    </Box>
                )}
            </DotLegalPaper>
            {!isNullOrWhitespace(showAddContactDialog) && (
                <LegalEntityContactDialog
                    onCloseDialog={() => setShowAddContactDialog(undefined)}
                    isEdit={false}
                    forLegalEntityId={showAddContactDialog}
                    onSave={(contactId) => {
                        onContactChange(showAddContactDialog!, contactId);
                        setShowAddContactDialog(undefined);
                    }}
                />
            )}

            {showSendAuditsDialog && (
                <SendAuditsDialog
                    selectedEntities={mapSelectedLegalEntities()}
                    onDialogClose={() => setShowSendAuditsDialog(false)}
                    auditGroupId={props.auditGroupId}
                    auditGroupName={props.auditGroupName}
                />
            )}
        </React.Fragment>
    );

    function mapToAuditGroupLegalEntityRowViewModel(rows: Array<AuditGroupLegalEntityRowApiModel>): Array<AuditGroupLegalEntityRowViewModel> {
        return rows.map((row) => {
            let selectedEntity = getSelectedEntity(row.id);
            let searchAudit = row.audits?.find((x) => x.questionnaireId === searchedAudit);

            const hasClassiciations = row.classifications && row.classifications.length > 0;
            return {
                ...row,
                legalEntityTypesString: formatLegalEntityTypes(row.legalEntityTypes),
                country: translateCountry(row.countryCode),
                contactName: getSelectedContactName(row) ?? "",
                systemNames: getSelectedSystemNames(row, selectedEntity),
                classificationName: hasClassiciations ? row.classifications[0].name : "",
                classificationRiskName: hasClassiciations ? row.classifications[0].riskName : "",
                auditName: searchAudit ? searchAudit.name : (row.latestAudit?.name ?? ""),
                auditSubmission: getAuditSubmissionName(searchAudit, row.latestAudit),
                auditSubmissionName: getSubmitted(searchAudit ?? row.latestAudit),
                auditTypeName: row.auditType ? translateString(setFirstLetterToLowerCase(LegalEntityAuditType[row.auditType].toString())) : "",
                auditFrequencyName: row.auditFrequency
                    ? translateString(setFirstLetterToLowerCase(LegalEntityAuditFrequency[row.auditFrequency].toString()))
                    : "",
            };
        });
    }

    function getAuditSubmissionName(
        searchAudit: LegalEntityAuditInformationViewModel | undefined,
        latestAudit: LegalEntityAuditInformationViewModel | undefined
    ) {
        if (searchAudit) {
            return searchAudit?.submissionDate ? new Date(searchAudit?.submissionDate!) : null;
        }

        return latestAudit?.submissionDate ? new Date(latestAudit?.submissionDate!) : null;
    }

    function getSelectedContactName(row: AuditGroupLegalEntityRowApiModel) {
        let selectedEntity = getSelectedEntity(row.id);

        if (selectedEntity) {
            return row.contacts.find((x) => x.id === selectedEntity.contactId || x.isPrimaryContact)?.name;
        }

        return "";
    }

    function getSelectedSystemNames(row: AuditGroupLegalEntityRowApiModel, selectedEntity: LegalEntityAuditGroupDraft | undefined) {
        return row.assets
            .filter((x) => selectedEntity?.systemIds.includes(x.id))
            .map((x) => x.name)
            .join(", ");
    }
}

export default AuditGroupLegalEntitySelector;
