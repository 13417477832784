import { useTranslation } from "../../localization/useTranslation.ts";
import { DotLegalDeleteDialog } from "@dotlegal/dotlegal-ui-components";
import { Trans } from "react-i18next";
import React from "react";
import useDeleteDeclarationCategory from "./DeleteDeclarationCategory.hooks.ts";

export interface DeleteDeclarationCategoryDialogProps {
    id: string;
    name: string;
    onCloseDialog: () => void;
    declarationId: string;
}

function DeleteDeclarationCategoryDialog(props: DeleteDeclarationCategoryDialogProps) {
    const { translateString } = useTranslation();
    const { onDelete, isDeleting } = useDeleteDeclarationCategory(props);

    return (
        <DotLegalDeleteDialog
            open
            headerText={translateString("deleteDeclarationCategory")}
            alertBodyText={<Trans i18nKey="deleteDeclarationCategoryWarning" values={{ name: props.name }} />}
            warningTranslation={""}
            deleteBtnText={translateString("delete")}
            cancelBtnText={translateString("cancel")}
            onDialogClose={props.onCloseDialog}
            onOkClick={onDelete}
            inProgress={isDeleting}
        />
    );
}

export default DeleteDeclarationCategoryDialog;
