import { lighten, Theme, useTheme } from "@mui/material";

import { TaskLaneStateEnum, TaskRawStateEnum, TaskStateEnum } from "../../annualWheel/AnnualWheel.types";
import { createSxStyles } from "../../common/createSxStyles";

export const useTaskStyles = (status: TaskLaneStateEnum, isOverdue: boolean) => {
    const theme = useTheme();
    return createSxStyles({
        root: {
            boxShadow: "0px 3px 6px #00000029",
        },
        headerContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        statusAndHeader: {
            display: "flex",
            alignItems: "center",
            gap: 1,
        },
        businessAreasContainer: {
            marginBottom: theme.spacing(1),
        },
        gridItemsContainer: {
            display: "flex",
            alignItems: "center",
        },
        taskContainter: {
            display: "flex",
            justifyContent: "center",
        },
        greenArrowContainer: {
            display: "flex",
            justifyContent: "flex-end",
        },
        greenArrow: {
            "&:hover": {
                cursor: "pointer",
                "& svg path": {
                    stroke: theme.palette.secondary.dark,
                },
            },
            "& svg": {
                width: 16,
                height: 16,
            },
        },
        gridContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        badge: {
            marginLeft: theme.spacing(0.5),
            marginRight: theme.spacing(1.5),
            "& .MuiBadge-badge": {
                backgroundColor: getTaskColor(status, theme, isOverdue),
            },
        },
        text: {
            marginLeft: 1,
            color: theme.palette.primary.dark,
            fontSize: theme.typography.pxToRem(14),
        },
        priorityIcon: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        description: {
            textOverflow: "ellipsis",
            overflow: "hidden",
            display: "-webkit-box",
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.primary.dark,
            fontWeight: theme.typography.fontWeightMedium,
            whiteSpace: "pre-line",
            WebkitLineClamp: "1",
            WebkitBoxOrient: "vertical",
        },
    });
};

export function getTaskColor(state: TaskLaneStateEnum | TaskRawStateEnum | TaskStateEnum, theme: Theme, isOverdue: boolean = false) {
    const errorColor = lighten(theme.palette.error.light, 0.5);
    switch (state) {
        case TaskLaneStateEnum.Ready | TaskStateEnum.Ready | TaskRawStateEnum.Ready:
            return isOverdue ? errorColor : theme.palette.primary.main;
        case TaskLaneStateEnum.InProgress | TaskStateEnum.InProgress | TaskRawStateEnum.InProgress:
            return isOverdue ? errorColor : "#BDD3EC";
        case TaskRawStateEnum.ReadyForApproval:
            return isOverdue ? errorColor : "#ffea00";
        case TaskLaneStateEnum.Completed | TaskStateEnum.Completed | TaskRawStateEnum.Completed:
            return theme.palette.secondary.main;
        case TaskStateEnum.Overdue:
            return errorColor;
    }
}
