import React from "react";

export const informationIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 14 14">
        <g id="Group_2791" data-name="Group 2791" transform="translate(-1019 -242)">
            <g id="Ellipse_65" data-name="Ellipse 65" transform="translate(1019 242)" fill="#efeffa" stroke="#7284fa" strokeWidth="1">
                <circle cx="7" cy="7" r="7" stroke="none" />
                <circle cx="7" cy="7" r="6.5" fill="none" />
            </g>
            <g id="info" transform="translate(993.179 245)">
                <path
                    id="Path_1500"
                    data-name="Path 1500"
                    d="M34.4,6.8l-.106.435q-.479.189-.764.288a2.016,2.016,0,0,1-.663.1,1.323,1.323,0,0,1-.9-.283.915.915,0,0,1-.322-.719,2.542,2.542,0,0,1,.024-.346q.024-.177.078-.4l.4-1.413q.053-.2.09-.385a1.672,1.672,0,0,0,.036-.331.512.512,0,0,0-.112-.378.631.631,0,0,0-.427-.107,1.119,1.119,0,0,0-.317.048c-.109.032-.2.063-.279.092l.107-.435q.392-.16.75-.273a2.244,2.244,0,0,1,.679-.114,1.291,1.291,0,0,1,.888.278.924.924,0,0,1,.312.723c0,.061-.007.17-.022.324a2.168,2.168,0,0,1-.08.426l-.4,1.407a3.737,3.737,0,0,0-.087.388,1.994,1.994,0,0,0-.039.329.474.474,0,0,0,.125.382.707.707,0,0,0,.435.1,1.255,1.255,0,0,0,.329-.051A1.838,1.838,0,0,0,34.4,6.8Zm.1-5.907a.823.823,0,0,1-.278.627.946.946,0,0,1-.669.259.957.957,0,0,1-.673-.259A.821.821,0,0,1,32.6.89a.83.83,0,0,1,.281-.629.993.993,0,0,1,1.342,0A.831.831,0,0,1,34.505.89Z"
                    transform="translate(0 0)"
                    fill="#7284fa"
                />
            </g>
        </g>
    </svg>
);

export const blueCheck = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.823 10.436">
        <g id="tick-kopi" transform="translate(0.5 -67.428)">
            <g id="Group_918" data-name="Group 918" transform="translate(0 67.997)">
                <path
                    id="Path_1240"
                    data-name="Path 1240"
                    d="M12.567,68.184a.638.638,0,0,0-.9,0l-7.64,7.64L1.088,72.888a.638.638,0,0,0-.9.9l3.388,3.388a.638.638,0,0,0,.9,0l8.091-8.091A.638.638,0,0,0,12.567,68.184Z"
                    transform="translate(0 -67.998)"
                    fill="#7284FA"
                    stroke="#7284FA"
                    strokeWidth="1"
                />
            </g>
        </g>
    </svg>
);

export const arrow = (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.564" height="11.086" viewBox="0 0 20.564 11.086">
        <g id="Group_2154" data-name="Group 2154" transform="translate(-1752.544 -316.279)">
            <line
                id="Line_363"
                data-name="Line 363"
                x2="18.753"
                transform="translate(1753.294 322.232)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeWidth="1.5"
            />
            <line
                id="Line_364"
                data-name="Line 364"
                x2="4.892"
                y2="4.892"
                transform="translate(1767.155 317.34)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeWidth="1.5"
            />
            <line
                id="Line_365"
                data-name="Line 365"
                y1="4.077"
                x2="4.892"
                transform="translate(1767.155 322.232)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeWidth="1.5"
            />
        </g>
    </svg>
);

export const leftWhiteArrowButton = (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.977" height="11.283" viewBox="0 0 20.977 11.283">
        <g id="Group_7880" data-name="Group 7880" transform="translate(-9335.187 4603.041)">
            <g id="Group_7879" data-name="Group 7879" transform="translate(11108.707 -4275.478) rotate(180)">
                <line
                    id="Line_363"
                    data-name="Line 363"
                    x2="19.166"
                    transform="translate(1753.294 322.34)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="1.5"
                />
                <line
                    id="Line_364"
                    data-name="Line 364"
                    x2="5"
                    y2="5"
                    transform="translate(1767.46 317.34)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="1.5"
                />
                <line
                    id="Line_365"
                    data-name="Line 365"
                    y1="4.167"
                    x2="5"
                    transform="translate(1767.46 322.34)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="1.5"
                />
            </g>
        </g>
    </svg>
);

export const settingsIcon = (
    <svg id="Group_1241" data-name="Group 1241" xmlns="http://www.w3.org/2000/svg" stroke="#7284FA" viewBox="0 0 23.825 24">
        <g id="Group_923" data-name="Group 923">
            <path
                id="Path_1245"
                data-name="Path 1245"
                d="M14.779,14.143l1.577-.911-2-3.464-1.574.909a6.531,6.531,0,0,0-2.354-1.354V7.5h-4V9.321a6.479,6.479,0,0,0-2.356,1.356L2.5,9.768l-2,3.464,1.578.912a6.443,6.443,0,0,0,0,2.713L.5,17.768l2,3.464,1.574-.908a6.525,6.525,0,0,0,2.354,1.354V23.5h4V21.68a6.454,6.454,0,0,0,2.356-1.355l1.572.907,2-3.464-1.577-.91A6.469,6.469,0,0,0,14.779,14.143Z"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                id="Path_1246"
                data-name="Path 1246"
                d="M22.318,4.2l1.007-.582-1-1.732-1.007.582a3.451,3.451,0,0,0-1.39-.81V.5h-2V1.656a3.486,3.486,0,0,0-1.394.806l-1-.579-1,1.732,1,.578A3.443,3.443,0,0,0,15.537,5.8l-1.005.581,1,1.732,1.005-.58a3.457,3.457,0,0,0,1.391.811V9.5h2V8.344a3.5,3.5,0,0,0,1.4-.807l1,.578,1-1.732-1-.578A3.465,3.465,0,0,0,22.318,4.2Z"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <circle id="Ellipse_41" data-name="Ellipse 41" cx="3" cy="3" r="3" transform="translate(5.429 12.5)" strokeLinejoin="round" strokeWidth="1.5" />
            <circle
                id="Ellipse_42"
                data-name="Ellipse 42"
                cx="1.5"
                cy="1.5"
                r="1.5"
                transform="translate(17.429 3.5)"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </g>
    </svg>
);

export const systemsIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.5 21.5">
        <g id="Group_1193" data-name="Group 1193" transform="translate(-1030.75 -2008.75)">
            <g id="Group_1192" data-name="Group 1192">
                <g id="Group_1191" data-name="Group 1191">
                    <path
                        id="Path_1263"
                        data-name="Path 1263"
                        d="M1054.5,2025.911a1.546,1.546,0,0,1-1.5,1.589h-20a1.546,1.546,0,0,1-1.5-1.589v-14.822a1.546,1.546,0,0,1,1.5-1.589h20a1.546,1.546,0,0,1,1.5,1.589Z"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                    <line
                        id="Line_173"
                        data-name="Line 173"
                        x2="14"
                        transform="translate(1036 2029.5)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                    <line
                        id="Line_174"
                        data-name="Line 174"
                        y2="2"
                        transform="translate(1042.5 2027.5)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                    <circle
                        id="Ellipse_47"
                        data-name="Ellipse 47"
                        cx="0.5"
                        cy="0.5"
                        r="0.5"
                        transform="translate(1042.5 2025)"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                    <line id="Line_175" data-name="Line 175" x2="23" transform="translate(1031.5 2023.5)" strokeLinejoin="round" strokeWidth="1.5" />
                </g>
                <circle
                    id="Ellipse_48"
                    data-name="Ellipse 48"
                    cx="5"
                    cy="5"
                    r="5"
                    transform="translate(1034 2011.5)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_1264"
                    data-name="Path 1264"
                    d="M1041.827,2012.376,1039,2016.5l3.205,3.836"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <line
                    id="Line_176"
                    data-name="Line 176"
                    x1="5"
                    transform="translate(1034 2016.5)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <line id="Line_177" data-name="Line 177" x2="6" transform="translate(1046 2012.5)" strokeLinejoin="round" strokeWidth="1.5" />
                <line id="Line_178" data-name="Line 178" x2="6" transform="translate(1046 2015.5)" strokeLinejoin="round" strokeWidth="1.5" />
                <line id="Line_179" data-name="Line 179" x2="6" transform="translate(1046 2018.5)" strokeLinejoin="round" strokeWidth="1.5" />
            </g>
        </g>
    </svg>
);

export const systemsMenuIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.5 21.5" stroke="#7284FA">
        <g id="Group_1193" data-name="Group 1193" transform="translate(-1030.75 -2008.75)">
            <g id="Group_1192" data-name="Group 1192">
                <g id="Group_1191" data-name="Group 1191">
                    <path
                        id="Path_1263"
                        data-name="Path 1263"
                        d="M1054.5,2025.911a1.546,1.546,0,0,1-1.5,1.589h-20a1.546,1.546,0,0,1-1.5-1.589v-14.822a1.546,1.546,0,0,1,1.5-1.589h20a1.546,1.546,0,0,1,1.5,1.589Z"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                    <line
                        id="Line_173"
                        data-name="Line 173"
                        x2="14"
                        transform="translate(1036 2029.5)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                    <line
                        id="Line_174"
                        data-name="Line 174"
                        y2="2"
                        transform="translate(1042.5 2027.5)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                    <circle
                        id="Ellipse_47"
                        data-name="Ellipse 47"
                        cx="0.5"
                        cy="0.5"
                        r="0.5"
                        transform="translate(1042.5 2025)"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                    <line id="Line_175" data-name="Line 175" x2="23" transform="translate(1031.5 2023.5)" strokeLinejoin="round" strokeWidth="1.5" />
                </g>
                <circle
                    id="Ellipse_48"
                    data-name="Ellipse 48"
                    cx="5"
                    cy="5"
                    r="5"
                    transform="translate(1034 2011.5)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_1264"
                    data-name="Path 1264"
                    d="M1041.827,2012.376,1039,2016.5l3.205,3.836"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <line
                    id="Line_176"
                    data-name="Line 176"
                    x1="5"
                    transform="translate(1034 2016.5)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <line id="Line_177" data-name="Line 177" x2="6" transform="translate(1046 2012.5)" strokeLinejoin="round" strokeWidth="1.5" />
                <line id="Line_178" data-name="Line 178" x2="6" transform="translate(1046 2015.5)" strokeLinejoin="round" strokeWidth="1.5" />
                <line id="Line_179" data-name="Line 179" x2="6" transform="translate(1046 2018.5)" strokeLinejoin="round" strokeWidth="1.5" />
            </g>
        </g>
    </svg>
);

export const leftArrowIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.5" height="10.121" viewBox="0 0 15.5 10.121">
        <g id="Group_2573" data-name="Group 2573" transform="translate(-1160.25 -1064.939)">
            <line
                id="Line_168"
                data-name="Line 168"
                x2="14"
                transform="translate(1161 1070)"
                fill="none"
                stroke="#618cf5"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                id="Path_1258"
                data-name="Path 1258"
                d="M989.5,2699.5l-4-4,4-4"
                transform="translate(175.5 -1625.5)"
                fill="#d9daee"
                stroke="#618cf5"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </g>
    </svg>
);

export const rightArrowIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.5" height="10.121" viewBox="0 0 15.5 10.121">
        <g id="Group_2574" data-name="Group 2574" transform="translate(-1169.25 -1069.939)">
            <line
                id="Line_169"
                data-name="Line 169"
                x2="14"
                transform="translate(1170 1075)"
                fill="none"
                stroke="#618cf5"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                id="Path_1259"
                data-name="Path 1259"
                d="M1004.5,2704.5l4-4-4-4"
                transform="translate(175.5 -1625.5)"
                fill="#d9daee"
                stroke="#618cf5"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </g>
    </svg>
);

export const dataProcessorIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.546" height="20.072" viewBox="0 0 18.546 20.072">
        <g id="Group_3232" data-name="Group 3232" transform="translate(-97 -2143)">
            <path
                id="Path_2781"
                data-name="Path 2781"
                d="M100.17,2164.609v1.061l-1.937.554a1.073,1.073,0,0,0-.733.972v.381h4.2"
                transform="translate(0 -5.006)"
                fill="#dad9ef"
                stroke="#618cf5"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <path
                id="Path_2782"
                data-name="Path 2782"
                d="M103.526,2164.578v1.085l1.936.554a1.072,1.072,0,0,1,.734.972v.381H102"
                transform="translate(-1.067 -4.998)"
                fill="#dad9ef"
                stroke="#618cf5"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <ellipse
                id="Ellipse_89"
                data-name="Ellipse 89"
                cx="2.289"
                cy="2.496"
                rx="2.289"
                ry="2.496"
                transform="translate(99.026 2154.943)"
                fill="#dad9ef"
                stroke="#618cf5"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <path
                id="Path_2783"
                data-name="Path 2783"
                d="M104.069,2161.073a1.309,1.309,0,0,1-1.884-.4,2.194,2.194,0,0,1-2.62.328"
                transform="translate(-0.49 -4.072)"
                fill="#dad9ef"
                stroke="#618cf5"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <path
                id="Path_2784"
                data-name="Path 2784"
                d="M113.17,2164.609v1.061l-1.937.554a1.073,1.073,0,0,0-.733.972v.381h4.2"
                transform="translate(-3.083 -5.006)"
                fill="#dad9ef"
                stroke="#618cf5"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <path
                id="Path_2785"
                data-name="Path 2785"
                d="M116.526,2164.594v1.073l1.936.554a1.072,1.072,0,0,1,.734.972v.381H115"
                transform="translate(-4.15 -5.002)"
                fill="#dad9ef"
                stroke="#618cf5"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <ellipse
                id="Ellipse_90"
                data-name="Ellipse 90"
                cx="2.289"
                cy="2.496"
                rx="2.289"
                ry="2.496"
                transform="translate(108.943 2154.943)"
                fill="#dad9ef"
                stroke="#618cf5"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <path
                id="Path_2786"
                data-name="Path 2786"
                d="M117.057,2161.07a1.3,1.3,0,0,1-1.871-.4,2.194,2.194,0,0,1-2.62.328"
                transform="translate(-3.573 -4.072)"
                fill="#dad9ef"
                stroke="#618cf5"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <g id="Group_3233" data-name="Group 3233" transform="translate(102.459 2143.5)">
                <path
                    id="Path_2787"
                    data-name="Path 2787"
                    d="M106.67,2177.609v1.061l-1.937.554a1.073,1.073,0,0,0-.733.972v.381h4.2"
                    transform="translate(-104 -2172.949)"
                    fill="#dad9ef"
                    stroke="#618cf5"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <path
                    id="Path_2788"
                    data-name="Path 2788"
                    d="M110.026,2177.609v1.061l1.936.554a1.072,1.072,0,0,1,.734.972v.381h-4.2"
                    transform="translate(-105.067 -2172.949)"
                    fill="#dad9ef"
                    stroke="#618cf5"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <ellipse
                    id="Ellipse_91"
                    data-name="Ellipse 91"
                    cx="2.289"
                    cy="2.496"
                    rx="2.289"
                    ry="2.496"
                    transform="translate(1.526)"
                    fill="#dad9ef"
                    stroke="#618cf5"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <path
                    id="Path_2789"
                    data-name="Path 2789"
                    d="M110.557,2174.051a1.289,1.289,0,0,1-1.871-.381,2.194,2.194,0,0,1-2.62.328"
                    transform="translate(-104.49 -2172.015)"
                    fill="#dad9ef"
                    stroke="#618cf5"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
            </g>
            <line
                id="Line_415"
                data-name="Line 415"
                x2="1.316"
                y2="1.144"
                transform="translate(108.04 2152.654)"
                fill="none"
                stroke="#618cf5"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <path
                id="Path_2790"
                data-name="Path 2790"
                d="M1.312,0,0,1.144"
                transform="translate(103.388 2152.654)"
                fill="#dad9ef"
                stroke="#618cf5"
                strokeLinecap="round"
                strokeWidth="1"
            />
        </g>
    </svg>
);

export const arrowRight = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.05 19.741">
        <g transform="translate(235.429 39.12) rotate(180)">
            <line x1="7.808" y2="7.808" transform="translate(225.5 21.5)" strokeLinecap="round" strokeWidth="3" />
            <line x1="7.808" y1="7.25" transform="translate(225.5 29.75)" strokeLinecap="round" strokeWidth="3" />
        </g>
    </svg>
);

export const arrowLeft = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.05 19.741">
        <g transform="translate(-223.379 -19.379)">
            <line x1="7.808" y2="7.808" transform="translate(225.5 21.5)" strokeLinecap="round" strokeWidth="3" />
            <line x1="7.808" y1="7.25" transform="translate(225.5 29.75)" strokeLinecap="round" strokeWidth="3" />
        </g>
    </svg>
);

export const legalEntitiesIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.166 20.166" stroke="#7284FA">
        <g id="Group_2516" data-name="Group 2516" transform="translate(-91.917 -956.038)">
            <g id="Group_2515" data-name="Group 2515" transform="translate(92.417 956.538)">
                <rect
                    id="Rectangle_728"
                    data-name="Rectangle 728"
                    width="9.166"
                    height="11.666"
                    transform="translate(0 7.5)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <rect
                    id="Rectangle_729"
                    data-name="Rectangle 729"
                    width="5.833"
                    height="5"
                    transform="translate(1.667 2.5)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_375"
                    data-name="Line 375"
                    y1="2.5"
                    transform="translate(4.583)"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <path id="Path_2176" data-name="Path 2176" d="M0,0H7.5V15H0Z" transform="translate(11.666 4.167)" strokeLinecap="round" strokeWidth="1" />
                <line
                    id="Line_376"
                    data-name="Line 376"
                    x2="4.167"
                    transform="translate(13.333 5.833)"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_377"
                    data-name="Line 377"
                    x2="4.167"
                    transform="translate(13.333 7.5)"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_378"
                    data-name="Line 378"
                    x2="4.167"
                    transform="translate(13.333 9.166)"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_379"
                    data-name="Line 379"
                    x2="4.167"
                    transform="translate(13.333 10.833)"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_380"
                    data-name="Line 380"
                    x2="4.167"
                    transform="translate(13.333 12.5)"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_381"
                    data-name="Line 381"
                    x2="4.167"
                    transform="translate(13.333 14.166)"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_382"
                    data-name="Line 382"
                    x2="4.167"
                    transform="translate(13.333 15.833)"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_383"
                    data-name="Line 383"
                    x2="5.833"
                    transform="translate(1.667 10)"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_384"
                    data-name="Line 384"
                    x2="5.833"
                    transform="translate(1.667 12.5)"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_385"
                    data-name="Line 385"
                    x2="5.833"
                    transform="translate(1.667 15)"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_386"
                    data-name="Line 386"
                    x2="2.5"
                    transform="translate(3.333 4.167)"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_387"
                    data-name="Line 387"
                    x2="2.5"
                    transform="translate(3.333 5.833)"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <rect
                    id="Rectangle_731"
                    data-name="Rectangle 731"
                    width="1.667"
                    height="1.667"
                    transform="translate(4.167 17.5)"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <rect
                    id="Rectangle_732"
                    data-name="Rectangle 732"
                    width="1.667"
                    height="1.667"
                    transform="translate(14.583 17.5)"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
            </g>
        </g>
    </svg>
);

export const processingActivitiesIcon = (
    <svg id="Group_3479" stroke="#7284FA" data-name="Group 3479" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.404 19.404">
        <defs>
            <clipPath id="clipPath">
                <rect id="Rectangle_1059" data-name="Rectangle 1059" width="19.404" height="19.404" fill="none" strokeWidth="1" />
            </clipPath>
        </defs>
        <g id="Group_3478" data-name="Group 3478" transform="translate(0 0)" clipPath="url(#clipPath)">
            <circle
                id="Ellipse_96"
                data-name="Ellipse 96"
                cx="5.734"
                cy="5.734"
                r="5.734"
                transform="translate(3.964 3.969)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <path
                id="Path_3106"
                data-name="Path 3106"
                d="M14.733,6.4a2.847,2.847,0,0,1-1.246,1.867c-2.312-.578-1.438.73-1.246,1.5.25.249.747,0,.747,0,1.115,2.194-.747,2.742-.747,2.742-1.357,1.357-.915,1.744-2.243,1.744-.25,0-.749-1-.749-1.246,0-1.9-.952-2.243-1.744-2.243-.748,0-.748-1-.748-1s0-1.747,1.246-2,1.246.249,1.246.249c.515.515,2.341-.495.673-1.793-.617-.481,2.251-1.448,2.251-1.448"
                transform="translate(-0.797 -0.564)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <path
                id="Path_3107"
                data-name="Path 3107"
                d="M19.995,2.751l-3.457-.68.268,2.978"
                transform="translate(-1.95 -0.244)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <path
                id="Path_3108"
                data-name="Path 3108"
                d="M1.006,18.707l3.939.823-.318-2.969"
                transform="translate(-0.119 -1.953)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <path
                id="Path_3109"
                data-name="Path 3109"
                d="M15.611,2.071A9.264,9.264,0,0,1,8.759,19"
                transform="translate(-1.033 -0.244)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <path
                id="Path_3110"
                data-name="Path 3110"
                d="M4.885,17.636A9.264,9.264,0,0,1,11.718.707"
                transform="translate(-0.059 -0.059)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
        </g>
    </svg>
);

export const helpIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
        <g id="Group_3480" data-name="Group 3480" transform="translate(-34.498 -389.476)">
            <g id="Group_1173" data-name="Group 1173" transform="translate(-872.621 -5076.022)">
                <g id="Group_640" data-name="Group 640" transform="translate(907.619 5466.17)">
                    <circle
                        id="Ellipse_34"
                        data-name="Ellipse 34"
                        cx="8"
                        cy="8"
                        r="8"
                        transform="translate(0 -0.172)"
                        fill="#dad9ef"
                        stroke="#7284fa"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                </g>
            </g>
            <path
                id="Path_3112"
                data-name="Path 3112"
                d="M1.5,10.26V9.927A1.96,1.96,0,0,1,1.7,8.977a2.708,2.708,0,0,1,.727-.784,4.022,4.022,0,0,0,.8-.758,1.138,1.138,0,0,0,.179-.644.81.81,0,0,0-.291-.67,1.307,1.307,0,0,0-.836-.234,2.875,2.875,0,0,0-.914.14,6.491,6.491,0,0,0-.821.338L.109,5.449a4.573,4.573,0,0,1,2.26-.587,2.442,2.442,0,0,1,1.616.5,1.7,1.7,0,0,1,.6,1.377,1.961,1.961,0,0,1-.114.694,1.99,1.99,0,0,1-.345.579,5.578,5.578,0,0,1-.8.717,3.058,3.058,0,0,0-.647.629,1.207,1.207,0,0,0-.164.67v.234Zm-.213,1.657a.684.684,0,0,1,.764-.784.756.756,0,0,1,.571.205.8.8,0,0,1,.2.579.825.825,0,0,1-.2.584.738.738,0,0,1-.569.216.739.739,0,0,1-.566-.21A.828.828,0,0,1,1.283,11.917Z"
                transform="translate(40.652 389.187)"
                fill="#7284fa"
                strokeWidth="0"
            />
        </g>
    </svg>
);

export const recordIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.675 26.001" stroke="#7284FA">
        <g id="Group_3319" data-name="Group 3319" transform="translate(-1174.564 -487.929)">
            <g id="Group_3262" data-name="Group 3262" transform="translate(1175.314 488.679)">
                <g id="Group_3261" data-name="Group 3261" transform="translate(0)">
                    <g id="Group_3260" data-name="Group 3260">
                        <path
                            id="Path_2792"
                            data-name="Path 2792"
                            d="M861.544,912.5h2.131v22.37H844.5V912.5h2.13"
                            transform="translate(-844.5 -910.369)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                        <path
                            id="Path_2794"
                            data-name="Path 2794"
                            d="M848.63,914.5H846.5v18.109h14.914V914.5h-2.13"
                            transform="translate(-844.369 -910.239)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                        <path
                            id="Path_2793"
                            data-name="Path 2793"
                            d="M855.957,912.631a2.131,2.131,0,1,0-4.261,0h-3.2v3.2h10.653v-3.2Z"
                            transform="translate(-844.239 -910.5)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                    </g>
                </g>
                <line
                    id="Line_428"
                    data-name="Line 428"
                    x2="8.554"
                    transform="translate(5.132 8.554)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_429"
                    data-name="Line 429"
                    x2="8.554"
                    transform="translate(5.132 11.12)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_430"
                    data-name="Line 430"
                    x2="8.554"
                    transform="translate(5.132 13.686)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_431"
                    data-name="Line 431"
                    x2="4.794"
                    transform="translate(5.133 16.253)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
            </g>
        </g>
    </svg>
);

export const signOutIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.5 17.214" stroke="#7284FA">
        <g id="Group_1282" data-name="Group 1282" transform="translate(70.249 975.964) rotate(-180)">
            <path
                id="Path_776"
                data-name="Path 776"
                d="M64.093,963.311a7.764,7.764,0,0,0-6.737-3.811,7.857,7.857,0,1,0,0,15.714,7.941,7.941,0,0,0,6.664-3.692"
                transform="translate(0)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <g id="Group_564" data-name="Group 564" transform="translate(69.499 970.214) rotate(180)">
                <line
                    id="Line_127"
                    data-name="Line 127"
                    x1="10.714"
                    transform="translate(1.429 2.857)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <path id="Path_775" data-name="Path 775" d="M2.857,0,0,2.857,2.857,5.714" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
            </g>
        </g>
    </svg>
);

export const dataControllerRecordIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 12.208 15.321">
        <g id="Group_3263" data-name="Group 3263" transform="translate(-1129.814 -513.179)">
            <g id="Group_3262" data-name="Group 3262" transform="translate(285.814 -396.821)">
                <g id="Group_3261" data-name="Group 3261" transform="translate(844.5 910.5)">
                    <g id="Group_3260" data-name="Group 3260">
                        <path
                            id="Path_2792"
                            data-name="Path 2792"
                            d="M854.463,912.5h1.245v13.076H844.5V912.5h1.245"
                            transform="translate(-844.5 -911.255)"
                            fill="none"
                            stroke="#7387f7"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                        <path
                            id="Path_2794"
                            data-name="Path 2794"
                            d="M847.745,914.5H846.5v10.585h8.717V914.5h-1.245"
                            transform="translate(-845.255 -912.009)"
                            fill="#efeffa"
                            stroke="#7387f7"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                        <path
                            id="Path_2793"
                            data-name="Path 2793"
                            d="M852.859,911.745a1.245,1.245,0,0,0-2.491,0H848.5v1.868h6.227v-1.868Z"
                            transform="translate(-846.009 -910.5)"
                            fill="#efeffa"
                            stroke="#7387f7"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                    </g>
                </g>
                <line
                    id="Line_428"
                    data-name="Line 428"
                    x2="5"
                    transform="translate(847.5 915.5)"
                    fill="none"
                    stroke="#7387f7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_429"
                    data-name="Line 429"
                    x2="5"
                    transform="translate(847.5 917)"
                    fill="none"
                    stroke="#7387f7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_430"
                    data-name="Line 430"
                    x2="5"
                    transform="translate(847.5 918.5)"
                    fill="none"
                    stroke="#7387f7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_431"
                    data-name="Line 431"
                    x2="2.802"
                    transform="translate(847.5 920)"
                    fill="none"
                    stroke="#7387f7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
            </g>
        </g>
    </svg>
);

export const greenArrowRight = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.191 16.846">
        <path
            id="Path_804"
            data-name="Path 804"
            d="M0,0,6.655,6.655,13.31,0"
            transform="translate(1.768 15.078) rotate(-90)"
            fill="none"
            stroke="#2cd5c4"
            strokeLinecap="round"
            strokeWidth="2.5"
        />
    </svg>
);

export const editIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.656" height="24.063" viewBox="0 0 23.656 24.063">
        <g id="Group_1085" data-name="Group 1085" transform="translate(-1768.553 -47.392)">
            <g id="Group_1082" data-name="Group 1082" transform="translate(1623.554 -94.381)">
                <path
                    id="Path_1384"
                    data-name="Path 1384"
                    d="M164.446,153.417v11.919H145.5V144.477H159.46"
                    transform="translate(0)"
                    fill="none"
                    stroke="#5a61a8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
            </g>
            <g id="Group_1080" data-name="Group 1080" transform="translate(1679.407 -46.539)">
                <path
                    id="Path_1383"
                    data-name="Path 1383"
                    d="M101.929,107.945l-4.1.962.962-4.1,9.894-9.9a1.27,1.27,0,0,1,1.794,0l1.346,1.346a1.27,1.27,0,0,1,0,1.794Z"
                    transform="translate(0 0)"
                    fill="#b6d5ef"
                    stroke="#5a61a8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_180"
                    data-name="Line 180"
                    x1="3.142"
                    y1="3.141"
                    transform="translate(108.236 95.359)"
                    fill="none"
                    stroke="#5a61a8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_181"
                    data-name="Line 181"
                    x1="3.134"
                    y1="3.149"
                    transform="translate(107.34 96.256)"
                    fill="none"
                    stroke="#5a61a8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_182"
                    data-name="Line 182"
                    x1="3.14"
                    y1="3.14"
                    transform="translate(98.95 104.646)"
                    fill="none"
                    stroke="#5a61a8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
            </g>
        </g>
    </svg>
);

export const download = (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.5" height="19.5" viewBox="0 0 19.5 19.5">
        <g id="Group_767" data-name="Group 767" transform="translate(-984.75 -2589.75)">
            <path
                id="Path_1070"
                data-name="Path 1070"
                d="M1003.5,2607.718v1.565a2.348,2.348,0,0,1-2.347,2.347H987.847a2.347,2.347,0,0,1-2.347-2.347v-1.565"
                transform="translate(0 -3.13)"
                fill="#dad9ef"
                stroke="#7284fa"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <line
                id="Line_163"
                data-name="Line 163"
                y1="13.997"
                transform="translate(994.5 2590.5)"
                fill="none"
                stroke="#7284fa"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                id="Path_1071"
                data-name="Path 1071"
                d="M1000.955,2600.608l-5.727,5.727-5.727-5.727"
                transform="translate(-0.727 -1.838)"
                fill="#dad9ef"
                stroke="#7284fa"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </g>
    </svg>
);

export const eye = (
    <svg xmlns="http://www.w3.org/2000/svg" width="21.181" height="12.624" viewBox="0 0 21.181 12.624">
        <g id="Group_766" data-name="Group 766" transform="translate(-144.75 -242.75)">
            <path
                id="Path_1068"
                data-name="Path 1068"
                d="M165.181,249.062s-4.405,5.562-9.841,5.562-9.841-5.562-9.841-5.562,4.405-5.562,9.841-5.562S165.181,249.062,165.181,249.062Z"
                fill="#dad9ef"
                stroke="#7284fa"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <circle
                id="Ellipse_50"
                data-name="Ellipse 50"
                cx="3.423"
                cy="3.423"
                r="3.423"
                transform="translate(151.918 245.639)"
                fill="#dad9ef"
                stroke="#7284fa"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                id="Path_1069"
                data-name="Path 1069"
                d="M156.711,248A1.711,1.711,0,1,1,155,249.711"
                transform="translate(-1.371 -0.649)"
                fill="#dad9ef"
                stroke="#7284fa"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </g>
    </svg>
);

export const NoteSVG = (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.811" height="19.811" viewBox="0 0 19.811 19.811">
        <g id="Group_10230" data-name="Group 10230" transform="translate(-1684.25 -605.25)">
            <path id="Polygon_1" data-name="Polygon 1" d="M4,0,8,5H0Z" transform="matrix(0.719, -0.695, 0.695, 0.719, 1694, 620.557)" fill="#dad9ef" />
            <g id="Group_1105" data-name="Group 1105" transform="translate(747.5 -256.5)">
                <path
                    id="Path_1449"
                    data-name="Path 1449"
                    d="M949.239,874.239V880.5H937.5v-18h18v11.739Z"
                    fill="#dad9ef"
                    stroke="#7284fa"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <line
                    id="Line_184"
                    data-name="Line 184"
                    x1="6"
                    y2="6"
                    transform="translate(949.5 874.5)"
                    fill="none"
                    stroke="#7284fa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_1450"
                    data-name="Path 1450"
                    d="M942,872l.643-1a.706.706,0,0,1,1.286,0,.706.706,0,0,0,1.286,0,.706.706,0,0,1,1.286,0,.706.706,0,0,0,1.286,0,.706.706,0,0,1,1.286,0,.706.706,0,0,0,1.286,0l.643-1"
                    transform="translate(0 -1.786)"
                    fill="#dad9ef"
                    stroke="#7284fa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_1451"
                    data-name="Path 1451"
                    d="M942,877l1-1a1.415,1.415,0,0,1,2,0,1.415,1.415,0,0,0,2,0"
                    transform="translate(0 -3.031)"
                    fill="#dad9ef"
                    stroke="#7284fa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
            </g>
        </g>
    </svg>
);

export const maleIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="88.114" height="86.882" viewBox="0 0 88.114 86.882">
        <g id="Group_679" data-name="Group 679" transform="translate(-904.746 -35.69)">
            <path
                id="Path_23"
                data-name="Path 23"
                d="M960.283,53.137l1.871,9.228c5.616,0,5.616,11.07,0,11.07,0,11.072-13.1,18.452-18.719,18.452s-18.718-7.38-18.718-18.452c-5.617,0-5.617-11.07,0-11.07l2.9-15.088L938.5,54.159l-.412-6.882,12.323,6.882-1.231-6.882Z"
                transform="translate(5.367 3.85)"
                fill="none"
                stroke="#032478"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
            <path
                id="Path_24"
                data-name="Path 24"
                d="M966.238,68.989l1.916-13.847s1.871-18.452-24.335-18.452-24.335,18.452-24.335,18.452L921.4,68.989"
                transform="translate(4.985)"
                fill="none"
                stroke="#032478"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
            <path
                id="Path_25"
                data-name="Path 25"
                d="M939.279,77.335l-23.811,6.432a14.753,14.753,0,0,0-9.722,13.815v9.225H991.86V97.583a14.749,14.749,0,0,0-9.722-13.815L958.4,77.285"
                transform="translate(0 14.764)"
                fill="none"
                stroke="#032478"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
            <path
                id="Path_26"
                data-name="Path 26"
                d="M929.082,61.547c0-2.04,1.675-1.846,3.745-1.846s3.746-.194,3.746,1.846"
                transform="translate(8.487 8.367)"
                fill="none"
                stroke="#032478"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
            <path
                id="Path_27"
                data-name="Path 27"
                d="M940.065,61.547c0-2.04,1.675-1.846,3.745-1.846s3.743-.194,3.743,1.846"
                transform="translate(12.481 8.367)"
                fill="none"
                stroke="#032478"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
        </g>
    </svg>
);

export const womanIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="99.419" height="88.047" viewBox="0 0 99.419 88.047">
        <g id="Group_676" data-name="Group 676" transform="translate(0 -10)">
            <g id="Group_673" data-name="Group 673" transform="translate(0 0)">
                <path
                    id="Path_1056"
                    data-name="Path 1056"
                    d="M60.469,43.91c18.853,8.263,36.9,12.991,36.9,27.444v2.063H1V71.354C1,56.9,19.041,52.173,37.9,43.91"
                    transform="translate(0.526 23.104)"
                    fill="none"
                    stroke="#032478"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    id="Path_1057"
                    data-name="Path 1057"
                    d="M46.771,7.866c-29.42,2.913-36.125,44.17-41.657,48.26,0,0,17.72,3.905,24.005-2.292"
                    transform="translate(2.691 5.29)"
                    fill="none"
                    stroke="#032478"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    id="Path_1058"
                    data-name="Path 1058"
                    d="M28.83,7.909C62.3,9.071,65.3,52.074,70.975,56.168c0,0-18.187,3.909-24.641-2.295"
                    transform="translate(20.632 5.247)"
                    fill="none"
                    stroke="#032478"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    id="Path_1059"
                    data-name="Path 1059"
                    d="M44.525,19.945c-2.265,2.952-12.912,16.2-29.943,16.2"
                    transform="translate(7.747 6.926)"
                    fill="none"
                    stroke="#032478"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    id="Path_1060"
                    data-name="Path 1060"
                    d="M60.744,28.133a37.317,37.317,0,0,1-3.985,16.833c.077-.156.671-.122.61,0-4.549,9.113-13.145,10.515-17.575,11.97-6.289-2.063-20.951-4.021-20.951-28.8"
                    transform="translate(9.915 14.802)"
                    fill="none"
                    stroke="#032478"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    id="Path_1061"
                    data-name="Path 1061"
                    d="M40.808,19.945c0,4.858,9.979,17,23.286,17"
                    transform="translate(13.089 6.926)"
                    fill="none"
                    stroke="#032478"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    id="Path_1062"
                    data-name="Path 1062"
                    d="M24.334,30.136c0-2.283,1.882-2.066,4.189-2.066s4.191-.217,4.191,2.066"
                    transform="translate(12.804 14.766)"
                    fill="none"
                    stroke="#032478"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    id="Path_1063"
                    data-name="Path 1063"
                    d="M35.315,30.136c0-2.283,1.882-2.066,4.191-2.066s4.191-.217,4.191,2.066"
                    transform="translate(18.582 14.766)"
                    fill="none"
                    stroke="#032478"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    id="Path_1065"
                    data-name="Path 1065"
                    d="M20.216,46v1.532c0,6.843,8.444,12.394,18.856,12.394s18.854-5.551,18.854-12.394V46"
                    transform="translate(10.637 24.205)"
                    fill="none"
                    stroke="#032478"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
            </g>
        </g>
    </svg>
);

export const whiteArrowRight = (
    <svg xmlns="http://www.w3.org/2000/svg" width="104.449" height="19.554" viewBox="0 0 104.449 19.554">
        <g id="Group_683" data-name="Group 683" transform="translate(-1590.5 -262.086)">
            <g id="Group_678" data-name="Group 678" transform="translate(1303 -1.392)">
                <line
                    id="Line_1"
                    data-name="Line 1"
                    x2="101.701"
                    transform="translate(288.5 273.258)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="2"
                />
                <line
                    id="Line_2"
                    data-name="Line 2"
                    x1="8"
                    y2="8"
                    transform="translate(382.201 273.617)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="2"
                />
                <line
                    id="Line_3"
                    data-name="Line 3"
                    x1="8.035"
                    y1="8.244"
                    transform="translate(382.5 264.892)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="2"
                />
            </g>
        </g>
    </svg>
);

export const whiteArrowLeft = (
    <svg xmlns="http://www.w3.org/2000/svg" width="104.449" height="19.554" viewBox="0 0 104.449 19.554">
        <g id="Group_670" data-name="Group 670" transform="translate(391.949 283.031) rotate(180)">
            <line
                id="Line_1"
                data-name="Line 1"
                x2="101.701"
                transform="translate(288.5 273.258)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeWidth="2"
            />
            <line
                id="Line_2"
                data-name="Line 2"
                x1="8"
                y2="8"
                transform="translate(382.201 273.617)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeWidth="2"
            />
            <line
                id="Line_3"
                data-name="Line 3"
                x1="8"
                y1="8.244"
                transform="translate(382.534 264.892)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeWidth="2"
            />
        </g>
    </svg>
);

export const changeOrganization = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.08 18.5" stroke="#7284FA">
        <g id="Group_1746" data-name="Group 1746" transform="translate(-792.46 -3312.75)">
            <path id="Path_2196" data-name="Path 2196" d="M808.5,3330.5h-9a3,3,0,0,1-3-3v-8" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            <path id="Path_2197" data-name="Path 2197" d="M801.5,3313.5h9a3,3,0,0,1,3,3v8" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            <path id="Path_2198" data-name="Path 2198" d="M793.5,3321.5l3-2,3,2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            <path id="Path_2199" data-name="Path 2199" d="M810.5,3322.5l3,2,3-2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        </g>
    </svg>
);

export const privacyLogo = (
    <svg xmlns="http://www.w3.org/2000/svg" width="155.344" height="40.052" viewBox="0 0 155.344 40.052">
        <path
            id="Path_5217"
            data-name="Path 5217"
            d="M7.79-9.956V0H2.432V-26.828H12.2q4.75,0,7.163,2.28a8.114,8.114,0,0,1,2.413,6.194,7.892,7.892,0,0,1-2.489,6.118Q16.8-9.956,12.2-9.956Zm3.838-4.294q4.712,0,4.712-4.1a4.083,4.083,0,0,0-1.1-3.078,5.081,5.081,0,0,0-3.61-1.064H7.79v8.246Zm21.28-2.774A8.806,8.806,0,0,1,36.024-20.2a8.278,8.278,0,0,1,4.332-1.159v5.738H38.8q-5.89,0-5.89,5.472V0H27.55V-21.052h5.358Zm12.084-10.07A2.7,2.7,0,0,1,45.9-29.2,3.452,3.452,0,0,1,48.3-30.02a3.452,3.452,0,0,1,2.394.817,2.7,2.7,0,0,1,.912,2.109,2.659,2.659,0,0,1-.912,2.071,3.452,3.452,0,0,1-2.394.817,3.452,3.452,0,0,1-2.394-.817A2.659,2.659,0,0,1,44.992-27.094Zm5.966,6.042V0H45.6V-21.052ZM67.26-5.358l5.32-15.694h5.7L70.49,0H63.954l-7.79-21.052H61.9Zm24.51-15.96a8.09,8.09,0,0,1,4.56,1.254,6.83,6.83,0,0,1,2.7,3.382v-4.37h5.32V0h-5.32V-4.408a6.931,6.931,0,0,1-2.7,3.4A8,8,0,0,1,91.77.266a9.043,9.043,0,0,1-4.788-1.292,8.729,8.729,0,0,1-3.325-3.743,12.98,12.98,0,0,1-1.2-5.757,12.98,12.98,0,0,1,1.2-5.757,8.729,8.729,0,0,1,3.325-3.743A9.043,9.043,0,0,1,91.77-21.318Zm1.672,4.674a5.241,5.241,0,0,0-4.028,1.634,6.326,6.326,0,0,0-1.52,4.484,6.256,6.256,0,0,0,1.52,4.465,5.272,5.272,0,0,0,4.028,1.615A5.3,5.3,0,0,0,97.451-6.1a6.147,6.147,0,0,0,1.577-4.427,6.2,6.2,0,0,0-1.577-4.465A5.3,5.3,0,0,0,93.442-16.644Zm27.512-4.674a10.418,10.418,0,0,1,6.783,2.2,9.629,9.629,0,0,1,3.4,6h-5.662a4.371,4.371,0,0,0-1.558-2.641,4.785,4.785,0,0,0-3.078-.969,4.592,4.592,0,0,0-3.553,1.577,6.672,6.672,0,0,0-1.425,4.617,6.672,6.672,0,0,0,1.425,4.617,4.592,4.592,0,0,0,3.553,1.577,4.785,4.785,0,0,0,3.078-.969,4.371,4.371,0,0,0,1.558-2.641h5.662a9.629,9.629,0,0,1-3.4,6,10.418,10.418,0,0,1-6.783,2.2,11.206,11.206,0,0,1-5.472-1.311,9.215,9.215,0,0,1-3.724-3.743,11.781,11.781,0,0,1-1.33-5.738,11.891,11.891,0,0,1,1.33-5.757,9.065,9.065,0,0,1,3.724-3.743A11.339,11.339,0,0,1,120.954-21.318Zm19.874.266,5.738,14.288,5.32-14.288h5.89L144.894,10.032h-5.928l4.75-10.64-8.854-20.444Z"
            transform="translate(-2.432 30.02)"
            fill="#7284fa"
        />
    </svg>
);

export const dotLegalExpandedNavIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="64.932" height="21.720" viewBox="0 0 32.233 10.875">
        <g id="Group_34" data-name="Group 34" transform="translate(-19.142 -29.434)">
            <g id="Group_33" data-name="Group 33" transform="translate(23.967 29.434)">
                <path id="Path_851" data-name="Path 851" d="M85.314,29.434v7.951H83.8V29.434Z" transform="translate(-83.8 -29.434)" fill="#707070" />
                <path
                    id="Path_852"
                    data-name="Path 852"
                    d="M130.386,58.549h-4.362a1.641,1.641,0,0,0,.429,1.171,1.382,1.382,0,0,0,.988.376,1.435,1.435,0,0,0,.876-.258,1.143,1.143,0,0,0,.446-.7h1.6a2.562,2.562,0,0,1-.5,1.117,2.639,2.639,0,0,1-.994.774,3.4,3.4,0,0,1-2.9-.091,2.6,2.6,0,0,1-1.053-1.058,3.7,3.7,0,0,1,0-3.25,2.563,2.563,0,0,1,1.054-1.058,3.208,3.208,0,0,1,1.547-.365,3.138,3.138,0,0,1,1.542.365,2.576,2.576,0,0,1,1.021,1,2.917,2.917,0,0,1,.36,1.456A2.871,2.871,0,0,1,130.386,58.549Zm-1.853-1.773a1.455,1.455,0,0,0-1.015-.376,1.489,1.489,0,0,0-1.032.376,1.539,1.539,0,0,0-.462,1.107h2.89A1.336,1.336,0,0,0,128.533,56.776Z"
                    transform="translate(-121.501 -53.284)"
                    fill="#707070"
                />
                <path
                    id="Path_853"
                    data-name="Path 853"
                    d="M223.942,55.56a1.932,1.932,0,0,1,.763.956V55.281h1.5v5.974a3.305,3.305,0,0,1-.328,1.488,2.417,2.417,0,0,1-.978,1.037,3.1,3.1,0,0,1-1.574.376,3.322,3.322,0,0,1-2.084-.613,2.533,2.533,0,0,1-.967-1.665h1.494a1.231,1.231,0,0,0,.5.7,1.655,1.655,0,0,0,.951.253,1.487,1.487,0,0,0,1.074-.392,1.576,1.576,0,0,0,.408-1.187V59.987a1.96,1.96,0,0,1-.763.962,2.262,2.262,0,0,1-1.289.36,2.556,2.556,0,0,1-1.354-.365,2.466,2.466,0,0,1-.939-1.058,4.083,4.083,0,0,1,0-3.256,2.468,2.468,0,0,1,.939-1.058,2.558,2.558,0,0,1,1.354-.365A2.288,2.288,0,0,1,223.942,55.56Zm-1.956,1.429a1.788,1.788,0,0,0-.429,1.268,1.768,1.768,0,0,0,.429,1.262,1.628,1.628,0,0,0,2.272-.011,1.995,1.995,0,0,0,0-2.514,1.5,1.5,0,0,0-1.134-.467,1.482,1.482,0,0,0-1.139.462Z"
                    transform="translate(-209.857 -53.282)"
                    fill="#707070"
                />
                <path
                    id="Path_854"
                    data-name="Path 854"
                    d="M327.328,55.561a1.931,1.931,0,0,1,.763.956V55.282h1.5v5.952h-1.5V59.989a1.96,1.96,0,0,1-.763.962,2.262,2.262,0,0,1-1.29.359,2.556,2.556,0,0,1-1.354-.365,2.467,2.467,0,0,1-.939-1.058,4.084,4.084,0,0,1,0-3.256,2.468,2.468,0,0,1,.939-1.058,2.558,2.558,0,0,1,1.354-.365,2.288,2.288,0,0,1,1.29.355Zm-1.956,1.429a1.788,1.788,0,0,0-.429,1.268,1.768,1.768,0,0,0,.429,1.262,1.628,1.628,0,0,0,2.272-.011,1.995,1.995,0,0,0,0-2.514,1.5,1.5,0,0,0-1.134-.467,1.482,1.482,0,0,0-1.139.462Z"
                    transform="translate(-305.529 -53.283)"
                    fill="#707070"
                />
                <path id="Path_855" data-name="Path 855" d="M432.348,29.434v7.951h-1.515V29.434Z" transform="translate(-404.94 -29.434)" fill="#707070" />
            </g>
            <circle id="Ellipse_4" data-name="Ellipse 4" cx="1.178" cy="1.178" r="1.178" transform="translate(19.142 37.145) rotate(-76.717)" fill="#8bbee8" />
        </g>
    </svg>
);

export const riskAssessmentIcon = (
    <svg id="Group_4507" data-name="Group 4507" xmlns="http://www.w3.org/2000/svg" width="21.332" height="15.144" viewBox="0 0 21.332 15.144">
        <g id="Group_4503" data-name="Group 4503" transform="translate(0.5 0.5)">
            <path
                id="Path_8178"
                data-name="Path 8178"
                d="M10.666.5A10.166,10.166,0,0,0,.5,10.666v3.978H20.832V10.666A10.166,10.166,0,0,0,10.666.5Z"
                transform="translate(-0.5 -0.5)"
                fill="#dad9ef"
                stroke="#7284fa"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <g id="Group_4504" data-name="Group 4504" transform="translate(1.768 1.768)">
                <circle
                    id="Ellipse_110"
                    data-name="Ellipse 110"
                    cx="1.326"
                    cy="1.326"
                    r="1.326"
                    transform="translate(7.072 7.072)"
                    fill="none"
                    stroke="#7284fa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_472"
                    data-name="Line 472"
                    y1="4.375"
                    x2="4.376"
                    transform="translate(9.336 3.085)"
                    fill="none"
                    stroke="#7284fa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_473"
                    data-name="Line 473"
                    x2="1.768"
                    transform="translate(0 8.84)"
                    fill="none"
                    stroke="#7284fa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_474"
                    data-name="Line 474"
                    x1="1.768"
                    transform="translate(15.028 8.84)"
                    fill="none"
                    stroke="#7284fa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_475"
                    data-name="Line 475"
                    x1="1.634"
                    y2="0.676"
                    transform="translate(14.522 5.184)"
                    fill="none"
                    stroke="#7284fa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_476"
                    data-name="Line 476"
                    x1="0.676"
                    y2="1.634"
                    transform="translate(10.934 0.639)"
                    fill="none"
                    stroke="#7284fa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_477"
                    data-name="Line 477"
                    y2="1.768"
                    transform="translate(8.398)"
                    fill="none"
                    stroke="#7284fa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_478"
                    data-name="Line 478"
                    x2="0.676"
                    y2="1.634"
                    transform="translate(5.185 0.639)"
                    fill="none"
                    stroke="#7284fa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_479"
                    data-name="Line 479"
                    x2="1.248"
                    y2="1.251"
                    transform="translate(2.46 2.459)"
                    fill="none"
                    stroke="#7284fa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_480"
                    data-name="Line 480"
                    x2="1.633"
                    y2="0.676"
                    transform="translate(0.639 5.184)"
                    fill="none"
                    stroke="#7284fa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
            </g>
        </g>
    </svg>
);

export const exportPdfIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.314" height="18.992" viewBox="0 0 19.314 18.992">
        <defs>
            <clipPath id="clipPath">
                <rect id="Rectangle_13238" data-name="Rectangle 13238" width="19.314" height="18.992" fill="none" stroke="#032478" strokeWidth="0.8" />
            </clipPath>
        </defs>
        <g id="Group_5050" data-name="Group 5050" transform="translate(2 1)">
            <g id="Group_5049" data-name="Group 5049" transform="translate(-2 -1)" clipPath="url(#clipPath)">
                <path
                    id="Path_8402"
                    data-name="Path 8402"
                    d="M10.242,6.051H3.971L1,9.17v10.4a.677.677,0,0,0,.66.693h8.582a.677.677,0,0,0,.66-.693V6.744A.677.677,0,0,0,10.242,6.051Z"
                    transform="translate(1.557 -2.785)"
                    fill="none"
                    stroke="#032478"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.8"
                />
                <path
                    id="Path_8403"
                    data-name="Path 8403"
                    d="M22.272,15.92l2.311-.347c.454-.065.712-.259.66-.693l-1.65-13.17a.77.77,0,0,0-.99-.693L16,1.711"
                    transform="translate(-8.492 0.516)"
                    fill="none"
                    stroke="#032478"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.8"
                />
                <path
                    id="Path_8404"
                    data-name="Path 8404"
                    d="M3.971,6.051V8.477a.677.677,0,0,1-.66.693H1"
                    transform="translate(1.557 -2.785)"
                    fill="none"
                    stroke="#032478"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.8"
                />
                <path
                    id="Path_8405"
                    data-name="Path 8405"
                    d="M24.32,27.051H23V30.17"
                    transform="translate(-13.181 -16.507)"
                    fill="none"
                    stroke="#032478"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.8"
                />
                <line
                    id="Line_515"
                    data-name="Line 515"
                    x2="0.99"
                    transform="translate(9.819 12.277)"
                    fill="none"
                    stroke="#032478"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.8"
                />
                <path
                    id="Path_8406"
                    data-name="Path 8406"
                    d="M6,28.784h.66c.33,0,.66,0,.66-.858,0-.875-.33-.875-.66-.875H6V30.17"
                    transform="translate(-1.792 -16.507)"
                    fill="none"
                    stroke="#032478"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.8"
                />
                <path
                    id="Path_8407"
                    data-name="Path 8407"
                    d="M14,30.17h.66c.33,0,.99-.347.99-1.733a1.34,1.34,0,0,0-.99-1.386H14V30.17"
                    transform="translate(-7.152 -16.507)"
                    fill="none"
                    stroke="#032478"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.8"
                />
            </g>
        </g>
    </svg>
);

export const warning = (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.177" height="11.177" viewBox="0 0 11.177 11.177">
        <g id="Group_2178" data-name="Group 2178" transform="translate(-1033 -2207)">
            <line
                id="Line_231"
                data-name="Line 231"
                y1="3.54"
                transform="translate(1038.588 2211.04)"
                fill="none"
                stroke="#e3547b"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <path
                id="Path_1450"
                data-name="Path 1450"
                d="M1043.677,2217.677H1033.5l5.088-10.177Z"
                fill="none"
                stroke="#e3547b"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <path
                id="Path_1451"
                data-name="Path 1451"
                d="M1045.123,2226.061a.561.561,0,0,1-.562.562h0a.562.562,0,0,1-.561-.562h0a.562.562,0,0,1,.561-.562h0a.562.562,0,0,1,.562.562Z"
                transform="translate(-5.973 -10.074)"
                fill="#e3547b"
            />
        </g>
    </svg>
);

export const warningIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="10.5" height="10.5" viewBox="0 0 10.5 10.5">
        <g id="Group_10305" data-name="Group 10305" transform="translate(0.5 0.5)">
            <line
                id="Line_231"
                data-name="Line 231"
                y1="3.304"
                transform="translate(4.75 3.305)"
                fill="none"
                stroke="#f7447a"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <path
                id="Path_1450"
                data-name="Path 1450"
                d="M1043,2217h-9.5l4.75-9.5Z"
                transform="translate(-1033.5 -2207.5)"
                fill="none"
                stroke="#f7447a"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <path
                id="Path_1451"
                data-name="Path 1451"
                d="M1045.048,2226.023a.524.524,0,0,1-.524.524h0a.525.525,0,0,1-.524-.524h0a.525.525,0,0,1,.524-.524h0a.525.525,0,0,1,.524.524Z"
                transform="translate(-1039.774 -2218.1)"
                fill="#e3547b"
            />
        </g>
    </svg>
);

export const star = (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.76" height="19.065" viewBox="0 0 19.76 19.065">
        <defs>
            <clipPath id="clipPath">
                <rect
                    id="Rectangle_13383"
                    data-name="Rectangle 13383"
                    width="19.76"
                    height="19.065"
                    transform="translate(-2 -2)"
                    fill="#e5e5f7"
                    stroke="#648ef5"
                    strokeWidth="1"
                />
            </clipPath>
        </defs>
        <g id="Group_7609" data-name="Group 7609" transform="translate(2 2)">
            <g id="Group_7608" data-name="Group 7608" transform="translate(0 0)" clipPath="url(#clipPath)">
                <path
                    id="Path_12919"
                    data-name="Path 12919"
                    d="M10.843,5.05,15.31,5.7a.451.451,0,0,1,.249.77L12.323,9.622a.453.453,0,0,0-.131.4l.759,4.451a.452.452,0,0,1-.656.475L8.3,12.843a.45.45,0,0,0-.42,0l-4,2.1a.451.451,0,0,1-.654-.477L4,10.013a.452.452,0,0,0-.129-.4L.637,6.458a.452.452,0,0,1,.251-.769l4.469-.644A.452.452,0,0,0,5.7,4.8L7.7.751a.451.451,0,0,1,.809,0L10.5,4.8a.45.45,0,0,0,.339.247"
                    transform="translate(-0.218 -0.218)"
                    fill="#e5e5f7"
                    stroke="#648ef5"
                    strokeWidth="1"
                />
                <path
                    id="Path_12920"
                    data-name="Path 12920"
                    d="M10.843,5.05,15.31,5.7a.451.451,0,0,1,.249.77L12.323,9.622a.453.453,0,0,0-.131.4l.759,4.451a.452.452,0,0,1-.656.475L8.3,12.843a.45.45,0,0,0-.42,0l-4,2.1a.451.451,0,0,1-.654-.477L4,10.013a.452.452,0,0,0-.129-.4L.637,6.458a.452.452,0,0,1,.251-.769l4.469-.644A.452.452,0,0,0,5.7,4.8L7.7.751a.451.451,0,0,1,.809,0L10.5,4.8A.45.45,0,0,0,10.843,5.05Z"
                    transform="translate(-0.218 -0.218)"
                    fill="#e5e5f7"
                    stroke="#648ef5"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
            </g>
        </g>
    </svg>
);

export const dotlegalLinkIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.361" height="13.195" viewBox="0 0 12.361 11.195">
        <g id="Group_7636" data-name="Group 7636" transform="translate(-1324.715 -334.682)">
            <path
                id="Path_12989"
                data-name="Path 12989"
                d="M-16621.969,18722.6s1.818-1.773,2.463-1.348a8.568,8.568,0,0,1,1.559,1.816s-.049,1.672-.74,1.969a18.827,18.827,0,0,0-2.76,2.443s-1.574-.062-1.893-.516"
                transform="translate(17953.887 -18385.506)"
                fill="none"
                stroke="#efeffa"
                strokeWidth="2"
            />
            <path
                id="Path_12990"
                data-name="Path 12990"
                d="M23.684,1.262,23.128.706a2.416,2.416,0,0,0-3.413,0L18.529,1.892a.167.167,0,0,0,.066.277L20,2.635a.166.166,0,0,0,.17-.04l.642-.643a.88.88,0,0,1,1.21,0l.411.411a.859.859,0,0,1,0,1.21L20.057,5.954a.85.85,0,0,1-.382.219.86.86,0,0,1-.828-.219l-.235-.235L18.594,5.7a.169.169,0,0,0-.119-.053.152.152,0,0,0-.12.049l-.94.94a.167.167,0,0,0,0,.236l.331.33a2.387,2.387,0,0,0,.851.547,2.422,2.422,0,0,0,2.224-.269,2.389,2.389,0,0,0,.337-.279l2.526-2.526a2.42,2.42,0,0,0,0-3.413m-.236,3.177L20.923,6.965a2.1,2.1,0,0,1-2.208.47,2.06,2.06,0,0,1-.733-.47l-.213-.213.7-.7.14.14a1.194,1.194,0,0,0,1.682,0l.513-.513,1.868-1.868a1.19,1.19,0,0,0,0-1.682l-.411-.411a1.191,1.191,0,0,0-1.682,0l-.57.57-1.056-.349,1-1a2.082,2.082,0,0,1,2.941,0l.556.556a2.086,2.086,0,0,1,0,2.941"
                transform="translate(1312.438 335.049)"
                fill="#efeffa"
                stroke="#618cf5"
                strokeWidth="0.5"
            />
            <path
                id="Path_12992"
                data-name="Path 12992"
                d="M-16621.814,18744.162c-.316-.527-2.191-.359-2.191-.359l-3.113,3.176.115.178a1.5,1.5,0,0,0,.406,1.7,13.61,13.61,0,0,0,1.477.973s1.938-.828,2.086-.973"
                transform="translate(17953 -18405)"
                fill="none"
                stroke="#efeffa"
                strokeWidth="1"
            />
            <path
                id="Path_12991"
                data-name="Path 12991"
                d="M5.8,19.265,4.386,18.8a.166.166,0,0,0-.17.04l-.642.643a.88.88,0,0,1-1.21,0l-.411-.411a.859.859,0,0,1,0-1.211l2.38-2.38a.848.848,0,0,1,.382-.219.859.859,0,0,1,.829.219l.236.237.016.018a.168.168,0,0,0,.119.054.181.181,0,0,0,.121-.049l.94-.94a.166.166,0,0,0,0-.235l-.331-.331a2.383,2.383,0,0,0-.851-.547,2.421,2.421,0,0,0-2.224.268,2.385,2.385,0,0,0-.338.279L.706,16.759a2.416,2.416,0,0,0,0,3.413l.556.556a2.417,2.417,0,0,0,3.413,0l1.187-1.187a.167.167,0,0,0-.066-.276M4.439,20.492a2.082,2.082,0,0,1-2.941,0l-.556-.556A2.082,2.082,0,0,1,.941,17l2.526-2.526a2.035,2.035,0,0,1,.291-.24A2.091,2.091,0,0,1,5.676,14a2.051,2.051,0,0,1,.733.47l.213.213-.7.7-.14-.14a1.193,1.193,0,0,0-1.682,0L1.716,17.625a1.19,1.19,0,0,0,0,1.682l.411.411a1.19,1.19,0,0,0,1.682,0l.57-.57,1.055.349Z"
                transform="translate(1324.965 324.193)"
                fill="#efeffa"
                stroke="#618cf5"
                strokeWidth="0.5"
            />
        </g>
    </svg>
);

export const taskManagementIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24.002" height="24.002" viewBox="0 0 24.002 24.002">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_13076" data-name="Rectangle 13076" width="23.502" height="24.002" transform="translate(0 0)" fill="#d9daee" />
            </clipPath>
        </defs>
        <g id="Group_4652" data-name="Group 4652" transform="translate(-35 -404)">
            <rect id="Rectangle_13077" data-name="Rectangle 13077" width="19" height="15" transform="translate(36 407)" fill="#d9daee" />
            <g id="Group_4649" data-name="Group 4649" transform="translate(35 404)">
                <path
                    id="Path_8194"
                    data-name="Path 8194"
                    d="M20.5,10V2.5h-4"
                    fill="#d9daee"
                    stroke="#7284fa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <g id="Group_4644" data-name="Group 4644">
                    <g id="Group_4650" data-name="Group 4650">
                        <path
                            id="Path_8195"
                            data-name="Path 8195"
                            d="M4.5,2.5H.5v16h11"
                            fill="#d9daee"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                        <rect
                            id="Rectangle_13072"
                            data-name="Rectangle 13072"
                            width="3"
                            height="4"
                            transform="translate(4.5 0.5)"
                            fill="#d9daee"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                        <rect
                            id="Rectangle_13073"
                            data-name="Rectangle 13073"
                            width="3"
                            height="4"
                            transform="translate(13.5 0.5)"
                            fill="#d9daee"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                    </g>
                </g>
                <line
                    id="Line_490"
                    data-name="Line 490"
                    x2="6"
                    transform="translate(7.5 2.5)"
                    fill="none"
                    stroke="#7284fa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <g id="Group_4646" data-name="Group 4646">
                    <g id="Group_4651" data-name="Group 4651">
                        <line
                            id="Line_491"
                            data-name="Line 491"
                            x2="20"
                            transform="translate(0.5 7.5)"
                            fill="none"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                        <path
                            id="Path_8196"
                            data-name="Path 8196"
                            d="M15.5,22.5l-3.5,1L13,20l7.5-7.5L23,15Z"
                            fill="#d9daee"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                    </g>
                </g>
                <line
                    id="Line_492"
                    data-name="Line 492"
                    x2="2.5"
                    y2="2.5"
                    transform="translate(18.502 14.5)"
                    fill="none"
                    stroke="#7284fa"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <g id="Group_4648" data-name="Group 4648">
                    <g id="Group_4647" data-name="Group 4647" clipPath="url(#clip-path)">
                        <line
                            id="Line_493"
                            data-name="Line 493"
                            x2="2.5"
                            y2="2.5"
                            transform="translate(13 20.002)"
                            fill="none"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const annualWheelIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23.999" viewBox="0 0 24 23.999">
        <defs>
            <clipPath id="clip-path">
                <rect
                    id="Rectangle_13736"
                    data-name="Rectangle 13736"
                    width="24"
                    height="23.999"
                    transform="translate(0 0)"
                    fill="none"
                    stroke="#7284fa"
                    strokeWidth="1"
                />
            </clipPath>
        </defs>
        <g id="Group_7855" data-name="Group 7855" transform="translate(-1721 -139)">
            <path id="Path_8691" data-name="Path 8691" d="M1725.986,160.871v-4.146l5.976.718Z" fill="#d9daee" />
            <circle id="Ellipse_523" data-name="Ellipse 523" cx="6.5" cy="6.5" r="6.5" transform="translate(1731 140)" fill="#d9daee" />
            <g id="Group_7764" data-name="Group 7764" transform="translate(1721 139)">
                <g id="Group_7763" data-name="Group 7763" clipPath="url(#clip-path)">
                    <circle
                        id="Ellipse_521"
                        data-name="Ellipse 521"
                        cx="7"
                        cy="7"
                        r="7"
                        transform="translate(9.5 0.5)"
                        fill="none"
                        stroke="#7284fa"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                    <path
                        id="Path_8685"
                        data-name="Path 8685"
                        d="M16.5,4.5v3l3.5,3"
                        fill="none"
                        stroke="#7284fa"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                    <rect
                        id="Rectangle_13735"
                        data-name="Rectangle 13735"
                        width="4"
                        height="7"
                        transform="translate(0.5 16.499)"
                        fill="#d9daee"
                        stroke="#7284fa"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                    <path
                        id="Path_8686"
                        data-name="Path 8686"
                        d="M4.5,22.5h17c0-1.5-3.5-3-7.5-3H7"
                        fill="#d9daee"
                        stroke="#7284fa"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                    <path
                        id="Path_8687"
                        data-name="Path 8687"
                        d="M4.5,17.5H8a5.19,5.19,0,0,1,4,2"
                        fill="#d9daee"
                        stroke="#7284fa"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const playIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="25.026" viewBox="0 0 20 15.026">
        <g id="Group_7696" data-name="Group 7696" transform="translate(-3354 835.026)">
            <g id="Path_12548" data-name="Path 12548" transform="translate(3366.459 -828.458) rotate(90)" fill="#7284fa">
                <path d="M2.258.15a.331.331,0,0,1,.554,0l2.2,3.37a.331.331,0,0,1-.277.513H.332A.331.331,0,0,1,.055,3.52Z" stroke="none" />
                <path
                    d="M 2.534980535507202 -1.668930053710938e-06 C 2.640915632247925 -1.668930053710938e-06 2.746850490570068 0.0499885082244873 2.812220573425293 0.1499683856964111 L 5.015410423278809 3.519558429718018 C 5.159460544586182 3.739868402481079 5.00139045715332 4.032068252563477 4.738170623779297 4.032068252563477 L 0.3317904472351074 4.032068252563477 C 0.06857061386108398 4.032068252563477 -0.08949947357177734 3.739868402481079 0.0545506477355957 3.519558429718018 L 2.257740497589111 0.1499683856964111 C 2.323110580444336 0.0499885082244873 2.429045438766479 -1.668930053710938e-06 2.534980535507202 -1.668930053710938e-06 Z"
                    stroke="none"
                    fill="#7284fa"
                />
            </g>
            <g id="Group_7692" data-name="Group 7692" transform="translate(3354 -835)">
                <g id="Rectangle_13861" data-name="Rectangle 13861" fill="none" stroke="#7284fa" strokeWidth="1">
                    <rect width="20" height="15" rx="3" stroke="none" />
                    <rect x="0.5" y="0.5" width="19" height="14" rx="2.5" fill="none" />
                </g>
            </g>
            <path
                id="Path_12549"
                data-name="Path 12549"
                d="M3107.92-1168.07H3126.3"
                transform="translate(247.107 336.912)"
                fill="none"
                stroke="#7284fa"
                strokeLinecap="round"
                strokeWidth="1"
            />
            <path
                id="Path_12550"
                data-name="Path 12550"
                d="M3111.792-1171.136l2.813-3.161"
                transform="translate(245.346 339.979)"
                fill="none"
                stroke="#7284fa"
                strokeLinecap="round"
                strokeWidth="1"
            />
            <path
                id="Path_12551"
                data-name="Path 12551"
                d="M3111.792-1171.207l3.146-3.091"
                transform="translate(249.062 339.979)"
                fill="none"
                stroke="#7284fa"
                strokeLinecap="round"
                strokeWidth="1"
            />
            <path
                id="Path_12552"
                data-name="Path 12552"
                d="M3111.791-1170.913l3.432-3.384"
                transform="translate(253.024 339.756)"
                fill="none"
                stroke="#7284fa"
                strokeWidth="1"
            />
            <path
                id="Path_12553"
                data-name="Path 12553"
                d="M3111.792-1171.769l2.956-2.89"
                transform="translate(256.74 340.611)"
                fill="none"
                stroke="#7284fa"
                strokeLinecap="round"
                strokeWidth="1"
            />
        </g>
    </svg>
);

export const notificationIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.722" height="16.851" viewBox="0 0 16.722 16.851">
        <g id="Group_7673" data-name="Group 7673" transform="translate(-5488.092 -8151.277)">
            <g id="Group_7672" data-name="Group 7672" transform="translate(5485.669 8151.778)">
                <g id="Ellipse_162" data-name="Ellipse 162" transform="translate(8.934 12.937)" fill="#b7c1fb" stroke="#7181f9" strokeWidth="1">
                    <circle cx="1.706" cy="1.706" r="1.706" stroke="none" />
                    <circle cx="1.706" cy="1.706" r="1.206" fill="none" />
                </g>
                <path
                    id="Path_12504"
                    data-name="Path 12504"
                    d="M5508.891,8172.459s.239-.008.667-.035c1.66-.1,7.766-.595,5.622-2.1-2.5-1.757-1.62-1.453-2.186-6.615s-4.307-4.957-4.307-4.957-.209-1.109-1.061-1.072-.967.969-.967.969-3.514-.12-4.234,4.637.454,5.432-2.051,7.038,5.97,2.136,5.97,2.136"
                    transform="translate(-5496.987 -8157.678)"
                    fill="#b9c0ed"
                    stroke="#7181f9"
                    strokeWidth="1"
                />
                <path
                    id="Path_12536"
                    data-name="Path 12536"
                    d="M24.019,6.319a8.1,8.1,0,0,1,.958,3.157c.128,1.4-.347,4.358,1.1,5.056s-1.239,1.364-2.19.329.015-2.022-.42-4.287A31.275,31.275,0,0,0,22.1,5.087C21.566,3.835,23.544,5.322,24.019,6.319Z"
                    transform="translate(-10.209 -2.244)"
                    fill="#d4dafd"
                />
            </g>
        </g>
    </svg>
);

export const newFunctionalityIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="21.25" height="21.702" viewBox="0 0 21.25 21.702">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_13835" data-name="Rectangle 13835" width="21.25" height="21.702" fill="none" />
            </clipPath>
        </defs>
        <g id="Group_7925" data-name="Group 7925" transform="translate(-502 -525.498)">
            <path
                id="Path_8734"
                data-name="Path 8734"
                d="M5.5,0a5.5,5.5,0,0,1,0,11c-3.038,0-.859.031-3.367-4.7S2.462,0,5.5,0Z"
                transform="translate(511 527)"
                fill="#dad9ef"
            />
            <g id="Group_7919" data-name="Group 7919" transform="translate(502 525.498)">
                <g id="Group_7915" data-name="Group 7915" clipPath="url(#clip-path)">
                    <circle
                        id="Ellipse_579"
                        data-name="Ellipse 579"
                        cx="3.165"
                        cy="3.165"
                        r="3.165"
                        transform="translate(2.261 0.452)"
                        fill="#dad9ef"
                        stroke="#7284fa"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                    <path
                        id="Path_8729"
                        data-name="Path 8729"
                        d="M10.447,9.5H.5c0,3.679,1.483,5.825,3.165,6.782V22.16H7.282V16.282C8.962,15.325,10.447,13.179,10.447,9.5Z"
                        transform="translate(-0.048 -0.91)"
                        fill="#dad9ef"
                        stroke="#7284fa"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                    <path
                        id="Path_8730"
                        data-name="Path 8730"
                        d="M6.356,9.5h-.9L5,14.926l.9.9.9-.9Z"
                        transform="translate(-0.479 -0.91)"
                        fill="none"
                        stroke="#7284fa"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                    <path
                        id="Path_8731"
                        data-name="Path 8731"
                        d="M12.1,2.8a5.425,5.425,0,1,1,1.4,9.117"
                        transform="translate(-1.159 -0.144)"
                        fill="#dad9ef"
                        stroke="#7284fa"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                    <path
                        id="Path_8732"
                        data-name="Path 8732"
                        d="M22.086.5,15.857,8.8,13.45,6.389"
                        transform="translate(-1.288 -0.048)"
                        fill="none"
                        stroke="#7284fa"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const newRulesIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.794" height="20.869" viewBox="0 0 20.794 20.869">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_13830" data-name="Rectangle 13830" width="18.794" height="18.869" fill="none" />
            </clipPath>
            <clipPath id="clip-path-2">
                <rect id="Rectangle_13834" data-name="Rectangle 13834" width="20.794" height="20.869" transform="translate(-1 -1)" fill="#dad9ef" />
            </clipPath>
        </defs>
        <g id="Group_7918" data-name="Group 7918" transform="translate(1 1)">
            <g id="Group_7911" data-name="Group 7911">
                <g id="Group_7910" data-name="Group 7910" clipPath="url(#clip-path)">
                    <rect
                        id="Rectangle_13828"
                        data-name="Rectangle 13828"
                        width="4.717"
                        height="18.083"
                        transform="translate(3.538 0.393)"
                        fill="#dad9ef"
                        stroke="#7284fa"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                    <path
                        id="Path_8735"
                        data-name="Path 8735"
                        d="M0,0H4.717V11.793H0Z"
                        transform="translate(8.255 6.683)"
                        fill="#dad9ef"
                        stroke="#7284fa"
                        strokeLinecap="round"
                        strokeWidth="1"
                    />
                </g>
            </g>
            <line
                id="Line_653"
                data-name="Line 653"
                x2="1.572"
                transform="translate(9.828 16.904)"
                fill="none"
                stroke="#7284fa"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <line
                id="Line_654"
                data-name="Line 654"
                y2="10.221"
                transform="translate(5.897 1.966)"
                fill="none"
                stroke="#7284fa"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <rect
                id="Rectangle_13831"
                data-name="Rectangle 13831"
                width="1.572"
                height="3.145"
                transform="translate(5.11 13.759)"
                fill="none"
                stroke="#7284fa"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <line
                id="Line_655"
                data-name="Line 655"
                y2="7.076"
                transform="translate(10.614 8.255)"
                fill="none"
                stroke="#7284fa"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <line
                id="Line_656"
                data-name="Line 656"
                y2="11.007"
                transform="translate(1.966 5.11)"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <g id="Group_7913" data-name="Group 7913">
                <g id="Group_7912" data-name="Group 7912" clipPath="url(#clip-path-2)">
                    <rect
                        id="Rectangle_13832"
                        data-name="Rectangle 13832"
                        width="3.145"
                        height="15.725"
                        transform="translate(0.393 2.752)"
                        fill="#dad9ef"
                        stroke="#7284fa"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                    <rect
                        id="Rectangle_13833"
                        data-name="Rectangle 13833"
                        width="2.358"
                        height="15.725"
                        transform="matrix(0.968, -0.25, 0.25, 0.968, 12.186, 3.251)"
                        fill="#dad9ef"
                        stroke="#7284fa"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const notificationTaskIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_13827" data-name="Rectangle 13827" width="24" height="24" fill="#dad9ef" stroke="#7284fa" strokeWidth="1" />
            </clipPath>
        </defs>
        <g id="Group_7920" data-name="Group 7920" transform="translate(-497 -191.465)">
            <rect id="Rectangle_13836" data-name="Rectangle 13836" width="12" height="17" transform="translate(501 196)" fill="#dad9ef" />
            <g id="Group_7917" data-name="Group 7917" transform="translate(497 191.465)">
                <rect id="Rectangle_13838" data-name="Rectangle 13838" width="2" height="4" transform="translate(0.5 4.535) rotate(-90)" fill="#dad9ef" />
                <path id="Path_8733" data-name="Path 8733" d="M-.338,0H3V7.273L.23,9.469Z" transform="translate(15 2.535)" fill="#dad9ef" />
                <g id="Group_7908" data-name="Group 7908" clipPath="url(#clip-path)">
                    <circle
                        id="Ellipse_578"
                        data-name="Ellipse 578"
                        cx="6"
                        cy="6"
                        r="6"
                        transform="translate(11.5 11.5)"
                        fill="#dad9ef"
                        stroke="#7284fa"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                    <line
                        id="Line_647"
                        data-name="Line 647"
                        x2="5"
                        transform="translate(15 17.5)"
                        fill="none"
                        stroke="#7284fa"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                    <line
                        id="Line_648"
                        data-name="Line 648"
                        y1="5"
                        transform="translate(17.5 15)"
                        fill="none"
                        stroke="#7284fa"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                    <path
                        id="Path_8724"
                        data-name="Path 8724"
                        d="M11,23.5H.5V2.5h2"
                        fill="#dad9ef"
                        stroke="#7284fa"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                    <path
                        id="Path_8725"
                        data-name="Path 8725"
                        d="M16.5,2.5h2v7"
                        fill="#dad9ef"
                        stroke="#7284fa"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                    <path
                        id="Path_8726"
                        data-name="Path 8726"
                        d="M11.5,2.5a2,2,0,0,0-4,0h-3v3h10v-3Z"
                        fill="#dad9ef"
                        stroke="#7284fa"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                    <path
                        id="Path_8727"
                        data-name="Path 8727"
                        d="M16.5,9.5v-5h-2"
                        fill="#dad9ef"
                        stroke="#7284fa"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                    <path
                        id="Path_8728"
                        data-name="Path 8728"
                        d="M4.5,4.5h-2v17h8"
                        fill="#dad9ef"
                        stroke="#7284fa"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                    <line
                        id="Line_649"
                        data-name="Line 649"
                        x2="8"
                        transform="translate(5.5 9.5)"
                        fill="none"
                        stroke="#7284fa"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                    <line
                        id="Line_650"
                        data-name="Line 650"
                        x2="6.5"
                        transform="translate(5.5 11.5)"
                        fill="none"
                        stroke="#7284fa"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                    <line
                        id="Line_651"
                        data-name="Line 651"
                        x2="5"
                        transform="translate(5.5 13.5)"
                        fill="none"
                        stroke="#7284fa"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                    <line
                        id="Line_652"
                        data-name="Line 652"
                        x2="4.5"
                        transform="translate(5.5 15.5)"
                        fill="none"
                        stroke="#7284fa"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const policiesIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21">
        <defs>
            <clipPath id="clip-path">
                <rect
                    id="Rectangle_13738"
                    data-name="Rectangle 13738"
                    width="19"
                    height="21"
                    transform="translate(0 -0.273)"
                    fill="#dad9ef"
                    stroke="#7284fa"
                    strokeWidth="1"
                />
            </clipPath>
            <clipPath id="clip-path-2">
                <rect id="Rectangle_13740" data-name="Rectangle 13740" width="6" height="7" fill="none" stroke="#7284fa" strokeWidth="1" />
            </clipPath>
        </defs>
        <g id="Group_8530" data-name="Group 8530" transform="translate(-36 -448)">
            <g id="Group_8527" data-name="Group 8527" transform="translate(0 1)">
                <rect id="Rectangle_13739" data-name="Rectangle 13739" width="17" height="19" transform="translate(36 448)" fill="#dbd8f0" />
                <g id="Group_7834" data-name="Group 7834" transform="translate(36 447.273)">
                    <g id="Group_7832" data-name="Group 7832" clipPath="url(#clip-path)">
                        <path
                            id="Path_13032"
                            data-name="Path 13032"
                            d="M5.182,0A5.182,5.182,0,1,1,0,5.182,5.182,5.182,0,0,1,5.182,0Z"
                            transform="translate(8.205 9.932)"
                            fill="#dad9ef"
                            stroke="#7284fa"
                            strokeWidth="1"
                        />
                        <path
                            id="Path_13033"
                            data-name="Path 13033"
                            d="M0,0"
                            transform="translate(13.386 12.523)"
                            fill="none"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeWidth="1"
                        />
                        <path
                            id="Path_13030"
                            data-name="Path 13030"
                            d="M7.409,20.636H2.227A1.728,1.728,0,0,1,.5,18.909V2.5"
                            transform="translate(-0.068 -0.341)"
                            fill="#dad9ef"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                        <path
                            id="Path_13031"
                            data-name="Path 13031"
                            d="M16.909.5H2.227a1.727,1.727,0,0,0,0,3.455H16.909V7.841"
                            transform="translate(-0.068 -0.068)"
                            fill="#dad9ef"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                        <line
                            id="Line_496"
                            data-name="Line 496"
                            x2="13.818"
                            transform="translate(2.159 2.159)"
                            fill="none"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                    </g>
                </g>
                <g id="Group_7836" data-name="Group 7836" transform="translate(46.5 459)">
                    <g id="Group_7835" data-name="Group 7835" clipPath="url(#clip-path-2)">
                        <path
                            id="Path_13034"
                            data-name="Path 13034"
                            d="M5.5,3,3,.5.5,3"
                            fill="none"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                        <line
                            id="Line_497"
                            data-name="Line 497"
                            y1="6"
                            transform="translate(3 0.5)"
                            fill="none"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const newCommentIcon = (
    <svg id="Group_8416" data-name="Group 8416" xmlns="http://www.w3.org/2000/svg" width="22" height="19" viewBox="0 0 22 19">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_14021" data-name="Rectangle 14021" width="22" height="19" fill="#d6daf3" stroke="#659cfc" strokeWidth="1" />
            </clipPath>
        </defs>
        <g id="Group_8414" data-name="Group 8414">
            <g id="Group_8413" data-name="Group 8413" clipPath="url(#clip-path)">
                <path
                    id="Path_9368"
                    data-name="Path 9368"
                    d="M20.974,14.743H10.292L6.731,18.3V14.743H.5V.5H20.974Z"
                    transform="translate(-0.055 -0.055)"
                    fill="#d6daf3"
                    stroke="#659cfc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
            </g>
        </g>
        <line
            id="Line_579"
            data-name="Line 579"
            x2="8.011"
            transform="translate(4.896 4.896)"
            fill="none"
            stroke="#659cfc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
        />
        <line
            id="Line_580"
            data-name="Line 580"
            x2="11.572"
            transform="translate(4.896 7.566)"
            fill="none"
            stroke="#659cfc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
        />
        <line
            id="Line_581"
            data-name="Line 581"
            x2="11.572"
            transform="translate(4.896 10.237)"
            fill="none"
            stroke="#659cfc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
        />
    </svg>
);

export const closeDialogXIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.828" height="14.828" viewBox="0 0 14.828 14.828">
        <g id="Group_967" data-name="Group 967" transform="translate(-1108.086 -210.086)">
            <line
                id="Line_169"
                data-name="Line 169"
                x2="12"
                y2="12"
                transform="translate(1109.5 211.5)"
                fill="none"
                stroke="#183683"
                strokeLinecap="round"
                strokeWidth="2"
            />
            <line
                id="Line_170"
                data-name="Line 170"
                x1="12"
                y2="12"
                transform="translate(1109.5 211.5)"
                fill="none"
                stroke="#183683"
                strokeLinecap="round"
                strokeWidth="2"
            />
        </g>
    </svg>
);

export const processingActivityDraft = (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.84" height="16.808" viewBox="0 0 16.84 16.808">
        <g id="Group_8645" data-name="Group 8645" transform="translate(0.052 0.052)">
            <g id="Group_8644" data-name="Group 8644" transform="translate(0.448 0.448)">
                <g id="Group_8646" data-name="Group 8646">
                    <path
                        id="Path_13574"
                        data-name="Path 13574"
                        d="M13.584,8.653v8.263H.5V2.455h9.641"
                        transform="translate(-0.5 -1.109)"
                        fill="#d5d4ed"
                        stroke="#6779f9"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                    <path
                        id="Path_13575"
                        data-name="Path 13575"
                        d="M11.283,9.8,7.875,11.262,9.336,7.852,16.689.5l1.947,1.947Z"
                        transform="translate(-2.796 -0.5)"
                        fill="#d5d4ed"
                        stroke="#6779f9"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                </g>
            </g>
            <line
                id="Line_596"
                data-name="Line 596"
                x1="1.948"
                y1="1.948"
                transform="translate(13.076 1.712)"
                fill="none"
                stroke="#6779f9"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <line
                id="Line_597"
                data-name="Line 597"
                x1="1.947"
                y1="1.949"
                transform="translate(6.988 7.801)"
                fill="none"
                stroke="#6779f9"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
        </g>
    </svg>
);

export const processingActivityArchived = (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.318" height="13.771" viewBox="0 0 17.318 13.771">
        <g id="Group_8653" data-name="Group 8653" transform="translate(0.145 0.146)">
            <g id="Group_8652" data-name="Group 8652">
                <g id="Group_8654" data-name="Group 8654">
                    <rect
                        id="Rectangle_14445"
                        data-name="Rectangle 14445"
                        width="16.318"
                        height="3.547"
                        transform="translate(0.355 0.355)"
                        fill="#d5d4ed"
                        stroke="#6779f9"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                    <rect
                        id="Rectangle_14446"
                        data-name="Rectangle 14446"
                        width="14.899"
                        height="9.223"
                        transform="translate(1.064 3.902)"
                        fill="#d5d4ed"
                        stroke="#6779f9"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                </g>
            </g>
            <rect
                id="Rectangle_14448"
                data-name="Rectangle 14448"
                width="4.966"
                height="1.419"
                transform="translate(6.031 6.031)"
                fill="#d5d4ed"
                stroke="#6779f9"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
        </g>
    </svg>
);

export const processingActivityAwaitingResearcher = (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.625" height="16.333" viewBox="0 0 11.625 16.333">
        <g id="Group_8648" data-name="Group 8648" transform="translate(0.188 0.167)">
            <g id="Group_8650" data-name="Group 8650" transform="translate(0 -0.001)">
                <path
                    id="Path_13576"
                    data-name="Path 13576"
                    d="M10.625,3.833A4.205,4.205,0,0,1,6.562,8.167,4.2,4.2,0,0,1,2.5,3.833V.5h8.125Z"
                    transform="translate(-0.938 -0.166)"
                    fill="#d5d4ed"
                    stroke="#6779f9"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <path
                    id="Path_13577"
                    data-name="Path 13577"
                    d="M10.625,16.333A4.205,4.205,0,0,0,6.562,12,4.2,4.2,0,0,0,2.5,16.333v3.333h8.125Z"
                    transform="translate(-0.938 -3.999)"
                    fill="#d5d4ed"
                    stroke="#6779f9"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_598"
                    data-name="Line 598"
                    x2="10.625"
                    transform="translate(0.312 0.334)"
                    fill="none"
                    stroke="#6779f9"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_599"
                    data-name="Line 599"
                    x2="10.625"
                    transform="translate(0.312 15.667)"
                    fill="none"
                    stroke="#6779f9"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <path
                    id="Path_13578"
                    data-name="Path 13578"
                    d="M2.5,18.5H4.687l1.875-2,1.875,2h2.187"
                    transform="translate(-0.938 -5.5)"
                    fill="#d5d4ed"
                    stroke="#6779f9"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_600"
                    data-name="Line 600"
                    x2="6.38"
                    transform="translate(2.435 6.334)"
                    fill="none"
                    stroke="#6779f9"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
            </g>
        </g>
    </svg>
);

export const processingActivityDraftSubmitted = (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.569" height="16.569" viewBox="0 0 16.569 16.569">
        <g id="Group_8657" data-name="Group 8657" transform="translate(0.162 0.161)">
            <g id="Group_8656" data-name="Group 8656">
                <g id="Group_8658" data-name="Group 8658">
                    <path
                        id="Path_13579"
                        data-name="Path 13579"
                        d="M10.654,10.654v5.415H.5V.5H16.069V10.654Z"
                        transform="translate(-0.162 -0.162)"
                        fill="#d5d4ed"
                        stroke="#6779f9"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                    <line
                        id="Line_601"
                        data-name="Line 601"
                        x1="5.415"
                        y2="5.415"
                        transform="translate(10.492 10.492)"
                        fill="none"
                        stroke="#6779f9"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                </g>
            </g>
            <path
                id="Path_13580"
                data-name="Path 13580"
                d="M13.785,6,8.031,12.431,6,10.4"
                transform="translate(-1.938 -1.938)"
                fill="#d5d4ed"
                stroke="#6779f9"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
        </g>
    </svg>
);

export const downloadIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.5" height="19.5" viewBox="0 0 19.5 19.5">
        <g id="Group_8692" data-name="Group 8692" transform="translate(-984.75 -2589.75)">
            <path
                id="Path_1070"
                data-name="Path 1070"
                d="M1003.5,2607.718v1.565a2.348,2.348,0,0,1-2.347,2.347H987.847a2.347,2.347,0,0,1-2.347-2.347v-1.565"
                transform="translate(0 -3.13)"
                fill="#b6d5ef"
                stroke="#7284fa"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <line
                id="Line_163"
                data-name="Line 163"
                y1="13.997"
                transform="translate(994.5 2590.5)"
                fill="none"
                stroke="#7284fa"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                id="Path_1071"
                data-name="Path 1071"
                d="M1000.955,2600.608l-5.727,5.727-5.727-5.727"
                transform="translate(-0.727 -1.838)"
                fill="#b6d5ef"
                stroke="#7284fa"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </g>
    </svg>
);

export const removeIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.513" height="14.513" viewBox="0 0 14.513 14.513">
        <g id="Group_2988" data-name="Group 2988" transform="translate(-1653.086 -570.086)">
            <line
                id="Line_404"
                data-name="Line 404"
                x2="11.685"
                y2="11.685"
                transform="translate(1654.5 571.5)"
                fill="none"
                stroke="#ff3f78"
                strokeLinecap="round"
                strokeWidth="2"
            />
            <line
                id="Line_405"
                data-name="Line 405"
                x1="11.685"
                y2="11.685"
                transform="translate(1654.5 571.5)"
                fill="none"
                stroke="#ff3f78"
                strokeLinecap="round"
                strokeWidth="2"
            />
        </g>
    </svg>
);

export const WhiteLockIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.37" height="19.16" viewBox="0 0 14.37 19.16">
        <path
            id="padlock_3064155"
            d="M15.574,7.185h-.6V4.79a4.79,4.79,0,1,0-9.58,0V7.185H4.8A1.8,1.8,0,0,0,3,8.981v8.382a1.8,1.8,0,0,0,1.8,1.8H15.574a1.8,1.8,0,0,0,1.8-1.8V8.981A1.8,1.8,0,0,0,15.574,7.185ZM6.992,4.79a3.193,3.193,0,0,1,6.387,0V7.185H6.992Zm3.992,8.56v1.819a.8.8,0,1,1-1.6,0V13.35a1.6,1.6,0,1,1,1.6,0Z"
            transform="translate(-3)"
            fill="#fff"
        />
    </svg>
);

export const PurpleLockIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.37" height="19.16" viewBox="0 0 14.37 19.16">
        <path
            id="padlock_3064155"
            d="M15.574,7.185h-.6V4.79a4.79,4.79,0,1,0-9.58,0V7.185H4.8A1.8,1.8,0,0,0,3,8.981v8.382a1.8,1.8,0,0,0,1.8,1.8H15.574a1.8,1.8,0,0,0,1.8-1.8V8.981A1.8,1.8,0,0,0,15.574,7.185ZM6.992,4.79a3.193,3.193,0,0,1,6.387,0V7.185H6.992Zm3.992,8.56v1.819a.8.8,0,1,1-1.6,0V13.35a1.6,1.6,0,1,1,1.6,0Z"
            transform="translate(-3)"
            fill="#7284fa"
        />
    </svg>
);

export const purpleWarning = (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.669" height="11.668" viewBox="0 0 11.669 11.668">
        <g id="Group_8773" data-name="Group 8773" transform="translate(-1033.1 -2207.1)">
            <line
                id="Line_231"
                data-name="Line 231"
                y1="3.781"
                transform="translate(1038.934 2211.281)"
                fill="none"
                stroke="#618cf5"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.8"
            />
            <path
                id="Path_1450"
                data-name="Path 1450"
                d="M1044.369,2218.369H1033.5l5.434-10.869Z"
                fill="none"
                stroke="#618cf5"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.8"
            />
            <path
                id="Path_1451"
                data-name="Path 1451"
                d="M1045.2,2226.1a.6.6,0,0,1-.6.6h0a.6.6,0,0,1-.6-.6h0a.6.6,0,0,1,.6-.6h0a.6.6,0,0,1,.6.6Z"
                transform="translate(-5.665 -9.535)"
                fill="#618cf5"
            />
        </g>
    </svg>
);

export const RepetitionIcon = (
    <svg version="1.1" id="Lag_1" xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.3" viewBox="0 0 25.5 19.3">
        <g id="Group_9850" transform="translate(-515.338 -957.719)">
            <g id="Group_9851" transform="translate(-10 1)">
                <circle id="Ellipse_582" fill="#DAD9EF" stroke="#DAD9EF" cx="537.7" cy="966.5" r="9.1" />
                <g id="Group_8791" transform="translate(526.032 957.271)">
                    <g id="Group_8792" transform="translate(0 0)">
                        <path
                            id="Path_13637"
                            stroke="#618CF5"
                            fill="#DAD9EF"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M20.4,7.6c0.7,4.6-2.2,8.9-6.7,10c-2.9,0.7-6-0.2-8.2-2.3"
                        />
                        <path
                            id="Path_13638"
                            stroke="#618CF5"
                            fill="#DAD9EF"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.9,11.7C1.7,6.8,4.6,1.9,9.5,0.6c0,0,0.1,0,0.1,0c3.1-0.7,6.4,0.3,8.6,2.7"
                        />
                        <path id="Path_13636" stroke="#618CF5" fill="#DAD9EF" strokeLinecap="round" strokeLinejoin="round" d="M23.3,11.9l-2.9-4.3l-3.3,4" />
                        <path id="Path_13635" stroke="#618CF5" fill="#DAD9EF" strokeLinecap="round" strokeLinejoin="round" d="M0,7.4l2.9,4.3l3.3-4" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const newestFirstSortIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.281" height="10.75" viewBox="0 0 14.281 10.75">
        <g id="Group_8805" data-name="Group 8805" transform="translate(1.281 -4)">
            <g id="Group_8806" data-name="Group 8806">
                <line
                    id="Line_672"
                    data-name="Line 672"
                    y1="9.75"
                    transform="translate(1.833 4.5)"
                    fill="none"
                    stroke="#6890f5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <path
                    id="Path_13645"
                    data-name="Path 13645"
                    d="M6.315,8,3.908,10.408,1.5,8"
                    transform="translate(-2.074 3.842)"
                    fill="#dad9ef"
                    stroke="#6890f5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_673"
                    data-name="Line 673"
                    x2="1.083"
                    transform="translate(5.417 13.25)"
                    fill="none"
                    stroke="#6890f5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_674"
                    data-name="Line 674"
                    x2="3.083"
                    transform="translate(5.417 9.667)"
                    fill="none"
                    stroke="#6890f5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_675"
                    data-name="Line 675"
                    x2="7.083"
                    transform="translate(5.417 5.583)"
                    fill="none"
                    stroke="#6890f5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
            </g>
        </g>
    </svg>
);

export const oldestFirstSortIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.032" height="10.75" viewBox="0 0 13.032 10.75">
        <g id="Group_9984" data-name="Group 9984" transform="translate(17.948 14.75) rotate(180)">
            <g id="Group_8806" data-name="Group 8806">
                <line
                    id="Line_672"
                    data-name="Line 672"
                    y1="9.75"
                    transform="translate(14.833 4.5)"
                    fill="none"
                    stroke="#6890f5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <path
                    id="Path_13645"
                    data-name="Path 13645"
                    d="M6.315,8,3.908,10.408,1.5,8"
                    transform="translate(10.926 3.842)"
                    fill="#dad9ef"
                    stroke="#6890f5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_673"
                    data-name="Line 673"
                    x2="1.083"
                    transform="translate(5.417 13.25)"
                    fill="none"
                    stroke="#6890f5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_674"
                    data-name="Line 674"
                    x2="3.083"
                    transform="translate(5.417 9.667)"
                    fill="none"
                    stroke="#6890f5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_675"
                    data-name="Line 675"
                    x2="7.083"
                    transform="translate(5.417 5.583)"
                    fill="none"
                    stroke="#6890f5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
            </g>
        </g>
    </svg>
);

export const unPinnedCommentIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.502" height="13.414" viewBox="0 0 13.502 13.414">
        <g id="Group_9983" data-name="Group 9983" transform="translate(-0.793 0.206)">
            <g id="Group_9982" data-name="Group 9982">
                <line
                    id="Line_1044"
                    data-name="Line 1044"
                    x1="4.076"
                    y2="3.989"
                    transform="translate(1.5 8.511)"
                    fill="none"
                    stroke="#9e9e9e"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <path
                    id="Path_25508"
                    data-name="Path 25508"
                    d="M14.685,5.783l1.174-1.174L11.75.5,10.576,1.674l.587.587L8.228,5.2H5.88L5,6.076l5.283,5.283.88-.88V8.131L14.1,5.2Z"
                    transform="translate(-2.065 -0.207)"
                    fill="none"
                    stroke="#9e9e9e"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
            </g>
        </g>
    </svg>
);

export const pinnedCommentIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.502" height="13.414" viewBox="0 0 13.502 13.414">
        <g id="Group_9981" data-name="Group 9981" transform="translate(-0.793 0.207)">
            <g id="Group_9982" data-name="Group 9982">
                <line
                    id="Line_1044"
                    data-name="Line 1044"
                    x1="4.076"
                    y2="3.989"
                    transform="translate(1.5 8.511)"
                    fill="none"
                    stroke="#7284fa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <path
                    id="Path_25508"
                    data-name="Path 25508"
                    d="M14.685,5.783l1.174-1.174L11.75.5,10.576,1.674l.587.587L8.228,5.2H5.88L5,6.076l5.283,5.283.88-.88V8.131L14.1,5.2Z"
                    transform="translate(-2.065 -0.207)"
                    fill="#7284fa"
                    stroke="#7284fa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
            </g>
        </g>
    </svg>
);

export const IncidentLogIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.379" height="20.86" viewBox="0 0 15.379 20.86">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_15167" data-name="Rectangle 15167" width="3.086" height="6.012" fill="#7284fa" />
            </clipPath>
        </defs>
        <g id="Group_10084" data-name="Group 10084" transform="translate(-55.5 -462.548)">
            <g id="Group_10077" data-name="Group 10077" transform="translate(56 466.313)">
                <g id="Group_10078" data-name="Group 10078">
                    <path
                        id="Path_25535"
                        data-name="Path 25535"
                        d="M13.535,7.237H3.374V5.19A3.829,3.829,0,0,1,7.19,1.374H7.2a3.829,3.829,0,0,1,3.8,3.816c0,.272.34.19.612.19h.389c.272,0,.373.082.373-.19A5.2,5.2,0,0,0,7.2,0H7.19A5.2,5.2,0,0,0,2,5.19V7.237H.844A.847.847,0,0,0,0,8.082V18.568A1.292,1.292,0,0,0,1.292,19.86h11.8a1.292,1.292,0,0,0,1.292-1.292V8.082a.847.847,0,0,0-.844-.844"
                        transform="translate(0 -3.265)"
                        fill="#dad9ef"
                        stroke="#7284fa"
                        strokeWidth="1"
                    />
                </g>
            </g>
            <g id="Group_10080" data-name="Group 10080" transform="translate(62.858 470.019)">
                <g id="Group_10083" data-name="Group 10083" transform="translate(-0.858 3.348)">
                    <g id="Group_10082" data-name="Group 10082">
                        <g id="Group_10081" data-name="Group 10081" clipPath="url(#clip-path)">
                            <path
                                id="Path_25537"
                                data-name="Path 25537"
                                d="M3.069,5.487,2.374,3.156a.41.41,0,0,1,.137-.436,1.519,1.519,0,0,0,.568-1.2,1.536,1.536,0,0,0-3.072.019A1.52,1.52,0,0,0,.575,2.72a.407.407,0,0,1,.137.433l-.7,2.334a.408.408,0,0,0,.391.525H2.677a.408.408,0,0,0,.391-.525"
                                transform="translate(0 0)"
                                fill="#7284fa"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const excludedIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.329" height="14.329" viewBox="0 0 14.329 14.329">
        <g id="Group_8899" data-name="Group 8899" transform="translate(-0.34 -0.34)">
            <g id="Group_8900" data-name="Group 8900" transform="translate(0.34 0.34)">
                <circle id="Ellipse_589" data-name="Ellipse 589" cx="7.164" cy="7.164" r="7.164" fill="#f7447a" />
                <path
                    id="Path_13708"
                    data-name="Path 13708"
                    d="M9.109,4.333a4.775,4.775,0,0,0-3.95,7.46L11.794,5.16A4.751,4.751,0,0,0,9.109,4.333Z"
                    transform="translate(-1.945 -1.945)"
                    fill="#fff"
                />
                <path
                    id="Path_13709"
                    data-name="Path 13709"
                    d="M7.691,14.326a4.775,4.775,0,0,0,6.634-6.634Z"
                    transform="translate(-3.211 -3.211)"
                    fill="#fff"
                />
            </g>
        </g>
    </svg>
);

export const inProgressTaskPaperIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="28.023" height="24.338" viewBox="0 0 28.023 24.338">
        <defs>
            <clipPath id="clip-path">
                <rect
                    id="Rectangle_15048"
                    data-name="Rectangle 15048"
                    width="28.023"
                    height="24.338"
                    transform="translate(0 0)"
                    fill="none"
                    stroke="#7284fa"
                    strokeWidth="1"
                />
            </clipPath>
        </defs>
        <g id="Group_10064" data-name="Group 10064" transform="translate(-1390.099 86.272)">
            <circle id="Ellipse_796" data-name="Ellipse 796" cx="12" cy="12" r="12" transform="translate(1394 -86)" fill="#d9daee" />
            <g id="Group_10062" data-name="Group 10062" transform="translate(3 32)">
                <g id="Group_10050" data-name="Group 10050" transform="translate(1387.099 -118.271)">
                    <g id="Group_9769" data-name="Group 9769">
                        <path
                            id="Path_25313"
                            data-name="Path 25313"
                            d="M.5,8.477l3.685,5.529,4.3-4.914"
                            fill="none"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                        <path
                            id="Path_25314"
                            data-name="Path 25314"
                            d="M15.856,23.838A11.669,11.669,0,1,0,4.185,12.17v1.836"
                            fill="none"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                    </g>
                </g>
                <g id="Group_10056" data-name="Group 10056" transform="translate(1386.109 -120.102)">
                    <g id="Group_10053" data-name="Group 10053" transform="translate(1 -4)">
                        <g id="Group_10058" data-name="Group 10058">
                            <path
                                id="Path_25528"
                                data-name="Path 25528"
                                d="M14,22.5l-3.5,1,1-3.5L19,12.5,21.5,15Z"
                                fill="none"
                                stroke="#7284fa"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1"
                            />
                        </g>
                    </g>
                    <line
                        id="Line_1055"
                        data-name="Line 1055"
                        x2="2.5"
                        y2="2.5"
                        transform="translate(18.002 10.499)"
                        fill="none"
                        stroke="#7284fa"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                    <g id="Group_10055" data-name="Group 10055" transform="translate(1 -4)">
                        <g id="Group_10057" data-name="Group 10057">
                            <line
                                id="Line_1056"
                                data-name="Line 1056"
                                x2="2.5"
                                y2="2.5"
                                transform="translate(11.5 20.001)"
                                fill="none"
                                stroke="#7284fa"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const overdueTaskPaperIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <defs>
            <clipPath id="clip-path">
                <rect
                    id="Rectangle_15160"
                    data-name="Rectangle 15160"
                    width="20"
                    height="20"
                    transform="translate(-0.294 -0.268)"
                    fill="#d9daee"
                    stroke="#7284fa"
                    strokeWidth="1"
                />
            </clipPath>
            <clipPath id="clip-path-2">
                <rect
                    id="Rectangle_15162"
                    data-name="Rectangle 15162"
                    width="13"
                    height="13"
                    transform="translate(-0.174 -0.174)"
                    fill="#d9daee"
                    stroke="#7284fa"
                    strokeWidth="1"
                />
            </clipPath>
        </defs>
        <g id="Group_10075" data-name="Group 10075" transform="translate(-239 -194)">
            <g id="Ellipse_798" data-name="Ellipse 798" transform="translate(239 194)" fill="#d9daee" stroke="#7284fa" strokeWidth="1">
                <circle cx="12" cy="12" r="12" stroke="none" />
                <circle cx="12" cy="12" r="11.5" fill="none" />
            </g>
            <g id="Group_10067" data-name="Group 10067" transform="translate(241.294 196.268)">
                <g id="Group_10066" data-name="Group 10066" transform="translate(0 0)">
                    <g id="Group_10065" data-name="Group 10065" transform="translate(0 0)">
                        <ellipse
                            id="Ellipse_797"
                            data-name="Ellipse 797"
                            cx="9.576"
                            cy="9.576"
                            rx="9.576"
                            ry="9.576"
                            transform="translate(0.164 0.164)"
                            fill="#d9daee"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                        <path
                            id="Path_25531"
                            data-name="Path 25531"
                            d="M16.5,6.5v4.58l-5,4.58"
                            transform="translate(-6.34 -1.34)"
                            fill="#d9daee"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                    </g>
                </g>
            </g>
            <g id="Group_10068" data-name="Group 10068" transform="translate(250.174 205.174)">
                <g id="Group_10072" data-name="Group 10072">
                    <g id="Group_10071" data-name="Group 10071">
                        <path
                            id="Path_25533"
                            data-name="Path 25533"
                            d="M.5,12.339H12.339L6.42.5Z"
                            transform="translate(-0.007 -0.007)"
                            fill="#d9daee"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                    </g>
                </g>
                <line
                    id="Line_1058"
                    data-name="Line 1058"
                    y2="3.453"
                    transform="translate(6.413 5.426)"
                    fill="none"
                    stroke="#7284fa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <g id="Group_10074" data-name="Group 10074">
                    <g id="Group_10073" data-name="Group 10073">
                        <path
                            id="Path_25534"
                            data-name="Path 25534"
                            d="M6.493,10a.493.493,0,1,0,.493.493A.493.493,0,0,0,6.493,10"
                            transform="translate(-0.08 -0.134)"
                            fill="#7284fa"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const completedTaskPaperIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21.75" viewBox="0 0 22 21.75">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_15158" data-name="Rectangle 15158" width="22" height="21.75" fill="none" stroke="#7284fa" strokeWidth="1" />
            </clipPath>
        </defs>
        <g id="Group_10063" data-name="Group 10063" transform="translate(-1392 45)">
            <rect id="Rectangle_15159" data-name="Rectangle 15159" width="18" height="18" rx="1" transform="translate(1392.5 -42)" fill="#d9daee" />
            <g id="Group_10061" data-name="Group 10061" transform="translate(1 -3)">
                <g id="Group_10060" data-name="Group 10060" transform="translate(1391 -42)">
                    <g id="Group_10059" data-name="Group 10059">
                        <path
                            id="Path_25529"
                            data-name="Path 25529"
                            d="M21.5.5,9,16,4,11"
                            fill="none"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                        <path
                            id="Path_25530"
                            data-name="Path 25530"
                            d="M18.5,9.75v11.5H.5v-18H13"
                            fill="none"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const closed = (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g id="Group_10317" data-name="Group 10317" transform="translate(-1301 -794)">
            <g id="Ellipse_582" data-name="Ellipse 582" transform="translate(1301 794)" fill="#dedede" stroke="#c4c4c4" strokeWidth="1">
                <circle cx="10" cy="10" r="10" stroke="none" />
                <circle cx="10" cy="10" r="9.5" fill="none" />
            </g>
            <line
                id="Line_553"
                data-name="Line 553"
                x2="8"
                y2="8"
                transform="translate(1307 800)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeWidth="2"
            />
            <line
                id="Line_554"
                data-name="Line 554"
                x2="8"
                y2="8"
                transform="translate(1315 800) rotate(90)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeWidth="2"
            />
        </g>
    </svg>
);

export const auditInProgress = (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g id="Group_10321" data-name="Group 10321" transform="translate(-1189 -638)">
            <g id="Group_10315" data-name="Group 10315" transform="translate(-112 -156)">
                <g id="Ellipse_582" data-name="Ellipse 582" transform="translate(1301 794)" fill="#fff79f" stroke="#e1da8c" strokeWidth="1">
                    <circle cx="10" cy="10" r="10" stroke="none" />
                    <circle cx="10" cy="10" r="9.5" fill="none" />
                </g>
            </g>
            <g id="Group_10316" data-name="Group 10316" transform="translate(0 0.5)">
                <line
                    id="Line_551"
                    data-name="Line 551"
                    y2="7"
                    transform="translate(1199 641)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="2"
                />
                <line
                    id="Line_552"
                    data-name="Line 552"
                    x2="3.5"
                    y2="3"
                    transform="translate(1199 648)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="2"
                />
            </g>
        </g>
    </svg>
);

export const blueGreyCheckmark = (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g id="Group_10318" data-name="Group 10318" transform="translate(-1301 -794)">
            <g id="Ellipse_582" data-name="Ellipse 582" transform="translate(1301 794)" fill="#b0d9fc" stroke="#9cc0df" strokeWidth="1">
                <circle cx="10" cy="10" r="10" stroke="none" />
                <circle cx="10" cy="10" r="9.5" fill="none" />
            </g>
            <g id="Group_8681" data-name="Group 8681" transform="translate(1305.102 799.5)">
                <path
                    id="Path_1240"
                    data-name="Path 1240"
                    d="M10.91,68.183a.507.507,0,0,0-.783,0L3.494,75.755l-2.55-2.91a.507.507,0,0,0-.783,0,.7.7,0,0,0,0,.894L3.1,77.1a.507.507,0,0,0,.783,0l7.024-8.019A.7.7,0,0,0,10.91,68.183Z"
                    transform="translate(0 -67.998)"
                    fill="#fff"
                    stroke="#fff"
                    strokeWidth="1"
                />
            </g>
        </g>
    </svg>
);

export const greenCheckmark = (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g id="Group_10318" data-name="Group 10318" transform="translate(-1301 -794)">
            <g id="Ellipse_582" data-name="Ellipse 582" transform="translate(1301 794)" fill="#2cd3c4" stroke="#26bbad" strokeWidth="1">
                <circle cx="10" cy="10" r="10" stroke="none" />
                <circle cx="10" cy="10" r="9.5" fill="none" />
            </g>
            <g id="Group_8681" data-name="Group 8681" transform="translate(1305.102 799.5)">
                <path
                    id="Path_1240"
                    data-name="Path 1240"
                    d="M10.91,68.183a.507.507,0,0,0-.783,0L3.494,75.755l-2.55-2.91a.507.507,0,0,0-.783,0,.7.7,0,0,0,0,.894L3.1,77.1a.507.507,0,0,0,.783,0l7.024-8.019A.7.7,0,0,0,10.91,68.183Z"
                    transform="translate(0 -67.998)"
                    fill="#fff"
                    stroke="#fff"
                    strokeWidth="1"
                />
            </g>
        </g>
    </svg>
);

export const internalAssessments = (
    <svg id="Group_10337" data-name="Group 10337" xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_15193" data-name="Rectangle 15193" width="20" height="22" fill="#dad9ef" stroke="#7284fa" strokeWidth="1" />
            </clipPath>
        </defs>
        <g id="Group_10336" data-name="Group 10336">
            <g id="Group_10335" data-name="Group 10335">
                <path
                    id="Path_25599"
                    data-name="Path 25599"
                    d="M14.136,16.136v5.455H.5V2.5H19.591V16.136Z"
                    transform="translate(-0.045 -0.227)"
                    fill="#dad9ef"
                    stroke="#7284fa"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_555"
                    data-name="Line 555"
                    x1="5.455"
                    y2="5.455"
                    transform="translate(14.091 15.909)"
                    fill="none"
                    stroke="#7284fa"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_556"
                    data-name="Line 556"
                    y2="4.091"
                    transform="translate(3.182 0.455)"
                    fill="none"
                    stroke="#7284fa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_557"
                    data-name="Line 557"
                    y2="4.091"
                    transform="translate(7.727 0.455)"
                    fill="none"
                    stroke="#7284fa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_558"
                    data-name="Line 558"
                    y2="4.091"
                    transform="translate(12.273 0.455)"
                    fill="none"
                    stroke="#7284fa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_559"
                    data-name="Line 559"
                    y2="4.091"
                    transform="translate(16.818 0.455)"
                    fill="none"
                    stroke="#7284fa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
            </g>
        </g>
        <path
            id="Path_25600"
            data-name="Path 25600"
            d="M8.091,7.5,5.364,10.227,4,8.864"
            transform="translate(-0.364 -0.682)"
            fill="#dad9ef"
            stroke="#7284fa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
        />
        <path
            id="Path_25601"
            data-name="Path 25601"
            d="M8.091,13,5.364,15.727,4,14.364"
            transform="translate(-0.364 -1.182)"
            fill="#dad9ef"
            stroke="#7284fa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
        />
        <line
            id="Line_560"
            data-name="Line 560"
            x2="5.909"
            transform="translate(9.545 8.636)"
            fill="none"
            stroke="#7284fa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
        />
        <line
            id="Line_561"
            data-name="Line 561"
            x2="5.909"
            transform="translate(9.545 13.182)"
            fill="none"
            stroke="#7284fa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
        />
    </svg>
);

export const declarationsIcon = (
    <svg id="Group_10187" data-name="Group 10187" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_14584" data-name="Rectangle 14584" width="22" height="22" fill="#dad9ef" stroke="#7284fa" strokeWidth="1" />
            </clipPath>
        </defs>
        <g id="Group_10180" data-name="Group 10180">
            <g id="Group_10179" data-name="Group 10179">
                <rect
                    id="Rectangle_14583"
                    data-name="Rectangle 14583"
                    width="6.808"
                    height="20.878"
                    transform="translate(0.454 0.454)"
                    fill="#dad9ef"
                    stroke="#7284fa"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
            </g>
        </g>
        <rect
            id="Rectangle_14585"
            data-name="Rectangle 14585"
            width="2.723"
            height="10.893"
            transform="translate(2.269 2.269)"
            fill="#dad9ef"
            stroke="#7284fa"
            strokeLinejoin="round"
            strokeWidth="1"
        />
        <line
            id="Line_501"
            data-name="Line 501"
            x2="2.723"
            transform="translate(2.269 4.993)"
            fill="none"
            stroke="#7284fa"
            strokeLinejoin="round"
            strokeWidth="1"
        />
        <g id="Group_10182" data-name="Group 10182">
            <g id="Group_10181" data-name="Group 10181">
                <rect
                    id="Rectangle_14586"
                    data-name="Rectangle 14586"
                    width="7.262"
                    height="20.878"
                    transform="translate(7.262 0.454)"
                    fill="#dad9ef"
                    stroke="#7284fa"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
            </g>
        </g>
        <rect
            id="Rectangle_14588"
            data-name="Rectangle 14588"
            width="2.723"
            height="10.893"
            transform="translate(9.531 2.269)"
            fill="#dad9ef"
            stroke="#7284fa"
            strokeLinejoin="round"
            strokeWidth="1"
        />
        <line
            id="Line_502"
            data-name="Line 502"
            x2="2.723"
            transform="translate(9.531 4.993)"
            fill="none"
            stroke="#7284fa"
            strokeLinejoin="round"
            strokeWidth="1"
        />
        <g id="Group_10184" data-name="Group 10184">
            <g id="Group_10183" data-name="Group 10183">
                <rect
                    id="Rectangle_14589"
                    data-name="Rectangle 14589"
                    width="6.808"
                    height="20.878"
                    transform="translate(14.524 0.454)"
                    fill="#dad9ef"
                    stroke="#7284fa"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
            </g>
        </g>
        <rect
            id="Rectangle_14591"
            data-name="Rectangle 14591"
            width="2.723"
            height="10.893"
            transform="translate(16.793 2.269)"
            fill="#dad9ef"
            stroke="#7284fa"
            strokeLinejoin="round"
            strokeWidth="1"
        />
        <line
            id="Line_503"
            data-name="Line 503"
            x2="2.723"
            transform="translate(16.793 4.993)"
            fill="none"
            stroke="#7284fa"
            strokeLinejoin="round"
            strokeWidth="1"
        />
        <g id="Group_10186" data-name="Group 10186">
            <g id="Group_10185" data-name="Group 10185">
                <circle
                    id="Ellipse_531"
                    data-name="Ellipse 531"
                    cx="1.362"
                    cy="1.362"
                    r="1.362"
                    transform="translate(2.269 15.885)"
                    fill="#dad9ef"
                    stroke="#7284fa"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <circle
                    id="Ellipse_532"
                    data-name="Ellipse 532"
                    cx="1.362"
                    cy="1.362"
                    r="1.362"
                    transform="translate(9.531 15.885)"
                    fill="#dad9ef"
                    stroke="#7284fa"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <circle
                    id="Ellipse_533"
                    data-name="Ellipse 533"
                    cx="1.362"
                    cy="1.362"
                    r="1.362"
                    transform="translate(16.793 15.885)"
                    fill="#dad9ef"
                    stroke="#7284fa"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
            </g>
        </g>
    </svg>
);

export const closedBlue = (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g id="Group_10320" data-name="Group 10320" transform="translate(-1301 -794)">
            <g id="Ellipse_582" data-name="Ellipse 582" transform="translate(1301 794)" fill="#b0d9fc" stroke="#9cc0df" strokeWidth="1">
                <circle cx="10" cy="10" r="10" stroke="none" />
                <circle cx="10" cy="10" r="9.5" fill="none" />
            </g>
            <line
                id="Line_553"
                data-name="Line 553"
                x2="8"
                y2="8"
                transform="translate(1307 800)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeWidth="2"
            />
            <line
                id="Line_554"
                data-name="Line 554"
                x2="8"
                y2="8"
                transform="translate(1315 800) rotate(90)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeWidth="2"
            />
        </g>
    </svg>
);

export const notSatisfied = (
    <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47">
        <g id="Group_14205" data-name="Group 14205" transform="translate(-584.73 -387.224)">
            <path
                id="Path_25906"
                data-name="Path 25906"
                d="M32.409,74.652a11.48,11.48,0,0,1,21.065,0"
                transform="translate(565.288 345.195)"
                fill="none"
                stroke="#f7447a"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
            <path
                id="Path_26010"
                data-name="Path 26010"
                d="M47.125,26.147a22.5,22.5,0,1,1-22.5-22.5A22.5,22.5,0,0,1,47.125,26.147Z"
                transform="translate(583.605 384.577)"
                fill="rgba(247,68,122,0.1)"
                stroke="#f7447a"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
            <path
                id="Path_26011"
                data-name="Path 26011"
                d="M29.2,45.442a1.921,1.921,0,1,1,1.921,1.921A1.921,1.921,0,0,1,29.2,45.442"
                transform="translate(566.936 360.028)"
                fill="#f7447a"
            />
            <path
                id="Path_26012"
                data-name="Path 26012"
                d="M82.138,45.442a1.921,1.921,0,1,1,1.921,1.921,1.921,1.921,0,0,1-1.921-1.921"
                transform="translate(534.345 360.028)"
                fill="#f7447a"
            />
        </g>
    </svg>
);

export const partlySatisfied = (
    <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47">
        <g id="Group_14206" data-name="Group 14206" transform="translate(-871.668 -387.224)">
            <path
                id="Path_25903"
                data-name="Path 25903"
                d="M47.125,26.147a22.5,22.5,0,1,1-22.5-22.5A22.5,22.5,0,0,1,47.125,26.147Z"
                transform="translate(870.543 384.577)"
                fill="rgba(255,147,0,0.15)"
                stroke="#ffc400"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
            <path
                id="Path_25904"
                data-name="Path 25904"
                d="M29.2,45.441a1.92,1.92,0,1,1,1.92,1.92,1.92,1.92,0,0,1-1.92-1.92"
                transform="translate(853.434 360.364)"
                fill="#ffc400"
            />
            <path
                id="Path_25905"
                data-name="Path 25905"
                d="M82.138,45.441a1.92,1.92,0,1,1,1.92,1.92,1.92,1.92,0,0,1-1.92-1.92"
                transform="translate(821.289 360.364)"
                fill="#ffc400"
            />
            <path
                id="Path_26016"
                data-name="Path 26016"
                d="M0,0H25"
                transform="translate(882.668 416.354)"
                fill="none"
                stroke="#ffc400"
                strokeLinecap="round"
                strokeWidth="2"
            />
        </g>
    </svg>
);

export const satisfied = (
    <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47">
        <g id="Group_14207" data-name="Group 14207" transform="translate(-1157 -387.224)">
            <path
                id="Path_26013"
                data-name="Path 26013"
                d="M47.125,26.147a22.5,22.5,0,1,1-22.5-22.5A22.5,22.5,0,0,1,47.125,26.147Z"
                transform="translate(1155.875 384.577)"
                fill="rgba(44,213,197,0.1)"
                stroke="#2cd5c4"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
            <path
                id="Path_26014"
                data-name="Path 26014"
                d="M29.2,45.441a1.92,1.92,0,1,1,1.92,1.92,1.92,1.92,0,0,1-1.92-1.92"
                transform="translate(1139.434 360.364)"
                fill="#2cd5c4"
            />
            <path
                id="Path_26015"
                data-name="Path 26015"
                d="M82.138,45.441a1.92,1.92,0,1,1,1.92,1.92,1.92,1.92,0,0,1-1.92-1.92"
                transform="translate(1107.289 360.364)"
                fill="#2cd5c4"
            />
            <g id="Group_14204" data-name="Group 14204" transform="translate(1171.041 416.175)">
                <path
                    id="Path_25902"
                    data-name="Path 25902"
                    d="M252.22,72.208a8.915,8.915,0,0,1-17.023,0"
                    transform="translate(-234.249 -71.82)"
                    fill="none"
                    stroke="#2cd5c4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <line
                    id="Line_681"
                    data-name="Line 681"
                    x1="1.623"
                    y2="0.777"
                    fill="none"
                    stroke="#2cd5c4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <line
                    id="Line_682"
                    data-name="Line 682"
                    x2="1.623"
                    y2="0.777"
                    transform="translate(17.294)"
                    fill="none"
                    stroke="#2cd5c4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
            </g>
        </g>
    </svg>
);

export const samplingIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="Group_14188" data-name="Group 14188" transform="translate(-1494.995 -370.995)">
            <g id="Group_14184" data-name="Group 14184" transform="translate(-7)">
                <g id="Group_14179" data-name="Group 14179" transform="translate(1502 371)">
                    <g id="Group_14185" data-name="Group 14185">
                        <circle
                            id="Ellipse_583"
                            data-name="Ellipse 583"
                            cx="7.928"
                            cy="7.928"
                            r="7.928"
                            transform="translate(0.495 0.495)"
                            fill="#dad9ef"
                            stroke="#7284fa"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                        <line
                            id="Line_614"
                            data-name="Line 614"
                            x2="9.26"
                            y2="9.26"
                            transform="translate(14.028 14.028)"
                            fill="none"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                    </g>
                </g>
            </g>
            <g id="Group_14187" data-name="Group 14187" transform="translate(-7)">
                <g id="Path_39126" data-name="Path 39126" transform="translate(1506 375)" fill="#dad9ef">
                    <path d="M 3.5 3.5 L 0.5 3.5 L 0.5 0.5 L 3.5 0.5 L 3.5 3.5 Z" stroke="none" />
                    <path d="M 1 1 L 1 3 L 3 3 L 3 1 L 1 1 M 0 0 L 4 0 L 4 4 L 0 4 L 0 0 Z" stroke="none" fill="#7284fa" />
                </g>
                <g id="Rectangle_16842" data-name="Rectangle 16842" transform="translate(1511 380)" fill="#dad9ef" stroke="#7284fa" strokeWidth="1">
                    <rect width="4" height="4" stroke="none" />
                    <rect x="0.5" y="0.5" width="3" height="3" fill="none" />
                </g>
                <g id="Rectangle_16843" data-name="Rectangle 16843" transform="translate(1511 375)" fill="#dad9ef" stroke="#7284fa" strokeWidth="1">
                    <rect width="4" height="4" rx="2" stroke="none" />
                    <rect x="0.5" y="0.5" width="3" height="3" rx="1.5" fill="none" />
                </g>
                <g id="Rectangle_16844" data-name="Rectangle 16844" transform="translate(1506 380)" fill="#dad9ef" stroke="#7284fa" strokeWidth="1">
                    <rect width="4" height="4" rx="2" stroke="none" />
                    <rect x="0.5" y="0.5" width="3" height="3" rx="1.5" fill="none" />
                </g>
            </g>
        </g>
    </svg>
);

export const dashboardMenuIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g id="Group_1181" data-name="Group 1181" transform="translate(-744 -1054)">
            <rect
                id="Rectangle_97"
                data-name="Rectangle 97"
                width="8"
                height="8"
                transform="translate(744.5 1054.5)"
                fill="#dad9ef"
                stroke="#7284fa"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <rect
                id="Rectangle_98"
                data-name="Rectangle 98"
                width="8"
                height="8"
                transform="translate(755.5 1054.5)"
                fill="#dad9ef"
                stroke="#7284fa"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <rect
                id="Rectangle_99"
                data-name="Rectangle 99"
                width="8"
                height="8"
                transform="translate(744.5 1065.5)"
                fill="#dad9ef"
                stroke="#7284fa"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <rect
                id="Rectangle_100"
                data-name="Rectangle 100"
                width="8"
                height="8"
                transform="translate(755.5 1065.5)"
                fill="#dad9ef"
                stroke="#7284fa"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
        </g>
    </svg>
);

export const compliancePlatformLogo = (
    <svg id="Group_14285" data-name="Group 14285" xmlns="http://www.w3.org/2000/svg" width="523.147" height="125" viewBox="0 0 523.147 115">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_17486" data-name="Rectangle 17486" width="523.147" height="115" fill="none" />
            </clipPath>
        </defs>
        <rect id="Rectangle_17484" data-name="Rectangle 17484" width="15.908" height="83.482" transform="translate(48.141)" fill="#040405" />
        <g id="Group_14284" data-name="Group 14284">
            <g id="Group_14283" data-name="Group 14283" clipPath="url(#clip-path)">
                <path
                    id="Path_39385"
                    data-name="Path 39385"
                    d="M141.438,55.278h-45.8q.339,8.351,4.512,12.3a14.517,14.517,0,0,0,10.379,3.947,15.059,15.059,0,0,0,9.194-2.707,11.987,11.987,0,0,0,4.682-7.333h16.81a26.9,26.9,0,0,1-5.3,11.733,27.7,27.7,0,0,1-10.435,8.122,35.751,35.751,0,0,1-30.4-.959A27.354,27.354,0,0,1,84.016,69.268a34.96,34.96,0,0,1-3.948-17.036,35.286,35.286,0,0,1,3.948-17.091A26.916,26.916,0,0,1,95.072,24.029a33.673,33.673,0,0,1,16.245-3.836,32.943,32.943,0,0,1,16.189,3.836,27.057,27.057,0,0,1,10.717,10.548A30.622,30.622,0,0,1,142,49.863a30.127,30.127,0,0,1-.565,5.415m-19.46-18.614a15.276,15.276,0,0,0-10.661-3.948,15.63,15.63,0,0,0-10.83,3.948q-4.4,3.951-4.851,11.621h30.347q.337-7.673-4.005-11.621"
                    fill="#040405"
                />
                <path
                    id="Path_39386"
                    data-name="Path 39386"
                    d="M196.039,23.917a20.279,20.279,0,0,1,8.01,10.04V20.984h15.794V83.708A34.7,34.7,0,0,1,216.4,99.333a25.381,25.381,0,0,1-10.266,10.886q-6.828,3.948-16.527,3.949-13.539,0-21.886-6.431A26.594,26.594,0,0,1,157.57,90.251h15.681A12.931,12.931,0,0,0,178.5,97.64a17.379,17.379,0,0,0,9.984,2.652q6.995,0,11.282-4.118t4.287-12.466V70.4a20.569,20.569,0,0,1-8.01,10.1A23.754,23.754,0,0,1,182.5,84.272a26.834,26.834,0,0,1-14.214-3.836,25.892,25.892,0,0,1-9.871-11.112,38.523,38.523,0,0,1-3.554-17.091,38.523,38.523,0,0,1,3.554-17.091,25.912,25.912,0,0,1,9.871-11.113A26.861,26.861,0,0,1,182.5,20.194a24.024,24.024,0,0,1,13.538,3.723m-20.532,15q-4.514,4.851-4.513,13.312t4.513,13.256q4.511,4.8,11.958,4.794a15.744,15.744,0,0,0,11.9-4.907q4.68-4.908,4.682-13.143,0-8.348-4.682-13.256a15.744,15.744,0,0,0-11.9-4.907q-7.446,0-11.958,4.851"
                    fill="#040405"
                />
                <path
                    id="Path_39387"
                    data-name="Path 39387"
                    d="M277.038,23.917a20.279,20.279,0,0,1,8.01,10.04V20.984h15.794v62.5H285.048V70.4a20.569,20.569,0,0,1-8.01,10.1A23.754,23.754,0,0,1,263.5,84.272a26.834,26.834,0,0,1-14.214-3.836,25.9,25.9,0,0,1-9.872-11.112,38.551,38.551,0,0,1-3.553-17.091,38.551,38.551,0,0,1,3.553-17.091,25.92,25.92,0,0,1,9.872-11.113A26.861,26.861,0,0,1,263.5,20.194a24.024,24.024,0,0,1,13.538,3.723m-20.532,15q-4.514,4.851-4.512,13.312t4.512,13.256q4.51,4.8,11.958,4.794a15.744,15.744,0,0,0,11.9-4.907q4.68-4.908,4.682-13.143,0-8.348-4.682-13.256a15.744,15.744,0,0,0-11.9-4.907q-7.446,0-11.958,4.851"
                    fill="#040405"
                />
                <rect id="Rectangle_17485" data-name="Rectangle 17485" width="15.907" height="83.482" transform="translate(320.019)" fill="#040405" />
                <path
                    id="Path_39388"
                    data-name="Path 39388"
                    d="M24.743,71.77A12.372,12.372,0,1,1,12.372,59.4,12.371,12.371,0,0,1,24.743,71.77"
                    fill="#72b1e3"
                />
            </g>
        </g>
    </svg>
);

export const annualWheelActivityIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.377" height="19.167" viewBox="0 0 18.377 19.167">
        <g id="Group_15228" data-name="Group 15228" transform="translate(0.105 0.105)">
            <g id="Group_15229" data-name="Group 15229">
                <circle
                    id="Ellipse_793"
                    data-name="Ellipse 793"
                    cx="1.975"
                    cy="1.975"
                    r="1.975"
                    transform="translate(7.109 0.395)"
                    fill="#dad9ef"
                    stroke="#7284f8"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <circle
                    id="Ellipse_794"
                    data-name="Ellipse 794"
                    cx="1.975"
                    cy="1.975"
                    r="1.975"
                    transform="translate(7.109 14.613)"
                    fill="#dad9ef"
                    stroke="#7284f8"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <circle
                    id="Ellipse_795"
                    data-name="Ellipse 795"
                    cx="1.975"
                    cy="1.975"
                    r="1.975"
                    transform="translate(13.823 14.613)"
                    fill="#dad9ef"
                    stroke="#7284f8"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <circle
                    id="Ellipse_796"
                    data-name="Ellipse 796"
                    cx="1.975"
                    cy="1.975"
                    r="1.975"
                    transform="translate(0.395 14.613)"
                    fill="#dad9ef"
                    stroke="#7284f8"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_971"
                    data-name="Line 971"
                    y2="10.268"
                    transform="translate(9.084 4.344)"
                    fill="none"
                    stroke="#7284f8"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <path
                    id="Path_39246"
                    data-name="Path 39246"
                    d="M3,17.239C3,14.622,4.727,12.5,7.344,12.5h4.344a4.739,4.739,0,0,1,4.739,4.739"
                    transform="translate(-0.63 -2.627)"
                    fill="none"
                    stroke="#7284f8"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
            </g>
        </g>
    </svg>
);

export const contractsIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="21.779" height="24.446" viewBox="0 0 21.779 24.446">
        <g id="Group_12069" data-name="Group 12069" transform="translate(-32.493 -534.467)">
            <g id="Group_12067" data-name="Group 12067" transform="translate(32.993 534.967)">
                <line
                    id="Line_562"
                    data-name="Line 562"
                    x1="2.378"
                    y1="1.409"
                    transform="translate(13.781 18.529)"
                    fill="none"
                    stroke="#7284fa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_563"
                    data-name="Line 563"
                    x1="1.752"
                    y1="1.038"
                    transform="translate(12.927 19.967)"
                    fill="none"
                    stroke="#7284fa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_564"
                    data-name="Line 564"
                    x1="0.687"
                    y1="0.407"
                    transform="translate(12.2 21.48)"
                    fill="none"
                    stroke="#7284fa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <g id="Group_12066" data-name="Group 12066" transform="translate(0 0)">
                    <g id="Group_12068" data-name="Group 12068" transform="translate(0 0)">
                        <rect
                            id="Rectangle_15195"
                            data-name="Rectangle 15195"
                            width="6.808"
                            height="5.523"
                            rx="1"
                            transform="matrix(0.995, -0.105, 0.105, 0.995, 10.631, 15.99)"
                            fill="#dad9ef"
                        />
                        <rect
                            id="Rectangle_15194"
                            data-name="Rectangle 15194"
                            width="6.808"
                            height="5.932"
                            rx="1"
                            transform="translate(8.063 17.106)"
                            fill="#dad9ef"
                        />
                        <path
                            id="Path_39781"
                            data-name="Path 39781"
                            d="M64.927,20.544V14.494a1.1,1.1,0,0,0-.323-.781l-3.39-3.39A1.1,1.1,0,0,0,60.434,10H49.769a1.457,1.457,0,0,0-1.457,1.457V30.228a1.457,1.457,0,0,0,1.457,1.457h5.9"
                            transform="translate(-48.312 -10)"
                            fill="#dad9ef"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                        <path
                            id="Path_39780"
                            data-name="Path 39780"
                            d="M403.876,12.811v2.48a1.457,1.457,0,0,0,1.457,1.457h2.48"
                            transform="translate(-391.297 -12.712)"
                            fill="#dad9ef"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                        <path
                            id="Path_39782"
                            data-name="Path 39782"
                            d="M130.8,347.747l-.732-.434a.424.424,0,0,0-.581.149l-3.011,5.082a.424.424,0,0,0,.149.581l.732.434a1.034,1.034,0,0,0,1.416-.362l2.389-4.033A1.034,1.034,0,0,0,130.8,347.747Z"
                            transform="translate(-123.651 -335.323)"
                            fill="#fff"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                        <path
                            id="Path_39783"
                            data-name="Path 39783"
                            d="M497.54,351.306l1.12,1.89a1.034,1.034,0,0,0,1.416.362l.732-.434a.424.424,0,0,0,.149-.581l-3.011-5.082a.424.424,0,0,0-.581-.149l-.732.434a1.034,1.034,0,0,0-.362,1.416l.422.713"
                            transform="translate(-480.284 -335.323)"
                            fill="#fff"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                        <path
                            id="Path_39784"
                            data-name="Path 39784"
                            d="M266.523,396.568l-.121-.072a1.584,1.584,0,0,0-1.646-.23l-.683.264a1.454,1.454,0,0,1-1.266-.105l-.422-.249"
                            transform="translate(-254.812 -382.468)"
                            fill="#fff"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                        <line
                            id="Line_568"
                            data-name="Line 568"
                            x2="0.708"
                            y2="0.418"
                            transform="translate(4.957 18.08)"
                            fill="none"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                        <path
                            id="Path_39786"
                            data-name="Path 39786"
                            d="M364.744,396.764l-.1.057a1.454,1.454,0,0,1-1.266.105l-.77-.3a1.238,1.238,0,0,0-.756-.048l-1.18.3a1.238,1.238,0,0,0-.934,1.2v1.575a.4.4,0,0,0,.4.4,1.216,1.216,0,0,0,1.216-1.216v-.067l5.052,2.992a1.453,1.453,0,0,1,.319-.256l.716-.418"
                            transform="translate(-348.722 -382.864)"
                            fill="#dad9ef"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                        <path
                            id="Path_39785"
                            data-name="Path 39785"
                            d="M356.431,549.455l1.133.671a.836.836,0,1,0,.853-1.439l.941.557a.836.836,0,1,0,.853-1.439l.627.372a.836.836,0,1,0,.853-1.439l.767.455a.836.836,0,0,0,.852-1.439"
                            transform="translate(-345.531 -526.8)"
                            fill="none"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                        <path
                            id="Path_39787"
                            data-name="Path 39787"
                            d="M189.718,527.815a.836.836,0,0,1-1.358-.975l.474-.66a.836.836,0,0,1,1.358.975"
                            transform="translate(-183.253 -507.583)"
                            fill="none"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                        <path
                            id="Path_39788"
                            data-name="Path 39788"
                            d="M229.111,534.143l-1,1.388a.836.836,0,1,1-1.358-.975l1-1.388a.836.836,0,1,1,1.358.975Z"
                            transform="translate(-220.292 -514.323)"
                            fill="none"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                        <path
                            id="Path_39789"
                            data-name="Path 39789"
                            d="M266.149,560.732a.836.836,0,0,1,1.358.975l-1,1.388a.836.836,0,0,1-1.358-.975"
                            transform="translate(-257.33 -540.913)"
                            fill="none"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                        <path
                            id="Path_39790"
                            data-name="Path 39790"
                            d="M310.166,605.729a.836.836,0,1,1,1.358.975l-.331.462a.836.836,0,1,1-1.358-.975"
                            transform="translate(-300.431 -584.317)"
                            fill="none"
                            stroke="#7284fa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                    </g>
                </g>
                <line
                    id="Line_565"
                    data-name="Line 565"
                    x2="11.567"
                    transform="translate(2.945 6.501)"
                    fill="none"
                    stroke="#7284fa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_566"
                    data-name="Line 566"
                    x2="5.784"
                    transform="translate(2.945 4.036)"
                    fill="none"
                    stroke="#7284fa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_567"
                    data-name="Line 567"
                    x2="11.567"
                    transform="translate(2.945 8.685)"
                    fill="none"
                    stroke="#7284fa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_570"
                    data-name="Line 570"
                    x1="1.752"
                    y1="1.038"
                    transform="translate(12.886 19.938)"
                    fill="none"
                    stroke="#7284fa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_571"
                    data-name="Line 571"
                    x1="2.378"
                    y1="1.409"
                    transform="translate(13.781 18.529)"
                    fill="none"
                    stroke="#7284fa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
            </g>
        </g>
    </svg>
);
