import { useTranslation } from "../localization/useTranslation";
import { useTaskManagementStyles } from "./TaskManagement.styles";
import { useTaskManagementDataMapping } from "./TaskManagement.hooks";
import TaskStatusPaper from "./tasksStatusPaper/TaskStatusPaper";
import { TaskStateEnum } from "../annualWheel/AnnualWheel.types";
import { Box, Grid, GridSize } from "@mui/material";
import DotLegalPaper from "../common/components/dotLegalPaper/DotLegalPaper";
import DotLegalDragNDropWithControls from "../common/components/dotLegalDragNDropWithControls/DotLegalDragNDropWithControls";
import Task from "./task/Task";
import { DotLegalMultiSelect, DotLegalPageHeader, DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import React from "react";
import AnnualWheelYearPicker from "../annualWheel/annualWheelYearPicker/AnnualWheelYearPicker";
import { useUserContext } from "../auth/userContextProvider/UserContextProvider";
import { DragDropColumn, DragDropItem } from "../common/components/dotLegalDragNDrop/DotLegalDragNDrop.types";
import { OverviewTaskViewModel } from "./TaskManagement.types";
import EditTaskDialog from "./editTaskDialog/EditTaskDialog";
import { DotLegalSelectOption } from "../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { highRiskIcon, lowRiskIcon, moderateRiskIcon, reallyHighRiskIcon, reallyLowRiskIcon } from "../riskAssessment/riskIndicator/riskIcons";
import { AnnualWheelActivityType } from "../annualWheel/annualWheelDialog/AnnualWheelDialog.types";
import DotLegalAllOrMySwitchBox from "../common/components/dotLegalSwitch/dotLegalAllOrMySwitchBox/dotLegalAllOrMySwitchBox";
import { usePlanContext } from "../auth/planProvider/PlanProvider";
import { isApprovalRequired } from "../annualWheel/ApprovalOptions";
import DotLegalWarningTriangle from "../common/components/dotLegalWarningTriangle/DotLegalWarningTriangle";
import DotLegalCompanySelector from "../common/components/dotLegalCompanySelector/DotLegalCompanySelector";
import { taskStatusPaperMinHeight } from "./tasksStatusPaper/TaskStatusPaper.styles.ts";
import AddTaskButton from "./addTask/addTaskButton/AddTaskButton.tsx";
import { isNullOrWhitespace } from "../common/stringOperations.ts";
import { EntityType } from "../common/Common.types.ts";
import createWave from "../annualWheel/exportReportWave.svg?url";
import overdueWave from "../annualWheel/overdueTasksWave.svg?url";
import ongoingWave from "../annualWheel/plannedTasksWave.svg";
import completedWave from "../annualWheel/completedTasksWave.svg";

function TaskManagement() {
    const styles = useTaskManagementStyles();
    const { translateString } = useTranslation();
    const { permissions, userProfileId, customerName } = useUserContext();
    const { platformFeatures: platform } = usePlanContext();
    const {
        isLoading,
        data,
        ready,
        inProgress,
        completed,
        searchedBusinessAreas,
        setSearchedBusinessAreas,
        searchedResponsibles,
        setSearchedResponsibles,
        searchedTasks,
        setSearchedTasks,
        searchedPriorities,
        setSearchedPriorities,
        searchedMonths,
        setSearchedMonths,
        showOnlyOverdueTasks,
        setShowOverdueTasks,
        searchedAreas,
        setSearchedAreas,
        searchedTaskType,
        setSearchedTaskType,
        businessAreaFilterOptions,
        responsiblesFilterOptions,
        taskFilterOptions,
        priorityFilterOptions,
        areasFilterOptions,
        monthFilterOptions,
        taskTypeFilterOptions,
        changeTaskState,
        failedToChangeTaskState,
        year,
        setYear,
        selectedTask,
        setSelectedTask,
        refetchTasks,
        searchString,
        setSearchString,
        overdueTaskCount,
        inProgressTaskCount,
        completedTaskCount,
        onlyUserSpecific,
        setOnlyUserSpecific,
        setYearByTaskYear,
        getTaskStatusOptions,
        searchedTaskStatus,
        setSearchedTaskStatus,
        groupEntitiesForUserQuery,
    } = useTaskManagementDataMapping();

    const getPercentage = (paperTaskCount: number | undefined) => {
        if (!paperTaskCount) return 0;

        return Math.round((paperTaskCount / data?.totalTasks!) * 100);
    };

    const getOverduePercentage = (paperTaskCount: number | undefined) => {
        if (data && paperTaskCount !== undefined) {
            if (data.tasks.length === 0 && paperTaskCount === 0) {
                return 100;
            }

            const result = ((data?.totalTasks - paperTaskCount) / data?.totalTasks) * 100;
            return paperTaskCount > 0 && result >= 99 ? Math.floor(result) : Math.ceil(result);
        }

        return 0;
    };

    const getRiskIcon = (score: string | undefined | null) => {
        switch (score) {
            case "1":
                return reallyLowRiskIcon;
            case "2":
                return lowRiskIcon;
            case "3":
                return moderateRiskIcon;
            case "4":
                return highRiskIcon;
            case "5":
                return reallyHighRiskIcon;
        }
    };

    const renderPriorityOptions = (option: DotLegalSelectOption) => {
        return (
            <Box sx={styles.priorityContainer}>
                <div>{option.name}</div>
                <Box sx={styles.priorityIcon}>{getRiskIcon(option.id)}</Box>
            </Box>
        );
    };

    function getSearchFields() {
        const noOptionsLabel = translateString("noOptions");
        const showOnlyOverdueTasksValue = showOnlyOverdueTasks();

        let searchFields: Array<JSX.Element> = [];

        searchFields.push(
            <DotLegalMultiSelect
                onChange={setSearchedMonths}
                chosenOptions={searchedMonths}
                label={translateString("month")}
                placeholder={translateString("month")}
                isLoading={isLoading}
                options={monthFilterOptions}
                noMargin
                limit={1}
                noOptionsLabel={noOptionsLabel}
                disabled={showOnlyOverdueTasksValue}
            />
        );

        searchFields.push(
            <DotLegalMultiSelect
                onChange={setSearchedTasks}
                chosenOptions={searchedTasks}
                label={translateString("task")}
                placeholder={translateString("task")}
                isLoading={isLoading}
                options={taskFilterOptions}
                noMargin
                limit={1}
                noOptionsLabel={noOptionsLabel}
            />
        );
        searchFields.push(
            <DotLegalMultiSelect
                onChange={setSearchedResponsibles}
                chosenOptions={searchedResponsibles}
                label={translateString("responsibleAndAssignee")}
                placeholder={translateString("responsibleAndAssignee")}
                isLoading={isLoading}
                options={responsiblesFilterOptions}
                noMargin
                limit={1}
                noOptionsLabel={noOptionsLabel}
            />
        );
        searchFields.push(
            <DotLegalMultiSelect
                onChange={setSearchedBusinessAreas}
                chosenOptions={searchedBusinessAreas}
                label={translateString("businessArea")}
                placeholder={translateString("businessArea")}
                isLoading={isLoading}
                options={businessAreaFilterOptions}
                noMargin
                limit={1}
                noOptionsLabel={noOptionsLabel}
            />
        );

        searchFields.push(
            <DotLegalMultiSelect
                onChange={setSearchedTaskStatus}
                chosenOptions={searchedTaskStatus}
                label={translateString("taskStatus")}
                placeholder={translateString("taskStatus")}
                isLoading={isLoading}
                options={getTaskStatusOptions()}
                noMargin
                noOptionsLabel={noOptionsLabel}
            />
        );

        if (platform.annualWheelComplianceArea) {
            searchFields.push(
                <DotLegalMultiSelect
                    onChange={setSearchedAreas}
                    chosenOptions={searchedAreas}
                    label={translateString("area")}
                    placeholder={translateString("area")}
                    isLoading={isLoading}
                    options={areasFilterOptions}
                    noMargin
                    limit={1}
                    noOptionsLabel={noOptionsLabel}
                />
            );
        }

        searchFields.push(
            <DotLegalMultiSelect
                onChange={setSearchedPriorities}
                chosenOptions={searchedPriorities}
                label={translateString("priority")}
                placeholder={translateString("priority")}
                isLoading={isLoading}
                options={priorityFilterOptions}
                noMargin
                limit={1}
                renderOptions={renderPriorityOptions}
                noOptionsLabel={noOptionsLabel}
            />
        );

        searchFields.push(
            <DotLegalSelect
                label={translateString("taskType")}
                placeholder={translateString("taskType")}
                selectedItem={isNullOrWhitespace(searchedTaskType) ? " " : searchedTaskType}
                isLoading={isLoading}
                options={taskTypeFilterOptions}
                noMargin
                noOptionsLabel={noOptionsLabel}
                onChange={setSearchedTaskType}
            />
        );

        return searchFields;
    }

    function createDragItemContent(task: OverviewTaskViewModel): DragDropItem {
        var responsibleId = task.responsibles?.find((x) => x.isResponsible)?.id;
        return {
            id: task.id,
            columnWithDeniedAccess:
                (!task.hasDocuments && task.documentationRequired) ||
                task.type === AnnualWheelActivityType.ProcessingActivityValidation ||
                (isApprovalRequired(task.approval) && responsibleId && responsibleId !== userProfileId)
                    ? 2
                    : null,
            content: (
                <Task
                    activityName={task.name}
                    taskName={task.processingActivityName}
                    description={task.description}
                    businessAreas={task.businessAreas}
                    responsibles={task.responsibles}
                    month={new Date(task.deadline).getMonth() + 1}
                    year={new Date(task.deadline).getFullYear()}
                    subTaskCompleted={task.completedSubTasks}
                    totalSubTasks={task.totalSubTasks}
                    taskStatus={task.laneState}
                    isOverdue={task.isOverdue}
                    onClick={() => {
                        setSelectedTask(task.id);
                    }}
                    priority={task.priority}
                    processingActivtiyId={task.processingActivityId}
                    areas={task.areas}
                    customId={task.customId}
                    isReadyForApproval={task.isReadyForApproval}
                    isAdhocTask={task.isAdHocTask}
                />
            ),
        };
    }

    const dragNDropContent: Array<DragDropColumn> = [
        { title: translateString("planned"), tasks: ready.map((t) => createDragItemContent(t)) },
        { title: translateString("doing"), tasks: inProgress.map((t) => createDragItemContent(t)) },
        { title: translateString("completedPlural"), tasks: completed.map((t) => createDragItemContent(t)) },
    ];

    const getPagereaderLeftComponents = () => {
        return <DotLegalAllOrMySwitchBox onChange={setOnlyUserSpecific} enabled={onlyUserSpecific} hidden={!permissions.canAccessAllData} />;
    };

    const canCreateTasks = permissions.taskPermissions.create;
    const gridProps: { item: boolean; xs: GridSize; sm: GridSize; lg: GridSize } = {
        item: true,
        xs: 12,
        sm: 6,
        lg: canCreateTasks ? 3 : 4,
    };

    return (
        <React.Fragment>
            <DotLegalPageHeader
                userContext={{
                    customerName: customerName,
                }}
                breadCrumbs={[{ name: translateString("taskManagement") }]}
                rightComponent={<DotLegalCompanySelector isLoading={groupEntitiesForUserQuery.isLoading} companies={groupEntitiesForUserQuery.data ?? []} />}
                leftComponent={getPagereaderLeftComponents()}
                topLeftComponent={
                    <AnnualWheelYearPicker
                        showPreviousYear={data?.previousYearHasTasks}
                        sx={styles.yearPicker}
                        year={year!}
                        onChange={setYear}
                        isLoading={isLoading}
                    />
                }
            />
            <Grid container spacing={2} sx={styles.tasksStatusContainer}>
                {canCreateTasks && (
                    <Grid {...gridProps} sx={{ display: "flex" }}>
                        <DotLegalPaper
                            sx={{
                                backgroundImage: `url("${createWave}")`,
                                flex: 1,
                                minHeight: taskStatusPaperMinHeight,
                                display: "flex",
                                alignItems: "center",
                                backgroundPosition: "bottom",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                            }}
                        >
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <AddTaskButton tooltip={translateString("createAdHocTaskInfoText")} />
                            </Box>
                        </DotLegalPaper>
                    </Grid>
                )}
                <Grid {...gridProps}>
                    <TaskStatusPaper
                        tasksCount={overdueTaskCount}
                        percentage={getOverduePercentage(overdueTaskCount)}
                        isLoading={isLoading}
                        onClick={() => {
                            if (showOnlyOverdueTasks()) setShowOverdueTasks("false");
                            else setShowOverdueTasks("true");
                        }}
                        isSelected={showOnlyOverdueTasks()}
                        indicator={TaskStateEnum.Overdue}
                        headerTooltip={translateString("overdueTasksInfoTooltip")}
                        wave={overdueWave}
                        warningMsg={data?.previousYearsHaveOverdueTasks ? translateString("overdueTasksInPreviousYears") : undefined}
                    />
                </Grid>
                <Grid {...gridProps}>
                    <TaskStatusPaper
                        tasksCount={inProgressTaskCount}
                        percentage={getPercentage(inProgressTaskCount)}
                        isLoading={isLoading}
                        indicator={TaskStateEnum.InProgress}
                        wave={ongoingWave}
                    />
                </Grid>
                <Grid {...gridProps}>
                    <TaskStatusPaper
                        tasksCount={completedTaskCount}
                        percentage={getPercentage(completedTaskCount)}
                        isLoading={isLoading}
                        indicator={TaskStateEnum.Completed}
                        wave={completedWave}
                    />
                </Grid>
            </Grid>

            <DotLegalPaper>
                <DotLegalDragNDropWithControls
                    items={dragNDropContent}
                    isLoading={isLoading}
                    extraControls={getSearchFields()}
                    onDragEnd={changeTaskState}
                    searchString={searchString}
                    onSearchStringChange={setSearchString}
                    onColumnDragFailed={failedToChangeTaskState}
                    disabled={!permissions.taskPermissions.edit}
                    gridSizes={{ sm: 12, md: 6, lg: 4 }}
                />
            </DotLegalPaper>

            {selectedTask && (
                <EditTaskDialog
                    setShowDeleteDialog={() => {
                        /* setSelectedTaskName(data?.tasks.find((x) => x.id === selectedTask)?.name ?? "");
                        setShowDeleteDialog(true); */
                    }}
                    refetchTasks={refetchTasks}
                    taskId={selectedTask}
                    setSelectedTask={setSelectedTask}
                    setYearByTaskYear={setYearByTaskYear}
                />
            )}
        </React.Fragment>
    );
}

export default TaskManagement;
